import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AppUtils from '@control-front-end/utils/utils';
import {
  Icon,
  Label,
  Button,
  Upload,
  UploadItem,
  ProgressBar,
  Card,
  Space,
  Stack,
  cr,
} from 'mw-style-react';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import { UPLOAD_FILE } from 'constants';
import mes from './intl';
import scss from './ImageUpload.scss';

/**
 * Загрузка изображения для актора
 */
function ImageUpload({ handleUpload, handleClose }) {
  const ref = useRef();
  const t = useIntl();
  useOutsideClick({ ref, callback: handleClose });
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSaveImage = () => {
    setLoading(true);
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files,
        callback: (attachments) => {
          const img = attachments[0];
          AppUtils.getImgSize(img.fileName, ({ width, height }) => {
            handleUpload({
              pictureObject: { img: img.fileName, width, height },
              title: img.title,
              callback: () => setLoading(false),
            });
            handleClose();
          });
        },
      },
    });
  };

  const handleUploadChange = ({ value }) => setFiles(value);

  const renderPreview = () => (
    <Card
      className={scss.liUploadPreview}
      boxShadow="none"
      color={Card.COLOR.secondary}
    >
      <Space size="xsmall" fullWidth fullHeight>
        <Stack.H justifyContent="center" fullWidth fullHeight>
          <UploadItem
            className={scss.liUploadPreviewItem}
            {...files[0]}
            onRemove={() => setFiles([])}
          />
          {cr([
            loading,
            () => (
              <ProgressBar
                className={scss.liUploadLoader}
                type="circle"
                size="large"
              />
            ),
          ])}
          <Button
            className={scss.liUploadBtn}
            label={t(mes.proceed)}
            fontWeight="normal"
            onClick={handleSaveImage}
            visibility={loading ? 'disabled' : 'visible'}
          />
        </Stack.H>
      </Space>
    </Card>
  );

  return (
    <Stack.H className={scss.li} fullWidth alignItems="center">
      <Card
        className={scss.liUpload}
        forwardRef={ref}
        withBorder={false}
        borderRadius="large"
      >
        {cr([files.length, renderPreview])}
        <Space size="large" fullHeight>
          <Card
            className={scss.liUploadBrowse}
            color={Card.COLOR.secondary}
            fullHeight
            boxShadow="none"
          >
            <Space fullHeight size="xsmall">
              <Stack.V
                size="xsmall"
                alignItems="center"
                justifyContent="center"
                fullHeight
              >
                <Icon className={scss.liUploadIcon} type="picture" />
                <Label fontWeight="semibold" value={t(mes.dragImage)} />
                <Label
                  color={Label.COLOR.gray}
                  fontSize="xsmall"
                  value={t(mes.imageSize, {
                    fileSize: config.maxFileSize / (1024 * 1024),
                  })}
                />
                <Upload
                  className={scss.liUploadBox}
                  multiple={false}
                  accept="image/*"
                  value={files}
                  onChange={handleUploadChange}
                  maxFileSize={config.maxFileSize}
                  label=""
                  browseLinkLabel={t(mes.btnBrowseLabel)}
                />
              </Stack.V>
            </Space>
          </Card>
        </Space>
      </Card>
    </Stack.H>
  );
}

ImageUpload.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ImageUpload;
