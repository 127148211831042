import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon, Label, TextField } from 'mw-style-react';
import cn from 'classnames';
import './ChartTitle.scss';

/**
 * Editable chart title
 */
function ChartTitle({ title, readOnly, onSubmit }) {
  const [newTitle, setNewTitle] = useState(title);
  const [editMode, toggleEditMode] = useState(false);

  const saveChartTitle = () => {
    if (newTitle.length && newTitle !== title) {
      onSubmit(newTitle);
    }
    toggleEditMode(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      saveChartTitle();
    } else if (e.key === 'Escape') {
      toggleEditMode(false);
    }
  };

  const renderTitleLabel = () => {
    return (
      <div
        styleName={cn('title__label', { editable: !readOnly })}
        onClick={(e) => {
          if (readOnly) return;
          e.stopPropagation();
          toggleEditMode(true);
        }}
      >
        <div styleName="title__label__value">
          <Label fontWeight="semibold" fontSize="large" value={title} />
        </div>
        {!readOnly ? (
          <div styleName="title__label__icon">
            <Icon type="edit" size="small" />
          </div>
        ) : null}
      </div>
    );
  };

  const renderTitleEdit = () => {
    return (
      <div styleName="title__edit">
        <TextField
          styleName="title__field"
          autoFocus={true}
          autoSelect="mount"
          bordered={false}
          value={newTitle}
          onChange={({ value }) => setNewTitle(value)}
          onKeyDown={handleKeyPress}
          onBlur={saveChartTitle}
          fontSize={18}
        />
      </div>
    );
  };
  return (
    <div styleName="title">
      {editMode ? renderTitleEdit() : renderTitleLabel()}
    </div>
  );
}

ChartTitle.propTypes = {
  title: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChartTitle;
