function SVGIconActor(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(0.67, 0.67);
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = '#BBC4CD';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.translate(-3, -3);
  ctx.save();
  ctx.fillStyle = '#BBC4CD';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.beginPath();
  ctx.moveTo(12, 3);
  ctx.bezierCurveTo(16.9705627, 3, 21, 7.02943725, 21, 12);
  ctx.bezierCurveTo(21, 16.9705627, 16.9705627, 21, 12, 21);
  ctx.bezierCurveTo(7.02943725, 21, 3, 16.9705627, 3, 12);
  ctx.bezierCurveTo(3, 7.02943725, 7.02943725, 3, 12, 3);
  ctx.closePath();
  ctx.moveTo(12, 5);
  ctx.bezierCurveTo(8.13400675, 5, 5, 8.13400675, 5, 12);
  ctx.bezierCurveTo(5, 15.8659932, 8.13400675, 19, 12, 19);
  ctx.bezierCurveTo(15.8659932, 19, 19, 15.8659932, 19, 12);
  ctx.bezierCurveTo(19, 8.13400675, 15.8659932, 5, 12, 5);
  ctx.closePath();
  ctx.moveTo(12, 9);
  ctx.bezierCurveTo(13.6568542, 9, 15, 10.3431458, 15, 12);
  ctx.bezierCurveTo(15, 13.6568542, 13.6568542, 15, 12, 15);
  ctx.bezierCurveTo(10.3431458, 15, 9, 13.6568542, 9, 12);
  ctx.bezierCurveTo(9, 10.3431458, 10.3431458, 9, 12, 9);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconActor;
