import { createReducer } from '@reduxjs/toolkit';
import { SET_DATA_CACHE } from 'constants';
import { cloneDeep } from 'lodash';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {
  actor: {},
  user: {},
};

export default createReducer(initState, {
  [SET_DATA_CACHE](state, action) {
    const { objType, id, data } = action.payload;
    const reqTime = new Date().getTime();
    const copy = cloneDeep(state);
    return {
      ...copy,
      [objType]: { ...copy[objType], [id]: { ...data, reqTime } },
    };
  },
});
