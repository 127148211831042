function SVGIconEvent(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(0.5, 0.5);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(12, 1.6);
  ctx.bezierCurveTo(12, 1.03995, 12, 0.759921, 12.109, 0.546009);
  ctx.bezierCurveTo(12.2049, 0.357847, 12.3578, 0.204867, 12.546, 0.108993);
  ctx.bezierCurveTo(12.7599, 0, 13.0399, 0, 13.6, 0);
  ctx.lineTo(16.4, 0);
  ctx.bezierCurveTo(16.9601, 0, 17.2401, 0, 17.454, 0.108993);
  ctx.bezierCurveTo(17.6422, 0.204867, 17.7951, 0.357847, 17.891, 0.546009);
  ctx.bezierCurveTo(18, 0.759921, 18, 1.03995, 18, 1.6);
  ctx.lineTo(18, 6);
  ctx.lineTo(30, 6);
  ctx.lineTo(30, 1.6);
  ctx.bezierCurveTo(30, 1.03995, 30, 0.759921, 30.109, 0.546009);
  ctx.bezierCurveTo(30.2049, 0.357847, 30.3578, 0.204867, 30.546, 0.108993);
  ctx.bezierCurveTo(30.7599, 0, 31.0399, 0, 31.6, 0);
  ctx.lineTo(34.4, 0);
  ctx.bezierCurveTo(34.9601, 0, 35.2401, 0, 35.454, 0.108993);
  ctx.bezierCurveTo(35.6422, 0.204867, 35.7951, 0.357847, 35.891, 0.546009);
  ctx.bezierCurveTo(36, 0.759921, 36, 1.03995, 36, 1.6);
  ctx.lineTo(36, 6);
  ctx.lineTo(40.3083, 6);
  ctx.lineTo(46, 6);
  ctx.bezierCurveTo(47.1046, 6, 48, 6.89543, 48, 8);
  ctx.lineTo(48, 13.6917);
  ctx.lineTo(48, 21);
  ctx.lineTo(48, 46.3083);
  ctx.bezierCurveTo(48, 48.9829, 47.7215, 49.9528, 47.1986, 50.9305);
  ctx.bezierCurveTo(46.6757, 51.9083, 45.9083, 52.6757, 44.9305, 53.1986);
  ctx.bezierCurveTo(43.9528, 53.7215, 42.9829, 54, 40.3083, 54);
  ctx.lineTo(7.69166, 54);
  ctx.bezierCurveTo(5.0171, 54, 4.04724, 53.7215, 3.06946, 53.1986);
  ctx.bezierCurveTo(2.09169, 52.6757, 1.32432, 51.9083, 0.801398, 50.9305);
  ctx.bezierCurveTo(0.318702, 50.028, 0.044289, 49.1322, 0.00492789, 46.8961);
  ctx.lineTo(0.00108464, 21);
  ctx.lineTo(0, 21);
  ctx.lineTo(0, 13.6917);
  ctx.lineTo(0, 8);
  ctx.bezierCurveTo(0, 6.89543, 0.895431, 6, 2, 6);
  ctx.lineTo(12, 6);
  ctx.lineTo(12, 1.6);
  ctx.closePath();
  ctx.moveTo(6, 21);
  ctx.lineTo(6, 46.6914);
  ctx.lineTo(6.01789, 47.3285);
  ctx.lineTo(6.05022, 47.7986);
  ctx.lineTo(6.06359, 47.9286);
  ctx.lineTo(6.41166, 47.9627);
  ctx.lineTo(7.30379, 47.9952);
  ctx.lineTo(40.6914, 47.9952);
  ctx.lineTo(41.3285, 47.9773);
  ctx.lineTo(41.7986, 47.945);
  ctx.lineTo(41.9286, 47.9286);
  ctx.lineTo(41.9627, 47.5835);
  ctx.lineTo(41.9952, 46.6914);
  ctx.lineTo(41.9952, 21);
  ctx.lineTo(6, 21);
  ctx.closePath();
  ctx.moveTo(14.3033, 31.6259);
  ctx.bezierCurveTo(15.3744, 30.5676, 17.1109, 30.5676, 18.182, 31.6259);
  ctx.lineTo(22.0607, 35.458);
  ctx.lineTo(29.818, 27.7937);
  ctx.bezierCurveTo(30.8891, 26.7354, 32.6256, 26.7354, 33.6967, 27.7937);
  ctx.bezierCurveTo(34.7678, 28.8519, 34.7678, 30.5676, 33.6967, 31.6259);
  ctx.lineTo(24, 41.2063);
  ctx.bezierCurveTo(22.9289, 42.2646, 21.1924, 42.2646, 20.1213, 41.2063);
  ctx.lineTo(14.3033, 35.458);
  ctx.bezierCurveTo(13.2322, 34.3998, 13.2322, 32.6841, 14.3033, 31.6259);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconEvent;
