import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';
import AppUtils from '@control-front-end/utils/utils';
import history from '@control-front-end/app/src/store/history';
import { DescriptionEditor } from 'components';
import './Description.scss';

function Description(props) {
  const { refEdit, description, attendees, error, onChange, onUpload } = props;
  const accId = AppUtils.getAccountId(history.location.pathname);

  return (
    <div id="eventEditor" styleName={cn('event__editor', { error })}>
      <DescriptionEditor
        refEdit={refEdit}
        accId={accId}
        description={description}
        access={attendees}
        onChange={(value) => onChange({ id: 'description', value })}
        onUpload={(data) => onUpload(data, true)}
      />
    </div>
  );
}

Description.propTypes = {
  description: PropTypes.string,
  attendees: PropTypes.array,
  error: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default Description;
