function SVGIconTag(ctx) {
  ctx.save();
  ctx.miterLimit = 4;
  ctx.fillStyle = '#1973E8';
  ctx.scale(0.08526815056934256, 0.08526815056934256);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(273.661, 114.318);
  ctx.lineTo(273.661, 67.035);
  ctx.lineTo(228.103, 67.035);
  ctx.lineTo(236.886, 0);
  ctx.lineTo(189.196, 0);
  ctx.lineTo(180.413, 67.035);
  ctx.lineTo(120.329, 67.035);
  ctx.lineTo(129.113, 0);
  ctx.lineTo(81.425, 0);
  ctx.lineTo(72.64, 67.035);
  ctx.lineTo(7.804, 67.035);
  ctx.lineTo(7.804, 114.318);
  ctx.lineTo(66.453, 114.318);
  ctx.lineTo(59.549, 167.1089);
  ctx.lineTo(7.804, 167.1089);
  ctx.lineTo(7.804, 214.3979);
  ctx.lineTo(53.363, 214.3979);
  ctx.lineTo(44.579, 281.4639);
  ctx.lineTo(92.2659, 281.4639);
  ctx.lineTo(101.053, 214.3979);
  ctx.lineTo(161.136, 214.3979);
  ctx.lineTo(152.35, 281.46399);
  ctx.lineTo(200.041, 281.46399);
  ctx.lineTo(208.82399, 214.3979);
  ctx.lineTo(273.659, 214.3979);
  ctx.lineTo(273.659, 167.1089);
  ctx.lineTo(215.0129, 167.1089);
  ctx.lineTo(221.914, 114.3179);
  ctx.lineTo(273.661, 114.3179);
  ctx.closePath();
  ctx.moveTo(167.326, 167.109);
  ctx.lineTo(107.2419, 167.109);
  ctx.lineTo(114.142, 114.318);
  ctx.lineTo(174.224, 114.318);
  ctx.lineTo(167.326, 167.109);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconTag;
