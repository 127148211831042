import { useEffect, useState } from 'react';
import { FileUtils } from 'mw-style-react';

// 'uninitialized' is for Firefox which has known issues with drag n drop handling
const DRAG_EFFECTS_ALLOWED = ['all', 'copyMove', 'uninitialized'];

/**
 * Хук для drag&drop
 */
const useDragFiles = ({ ref, callback }) => {
  const [dragActive, setDragActive] = useState(false);
  const dragEvents = ['dragenter', 'dragleave', 'dragover', 'drop'];

  async function selectFiles(filesSource) {
    // eslint-disable-next-line no-return-await
    return await FileUtils.makeFilesArr(filesSource);
  }

  const handleDragEvent = (e) => {
    // skip custom actors drag-n-drop from panel or expanded node
    if (!DRAG_EFFECTS_ALLOWED.includes(e.dataTransfer.effectAllowed)) return;
    e.preventDefault();
    e.stopPropagation();
    switch (e.type) {
      case 'dragenter':
        setDragActive(true);
        break;
      case 'dragleave':
        setDragActive(false);
        break;
      case 'drop':
        setDragActive(false);
        if (!e.dataTransfer.files.length) return;
        selectFiles(e.dataTransfer.files).then((files) => callback(files, e));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (ref.current) {
      dragEvents.forEach((e) => {
        ref.current.addEventListener(e, handleDragEvent, true);
      });
    }
    return () => {
      if (ref.current) {
        dragEvents.forEach((e) => {
          ref.current.removeEventListener(e, handleDragEvent, true);
        });
      }
    };
  }, []);

  return { dragActive };
};

export default useDragFiles;
