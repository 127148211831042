import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIntl from 'useIntl';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import { DateUtils, Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { GET_FORM } from '@control-front-end/common/constants/forms';
import mes from '../components/FormSectionsPanel/intl';

/**
 * Ф-ции по работе со формами актора
 */
const useActorFormActions = ({ rootForm, forms, handleFormsChange }) => {
  const t = useIntl();
  const dispatch = useDispatch();
  const formsRef = useRef();
  const systemForms = useSelector((state) => state.systemForms);
  const systemInheritForms = [
    systemForms.graphs?.id,
    systemForms.layers?.id,
    systemForms.state?.id,
  ];

  useEffect(() => {
    formsRef.current = forms;
  }, [forms]);

  const checkIsInheritForm = (formId) => systemInheritForms.includes(formId);

  /**
   * Получить форму + ее связи до root
   */
  const handleGetFormWithRelations = ({ formId, callback }) => {
    dispatch({
      type: GET_FORM.REQUEST,
      payload: { formId, withRelations: true },
      callback,
    });
  };

  /**
   * Добавить форму + все родительские
   */
  const handleAddForms = (list) => {
    const newForms = Utils.sort(list, 'parentId');
    const findRoot = newForms.find((i) => !i.parentId);
    const rootIsDefault = rootForm && rootForm.form && rootForm.form.isDefault;
    if (
      !checkIsInheritForm(rootForm?.id) &&
      (!findRoot || (rootForm && !rootIsDefault && findRoot.id !== rootForm.id))
    ) {
      // Нотификация об ошибке (другой UAT)
      dispatch({
        type: SHOW_NOTIFY.REQUEST,
        payload: {
          id: AppUtils.createRid(),
          type: NOTIFY_LEVEL.ERROR,
          label: t(mes.differentRootError),
        },
      });
      return;
    }
    // если была дефолтная форма - полностью меняем на новый UAT
    const newFormsList = rootIsDefault ? [] : formsRef.current.slice(0);
    const uniqueFormsIds = new Set(formsRef.current.map((i) => i.id));
    newForms.forEach((item) => {
      if (!uniqueFormsIds.has(item.id)) newFormsList.push(item);
    });
    handleFormsChange(newFormsList);
  };

  /**
   * Обновить форму после редактирования в iFrame
   */
  const handleUpdateForm = (updatedForm) => {
    const newFormsList = forms.slice(0);
    const updFormIndex = newFormsList.findIndex((i) => i.id === updatedForm.id);
    newFormsList.splice(updFormIndex, 1, {
      ...updatedForm,
      updateTime: DateUtils.unixtime(),
    });
    handleFormsChange(newFormsList);
  };

  /**
   * Удалить форму + все по дочерние
   */
  const handleRemoveForms = (removeIds) => {
    const newFormsList = forms.filter((i) => !removeIds.includes(i.id));
    handleFormsChange(newFormsList);
  };

  return {
    checkIsInheritForm,
    handleAddForms,
    handleUpdateForm,
    handleRemoveForms,
    handleGetFormWithRelations,
  };
};

export default useActorFormActions;
