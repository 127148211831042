import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  selectActorFilterCapital: {
    id: 'selectActorFilterCapital_DashboardFromActorFilter',
    defaultMessage: 'Select Actor Filters',
  },
  selectActorFilter: {
    id: 'selectActorFilter_DashboardFromActorFilter',
    defaultMessage: 'Select actor filters',
  },
  top: {
    id: 'top_DashboardFromActorFilter',
    defaultMessage: 'Top',
  },
  topBrackets: {
    id: 'topBrackets_DashboardFromActorFilter',
    defaultMessage: '(from 1 to 100)',
  },
  topValueError: {
    id: 'TopValueError_DashboardFromActorFilter',
    defaultMessage: 'Value must be 1-100.',
  },
  customTopPlaceholder: {
    id: 'customTopPlaceholder_DashboardFromActorFilter',
    defaultMessage: 'Input number',
  },
  filterHasNoAccountSettings: {
    id: 'filterHasNoAccountSettings_DashboardFromActorFilter',
    defaultMessage: 'Selected filter has no account settings',
  },
});

export default { ...globalIntl, ...m };
