import { defineMessages } from 'react-intl';

export default defineMessages({
  status: {
    id: 'status',
    defaultMessage: 'Status',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },
  startDate: {
    id: 'startDate',
    defaultMessage: 'Start date',
  },
  endDate: {
    id: 'endDate',
    defaultMessage: 'End date',
  },
  title: {
    id: 'title',
    defaultMessage: 'Title',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  createDate: {
    id: 'createDate',
    defaultMessage: 'Create date',
  },
  type: {
    id: 'type',
    defaultMessage: 'Type',
  },
  custom: {
    id: 'custom',
    defaultMessage: 'Custom',
  },
  clearAll: {
    id: 'clearAll',
    defaultMessage: 'Clear all',
  },
  owner: {
    id: 'owner',
    defaultMessage: 'Owner',
  },
  guest: {
    id: 'guest',
    defaultMessage: 'Guest',
  },
  accOwner: {
    id: 'accOwner',
    defaultMessage: 'Account owner',
  },
  currentUser: {
    id: 'currentUser',
    defaultMessage: 'Current user',
  },
  unknownUser: {
    id: 'unknownUser',
    defaultMessage: 'Unknown user',
  },
  groups: {
    id: 'groups',
    defaultMessage: 'Groups',
  },
  users: {
    id: 'users',
    defaultMessage: 'Users',
  },
  all: {
    id: 'all',
    defaultMessage: 'All',
  },
  es_new: {
    id: 'es_new',
    defaultMessage: 'New',
  },
  es_in_progress: {
    id: 'es_in_progress',
    defaultMessage: 'In progress',
  },
  es_review: {
    id: 'es_review',
    defaultMessage: 'Review',
  },
  es_close: {
    id: 'es_close',
    defaultMessage: 'Done',
  },
  es_reopen: {
    id: 'es_reopen',
    defaultMessage: 'Reopened',
  },
  es_deleted: {
    id: 'es_deleted',
    defaultMessage: 'Deleted',
  },
  calendar: {
    id: 'calendar',
    defaultMessage: 'Calendar',
  },
  accounts: {
    id: 'accounts',
    defaultMessage: 'Accounts',
  },
  transfers: {
    id: 'transfers',
    defaultMessage: 'Transfers',
  },
  transactions: {
    id: 'transactions',
    defaultMessage: 'Transactions',
  },
  editors: {
    id: 'editors',
    defaultMessage: 'Editors',
  },
  communications: {
    id: 'communications',
    defaultMessage: 'Communications',
  },
  outerGraph: {
    id: 'outerGraph',
    defaultMessage: 'Outer Graph',
  },
  processes_simulator: {
    id: 'processes_simulator',
    defaultMessage: 'Processes Simulator',
  },
  expert_systems: {
    id: 'expert_systems',
    defaultMessage: 'Expert Systems',
  },
  scripts: {
    id: 'scripts',
    defaultMessage: 'Scripts',
  },
  searchScriptsByName: {
    id: 'searchScriptsByName',
    defaultMessage: 'Search scripts by name',
  },
  data: {
    id: 'data',
    defaultMessage: 'Data',
  },
  proceed: {
    id: 'proceed',
    defaultMessage: 'Proceed',
  },
  dashboard: {
    id: 'dashboard',
    defaultMessage: 'Dashboard',
  },
  dashboards: {
    id: 'dashboards',
    defaultMessage: 'Dashboards',
  },
  accessRules: {
    id: 'accessRules',
    defaultMessage: 'Access Rules',
  },
  manageAccess: {
    id: 'manageAccess',
    defaultMessage: 'Manage access rules',
  },
  accessSaved: {
    id: 'accessSaved',
    defaultMessage: 'Access settings have been successfully saved',
  },
  bin: {
    id: 'bin',
    defaultMessage: 'Bin',
  },
  myCompany: {
    id: 'myCompany',
    defaultMessage: 'My Company',
  },
  user_relations: {
    id: 'user_relations',
    defaultMessage: 'Communications',
  },
  relation_graph: {
    id: 'relation_graph',
    defaultMessage: 'Company Graph',
  },
  actors_graph: {
    id: 'actors_graph',
    defaultMessage: 'Actors Graph',
  },
  actor: {
    id: 'actor',
    defaultMessage: 'Actor',
  },
  files: {
    id: 'files',
    defaultMessage: 'Files',
  },
  download: {
    id: 'download',
    defaultMessage: 'Download',
  },
  templates: {
    id: 'templates',
    defaultMessage: 'Templates',
  },
  graph: {
    id: 'graph',
    defaultMessage: 'Graph',
  },
  triggers: {
    id: 'triggers',
    defaultMessage: 'Triggers',
  },
  tags: {
    id: 'tags',
    defaultMessage: 'Tags',
  },
  manageTags: {
    id: 'manageTags',
    defaultMessage: 'Manage tags',
  },
  managePairActors: {
    id: 'managePairActors',
    defaultMessage: 'Manage Tags & Triggers',
  },
  theGraph: {
    id: 'theGraph',
    defaultMessage: 'The Graph',
  },
  settings: {
    id: 'settings',
    defaultMessage: 'Settings',
  },
  trash: {
    id: 'trash',
    defaultMessage: 'Trash',
  },
  operations: {
    id: 'operations',
    defaultMessage: 'Operations',
  },
  myToDoTasks: {
    id: 'myToDoTasks',
    defaultMessage: 'Events',
  },
  withoutReaction: {
    id: 'withoutReaction',
    defaultMessage: 'Without reaction',
  },
  allTasks: {
    id: 'allTasks',
    defaultMessage: 'All events',
  },
  createdByMe: {
    id: 'createdByMe',
    defaultMessage: 'Created by me',
  },
  starred: {
    id: 'starred',
    defaultMessage: 'Starred',
  },
  TODO: {
    id: 'TODO',
    defaultMessage: 'To Do',
  },
  overdue: {
    id: 'overdue',
    defaultMessage: 'Overdue',
  },
  starredFiles: {
    id: 'starredFiles',
    defaultMessage: 'Starred',
  },
  updated: {
    id: 'updated',
    defaultMessage: 'Updated',
  },
  created: {
    id: 'created',
    defaultMessage: 'Created',
  },
  update: {
    id: 'update',
    defaultMessage: 'Update',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  done: {
    id: 'done',
    defaultMessage: 'Done',
  },
  reject: {
    id: 'reject',
    defaultMessage: 'Reject',
  },
  dueDate_nextOneWeek: {
    id: 'dueDate_nextOneWeek',
    defaultMessage: 'Due in next 1 week',
  },
  dueDate_nextThreeDays: {
    id: 'dueDate_nextThreeDays',
    defaultMessage: 'Due in next 3 days',
  },
  dueDate_nextOneDay: {
    id: 'dueDate_nextOneDay',
    defaultMessage: 'Due in next 1 day',
  },
  dueDate_nowOverdue: {
    id: 'dueDate_nowOverdue',
    defaultMessage: 'Now overdue',
  },
  single: {
    id: 'single',
    defaultMessage: 'Single',
  },
  group: {
    id: 'group',
    defaultMessage: 'Group',
  },
  add: {
    id: 'add',
    defaultMessage: 'Add',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  replace: {
    id: 'replace',
    defaultMessage: 'Replace',
  },
  findUsers: {
    id: 'findUsers',
    defaultMessage: 'Find users',
  },
  invited: {
    id: 'invited',
    defaultMessage: 'Invited',
  },
  edited: {
    id: 'edited',
    defaultMessage: 'Edited',
  },
  to: {
    id: 'to',
    defaultMessage: 'to',
  },
  deleted: {
    id: 'deleted',
    defaultMessage: 'deleted',
  },
  comment: {
    id: 'comment',
    defaultMessage: 'Comment',
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
  me: {
    id: 'me',
    defaultMessage: 'Me',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  searchByName: {
    id: 'searchByName',
    defaultMessage: 'Search by name',
  },
  today: {
    id: 'today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'yesterday',
    defaultMessage: 'Yesterday',
  },
  tomorrow: {
    id: 'tomorrow',
    defaultMessage: 'Tomorrow',
  },
  later: {
    id: 'later',
    defaultMessage: 'Later',
  },
  recent: {
    id: 'recent',
    defaultMessage: 'Recent',
  },
  ok: {
    id: 'ok',
    defaultMessage: 'OK',
  },
  by: {
    id: 'by',
    defaultMessage: 'by',
  },
  remind: {
    id: 'remind',
    defaultMessage: 'Remind (under construction)',
  },
  remove: {
    id: 'remove',
    defaultMessage: 'Remove',
  },
  underConstruction: {
    id: 'underConstruction',
    defaultMessage: 'Under construction',
  },
  reactionRate: {
    id: 'reactionRate',
    defaultMessage: 'Reaction rate',
  },
  writeComment: {
    id: 'writeComment',
    defaultMessage: 'Write comment',
  },
  reaction_view: {
    id: 'reaction_view',
    defaultMessage: 'viewed',
  },
  reaction_sign: {
    id: 'reaction_sign',
    defaultMessage: 'signed',
  },
  reaction_done: {
    id: 'reaction_done',
    defaultMessage: 'done',
  },
  reaction_rating: {
    id: 'reaction_rating',
    defaultMessage: 'rated',
  },
  reaction_reject: {
    id: 'reaction_reject',
    defaultMessage: 'rejected',
  },
  reaction_freeze: {
    id: 'reaction_freeze',
    defaultMessage: 'froze',
  },
  reaction_script: {
    id: 'reaction_script',
    defaultMessage: 'added script',
  },
  reaction_attachments: {
    id: 'reaction_attachments',
    defaultMessage: 'attached file(s)',
  },
  sign: {
    id: 'sign',
    defaultMessage: 'Sign',
  },
  viewer: {
    id: 'viewer',
    defaultMessage: 'Viewer',
  },
  editor: {
    id: 'editor',
    defaultMessage: 'Editor',
  },
  signer: {
    id: 'signer',
    defaultMessage: 'Signer',
  },
  executor: {
    id: 'executor',
    defaultMessage: 'Executor',
  },
  role_view: {
    id: 'role_view',
    defaultMessage: 'Viewer',
  },
  role_modify: {
    id: 'role_modify',
    defaultMessage: 'Editor',
  },
  role_sign: {
    id: 'role_sign',
    defaultMessage: 'Signer',
  },
  role_execute: {
    id: 'role_execute',
    defaultMessage: 'Executor',
  },
  role_done: {
    id: 'role_done',
    defaultMessage: 'Executor',
  },
  continue: {
    id: 'continue',
    defaultMessage: 'Continue',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Logout',
  },
  suggestedUsers: {
    id: 'suggestedUsers',
    defaultMessage: 'Suggested users',
  },
  goBack: {
    id: 'goBack',
    defaultMessage: 'Go back',
  },
  send: {
    id: 'send',
    defaultMessage: 'Send',
  },
  message: {
    id: 'message',
    defaultMessage: 'Message',
  },
  invalidRange: {
    id: 'invalidRange',
    defaultMessage: 'Field "to" can not be less than field "from"',
  },
  rangeExceedsYear: {
    id: 'rangeExceedsYear',
    defaultMessage: 'The selected date range cannot exceed 365 days.',
  },
  field_required: {
    id: 'field_required',
    defaultMessage: 'Field required',
  },
  fieldRequired: {
    id: 'fieldRequired',
    defaultMessage: 'Field is required',
  },
  minLimitError: {
    id: 'minLimitError',
    defaultMessage:
      "The balance can't be below the allowed limit (Min: {minLimit})",
  },
  maxLimitError: {
    id: 'maxLimitError',
    defaultMessage:
      "The balance can't exceed the allowed limit (Max: {maxLimit})",
  },
  incorrect_data: {
    id: 'incorrect_data',
    defaultMessage: 'Incorrect data',
  },
  duplicate_name: {
    id: 'duplicate_name',
    defaultMessage: 'This name already exist.',
  },
  min_len: {
    id: 'min_len',
    defaultMessage: 'Value must be at least {minLength} characters.',
  },
  min_len_3: {
    id: 'min_len_3',
    defaultMessage: 'Minimum length 3 characters',
  },
  max_len: {
    id: 'max_len',
    defaultMessage: 'Value must be at most {maxLength} characters.',
  },
  wrong_format: {
    id: 'wrong_format',
    defaultMessage: 'Wrong format',
  },
  valid_email: {
    id: 'valid_email',
    defaultMessage: 'Please enter valid email',
  },
  reply: {
    id: 'reply',
    defaultMessage: 'Reply',
  },
  reactionsNumber: {
    id: 'reactionsNumber',
    defaultMessage: 'Number of reactions in the event',
  },
  ratingNumber: {
    id: 'ratingNumber',
    defaultMessage: 'Rating of the event',
  },
  eventsNumber: {
    id: 'eventsNumber',
    defaultMessage: 'Number of events in the tree',
  },
  eventPrice: {
    id: 'eventPrice',
    defaultMessage: 'Event price',
  },
  signNumber: {
    id: 'signNumber',
    defaultMessage: 'Number of signatures',
  },
  eventRate: {
    id: 'eventRate',
    defaultMessage: 'Event activity rating',
  },
  userRate: {
    id: 'userRate',
    defaultMessage: 'User activity rating',
  },
  copyActorLink: {
    id: 'copyActorLink',
    defaultMessage: 'Copy actor link',
  },
  markAsFavorite: {
    id: 'markAsFavorite',
    defaultMessage: 'Mark as favorite',
  },
  unmarkAsFavorite: {
    id: 'unmarkAsFavorite',
    defaultMessage: 'Unmark as favorite',
  },
  contactUs: {
    id: 'contactUs',
    defaultMessage: 'Contact Us',
  },
  corezoid: {
    id: 'corezoid',
    defaultMessage: 'Corezoid',
  },
  apiGW: {
    id: 'apiGW',
    defaultMessage: 'API Gateway',
  },
  general: {
    id: 'general',
    defaultMessage: 'General',
  },
  members: {
    id: 'members',
    defaultMessage: 'Members',
  },
  integrations: {
    id: 'integrations',
    defaultMessage: 'Integrations',
  },
  connect: {
    id: 'connect',
    defaultMessage: 'Connect',
  },
  channels: {
    id: 'channels',
    defaultMessage: 'Channels',
  },
  saveChanges: {
    id: 'saveChanges',
    defaultMessage: 'Save changes',
  },
  justNow: {
    id: 'justNow',
    defaultMessage: 'just now',
  },
  secondsAgo: {
    id: 'secondsAgo',
    defaultMessage: 'seconds ago',
  },
  minuteAgo: {
    id: 'minuteAgo',
    defaultMessage: 'a minute ago',
  },
  minutesAgo: {
    id: 'minutesAgo',
    defaultMessage: 'minutes ago',
  },
  hourAgo: {
    id: 'hourAgo',
    defaultMessage: '1 hour ago',
  },
  hoursAgo: {
    id: 'hoursAgo',
    defaultMessage: 'hours ago',
  },
  yesterday1: {
    id: 'yesterday1',
    defaultMessage: 'yesterday',
  },
  events: {
    id: 'events',
    defaultMessage: 'Events',
  },
  newEvent: {
    id: 'newEvent',
    defaultMessage: 'New event',
  },
  addEvent: {
    id: 'addEvent',
    defaultMessage: 'Add event',
  },
  cloneEvent: {
    id: 'cloneEvent',
    defaultMessage: 'Clone event',
  },
  company: {
    id: 'company',
    defaultMessage: 'Company',
  },
  createNewEvent: {
    id: 'createNewEvent',
    defaultMessage: 'New event',
  },
  selectExisting: {
    id: 'selectExisting',
    defaultMessage: 'Select existing',
  },
  r_all: {
    id: 'r_all',
    defaultMessage: 'All',
  },
  r_comment: {
    id: 'r_comment',
    defaultMessage: 'Comments',
  },
  r_view: {
    id: 'r_view',
    defaultMessage: 'Views',
  },
  r_modify: {
    id: 'r_modify',
    defaultMessage: 'Updates',
  },
  r_sign: {
    id: 'r_sign',
    defaultMessage: 'Signatures',
  },
  r_decompose: {
    id: 'r_decompose',
    defaultMessage: 'Decompositions',
  },
  r_done: {
    id: 'r_done',
    defaultMessage: 'Done',
  },
  r_reject: {
    id: 'r_reject',
    defaultMessage: 'Rejections',
  },
  r_rating: {
    id: 'r_rating',
    defaultMessage: 'Ratings',
  },
  r_linkEvents: {
    id: 'r_linkEvents',
    defaultMessage: 'Link events',
  },
  linkedActors: {
    id: 'linkedActors',
    defaultMessage: 'Linked actors',
  },
  linkActor: {
    id: 'linkActor',
    defaultMessage: 'Link actor',
  },
  selectActor: {
    id: 'selectActor',
    defaultMessage: 'Select actor',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile',
  },
  myVerse: {
    id: 'myVerse',
    defaultMessage: 'My verse',
  },
  buy: {
    id: 'buy',
    defaultMessage: 'Buy',
  },
  viewThePresentation: {
    id: 'viewThePresentation',
    defaultMessage:
      'Want to know more about product features? Explore the presentation.',
  },
  import: {
    id: 'import',
    defaultMessage: 'Import',
  },
  export: {
    id: 'export',
    defaultMessage: 'Export',
  },
  importAndExport: {
    id: 'importAndExport',
    defaultMessage: 'Import & Export',
  },
  exportGraph: {
    id: 'exportGraph',
    defaultMessage: 'Export Graph',
  },
  credentials: {
    id: 'credentials',
    defaultMessage: 'Credentials',
  },
  createCredential: {
    id: 'createCredential',
    defaultMessage: 'Create credential',
  },
  updateCredential: {
    id: 'updateCredential',
    defaultMessage: 'Update credential',
  },
  read: {
    id: 'read',
    defaultMessage: 'Read',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  create: {
    id: 'create',
    defaultMessage: 'Create',
  },
  robot: {
    id: 'robot',
    defaultMessage: 'Robot',
  },
  thirdPartyUser: {
    id: 'thirdPartyUser',
    defaultMessage: 'Third-party system user',
  },
  webhooks: {
    id: 'webhooks',
    defaultMessage: 'Webhooks',
  },
  createWebhook: {
    id: 'createWebhook',
    defaultMessage: 'Create webhook',
  },
  updateWebhook: {
    id: 'updateWebhook',
    defaultMessage: 'Update webhook',
  },
  valid_url: {
    id: 'valid_url',
    defaultMessage: 'Please enter valid URL',
  },
  readOnly: {
    id: 'readOnly',
    defaultMessage: 'Read only',
  },
  discusCLosed: {
    id: 'discusCLosed',
    defaultMessage: 'Discussion closed',
  },
  interfaces: {
    id: 'interfaces',
    defaultMessage: 'Interfaces',
  },
  createNewForm: {
    id: 'createNewForm',
    defaultMessage: 'Create a new form',
  },
  noForms1: {
    id: 'noForms1',
    defaultMessage: 'No forms where found to match your search',
  },
  noForms2: {
    id: 'noForms2',
    defaultMessage: 'Try modifying your search criteria',
  },
  noResults: {
    id: 'noResults',
    defaultMessage: 'Try modifying your search criteria',
  },
  backToPrevStep: {
    id: 'backToPrevStep',
    defaultMessage: 'Back to previous step',
  },
  form: {
    id: 'form',
    defaultMessage: 'Form',
  },
  yes: {
    id: 'yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'no',
    defaultMessage: 'No',
  },
  notifications: {
    id: 'notifications',
    defaultMessage: 'Notifications',
  },
  info: {
    id: 'info',
    defaultMessage: 'Information',
  },
  processes: {
    id: 'processes',
    defaultMessage: 'Processes',
  },
  history: {
    id: 'history',
    defaultMessage: 'History',
  },
  comHistory: {
    id: 'comHistory',
    defaultMessage: 'Communications',
  },
  economics: {
    id: 'economics',
    defaultMessage: 'Economics',
  },
  products: {
    id: 'products',
    defaultMessage: 'Products/Services',
  },
  price: {
    id: 'price',
    defaultMessage: 'Price',
  },
  currency: {
    id: 'currency',
    defaultMessage: 'Currency',
  },
  createAccCurrency: {
    id: 'createAccCurrency',
    defaultMessage: 'Create new currency',
  },
  accName: {
    id: 'accName',
    defaultMessage: 'Account name',
  },
  createAccName: {
    id: 'createAccName',
    defaultMessage: 'Create new account name',
  },
  noPairManageAccess: {
    id: 'noPairManageAccess',
    defaultMessage:
      "You don't have permission to make transactions for this account.",
  },
  transfer: {
    id: 'transfer',
    defaultMessage: 'Transfer',
  },
  account: {
    id: 'account',
    defaultMessage: 'Account',
  },
  amount: {
    id: 'amount',
    defaultMessage: 'Amount',
  },
  amountAndFee: {
    id: 'amountAndFee',
    defaultMessage: 'Total & Fee',
  },
  enterAmount: {
    id: 'enterAmount',
    defaultMessage: 'Enter amount',
  },
  accFact: {
    id: 'accFact',
    defaultMessage: 'Fact',
  },
  accPlan: {
    id: 'accPlan',
    defaultMessage: 'Plan',
  },
  showPlanAccount: {
    id: 'showPlanAccount',
    defaultMessage: 'Show Plan (P) and Fact (F) values',
  },
  margin: {
    id: 'margin',
    defaultMessage: 'Margin',
  },
  sort_nameAsc: {
    id: 'sort_nameAsc',
    defaultMessage: 'by name: A to Z',
  },
  sort_nameDesc: {
    id: 'sort_nameDesc',
    defaultMessage: 'by name: Z to A',
  },
  sort_totalAsc: {
    id: 'sort_totalAsc',
    defaultMessage: 'by total: 0-10',
  },
  sort_totalDesc: {
    id: 'sort_totalDesc',
    defaultMessage: 'by total: 10-0',
  },
  bp_one_time_pay: {
    id: 'bp_one_time_pay',
    defaultMessage: 'One time pay',
  },
  bp_day: {
    id: 'bp_day',
    defaultMessage: 'Daily',
  },
  bp_week: {
    id: 'bp_week',
    defaultMessage: 'Weekly',
  },
  bp_month: {
    id: 'bp_month',
    defaultMessage: 'Monthly',
  },
  bp_year: {
    id: 'bp_year',
    defaultMessage: 'Yearly',
  },
  'bp_3-month': {
    id: 'bp_3-month',
    defaultMessage: 'Every 3 month',
  },
  'bp_4-month': {
    id: 'bp_4-month',
    defaultMessage: 'Every 4 month',
  },
  'bp_6-month': {
    id: 'bp_6-month',
    defaultMessage: 'Every 6 month',
  },
  generalDetails: {
    id: 'generalDetails',
    defaultMessage: 'General details',
  },
  extraData: {
    id: 'extraData',
    defaultMessage: 'Extra data',
  },
  details: {
    id: 'details',
    defaultMessage: 'Details',
  },
  weight: {
    id: 'weight',
    defaultMessage: 'Weight',
  },
  id: {
    id: 'id',
    defaultMessage: 'ID',
  },
  idAndRefId: {
    id: 'idAndRefId',
    defaultMessage: 'ID & Ref',
  },
  actorId: {
    id: 'actorId',
    defaultMessage: 'Actor ID',
  },
  refId: {
    id: 'refId',
    defaultMessage: 'Reference ID',
  },
  actorType: {
    id: 'actorType',
    defaultMessage: 'Actor type',
  },
  string: {
    id: 'string',
    defaultMessage: 'String',
  },
  number: {
    id: 'number',
    defaultMessage: 'Numeric',
  },
  numeric: {
    id: 'numeric',
    defaultMessage: 'Numeric',
  },
  multiple: {
    id: 'multiple',
    defaultMessage: 'Multiple',
  },
  debit: {
    id: 'debit',
    defaultMessage: 'Debit',
  },
  credit: {
    id: 'credit',
    defaultMessage: 'Credit',
  },
  total: {
    id: 'total',
    defaultMessage: 'Total',
  },
  copyId: {
    id: 'copyId',
    defaultMessage: 'Copy ID',
  },
  defaultIdCopied: {
    id: 'defaultIdCopied',
    defaultMessage: 'ID has been copied to clipboard',
  },
  defaultRefCopied: {
    id: 'defaultRefCopied',
    defaultMessage: 'Ref has been copied to clipboard',
  },
  actorName: {
    id: 'actorName',
    defaultMessage: 'Actor name',
  },
  system: {
    id: 'system',
    defaultMessage: 'System',
  },
  view: {
    id: 'view',
    defaultMessage: 'View',
  },
  unnamedForm: {
    id: 'unnamedForm',
    defaultMessage: 'Unnamed Form',
  },
  sharedWith: {
    id: 'sharedWith',
    defaultMessage: 'Shared with',
  },
  apply: {
    id: 'apply',
    defaultMessage: 'Apply',
  },
  searchResults: {
    id: 'searchResults',
    defaultMessage: 'Search results',
  },
  suggestions: {
    id: 'suggestions',
    defaultMessage: 'Suggestions',
  },
  layers: {
    id: 'layers',
    defaultMessage: 'Layers',
  },
  actors: {
    id: 'actors',
    defaultMessage: 'Actors',
  },
  actorsBag: {
    id: 'actorsBag',
    defaultMessage: 'Actors bag',
  },
  graphs: {
    id: 'graphs',
    defaultMessage: 'Graphs',
  },
  simulator: {
    id: 'simulator',
    defaultMessage: 'Simulator',
  },
  from: {
    id: 'from',
    defaultMessage: 'From',
  },
  range_custom: {
    id: 'range_custom',
    defaultMessage: 'Custom range',
  },
  range_today: {
    id: 'range_today',
    defaultMessage: 'Today',
  },
  range_realTime: {
    id: 'range_realTime',
    defaultMessage: 'Real-time',
  },
  range_lineRealTime: {
    id: 'range_lineRealTime',
    defaultMessage: 'Real-time',
  },
  range_yesterday: {
    id: 'range_yesterday',
    defaultMessage: 'Yesterday',
  },
  range_lastWeek: {
    id: 'range_lastWeek',
    defaultMessage: 'Last week',
  },
  range_lastHour: {
    id: 'range_lastHour',
    defaultMessage: 'Last hour',
  },
  range_allTime: {
    id: 'range_allTime',
    defaultMessage: 'All time',
  },
  range_last10Minutes: {
    id: 'range_last10Minutes',
    defaultMessage: 'Last 10 min',
  },
  range_lastMinute: {
    id: 'range_lastMinute',
    defaultMessage: 'Last 1 min',
  },
  range_lastMonth: {
    id: 'range_lastMonth',
    defaultMessage: 'Last month',
  },
  range_previousMonth: {
    id: 'range_previousMonth',
    defaultMessage: 'Previous month',
  },
  delPermanently: {
    id: 'delPermanently',
    defaultMessage: 'Permanently delete',
  },
  layer: {
    id: 'layer',
    defaultMessage: 'Layer',
  },
  createAccount: {
    id: 'createAccount',
    defaultMessage: 'Create account',
  },
  openActor: {
    id: 'openActor',
    defaultMessage: 'Show on the layer',
  },
  openActorDetails: {
    id: 'openActorDetails',
    defaultMessage: 'Open actors details',
  },
  openActorPanel: {
    id: 'openActorPanel',
    defaultMessage: 'Open actors panel',
  },
  flagActor: {
    id: 'flagActor',
    defaultMessage: 'Flag actor',
  },
  removeFlag: {
    id: 'removeFlag',
    defaultMessage: 'Remove flag',
  },
  linkWasCopied: {
    id: 'linkWasCopied',
    defaultMessage: 'Link has been copied to clipboard',
  },
  codeCopied: {
    id: 'codeCopied',
    defaultMessage: 'Code has been copied to clipboard',
  },
  changeOrder: {
    id: 'changeOrder',
    defaultMessage: 'Change order',
  },
  orderBy: {
    id: 'orderBy',
    defaultMessage: 'Sort by',
  },
  order_created_at: {
    id: 'order_created_at',
    defaultMessage: 'Created at',
  },
  order_updated_at: {
    id: 'order_updated_at',
    defaultMessage: 'Updated at',
  },
  order_original_date: {
    id: 'order_original_date',
    defaultMessage: 'Original date',
  },
  searchActorPlaceholder: {
    id: 'searchActorPlaceholder',
    defaultMessage: 'Search actor by name',
  },
  lname_apis: {
    id: 'lname_apis',
    defaultMessage: 'APIs',
  },
  lname_bin: {
    id: 'lname_bin',
    defaultMessage: 'Trash',
  },
  lname_Bin: {
    id: 'lname_Bin',
    defaultMessage: 'Trash',
  },
  lname_company: {
    id: 'lname_company',
    defaultMessage: 'Company',
  },
  lname_external: {
    id: 'lname_external',
    defaultMessage: 'External',
  },
  lname_internal: {
    id: 'lname_internal',
    defaultMessage: 'Internal',
  },
  lname_accessRights: {
    id: 'lname_accessRights',
    defaultMessage: 'Access rights',
  },
  lname_graphRules: {
    id: 'lname_graphRules',
    defaultMessage: 'Graph rules',
  },
  lname_accounts: {
    id: 'lname_accounts',
    defaultMessage: 'Accounts',
  },
  lname_processes: {
    id: 'lname_processes',
    defaultMessage: 'Processes',
  },
  lname_dashboards: {
    id: 'lname_dashboards',
    defaultMessage: 'Dashboards',
  },
  lname_economics: {
    id: 'lname_economics',
    defaultMessage: 'Economics',
  },
  lname_market: {
    id: 'lname_market',
    defaultMessage: 'Market',
  },
  lname_vertical: {
    id: 'lname_vertical',
    defaultMessage: 'Vertical',
  },
  lname_scripts: {
    id: 'lname_scripts',
    defaultMessage: 'Scripts',
  },
  lname_expertSystems: {
    id: 'lname_expertSystems',
    defaultMessage: 'Expert systems',
  },
  actorsLayer: {
    id: 'actorsLayer',
    defaultMessage: 'Actors Layer',
  },
  actorLinkCopied: {
    id: 'actorLinkCopied',
    defaultMessage: 'Actor link has been copied to clipboard',
  },
  accessDenied: {
    id: 'accessDenied',
    defaultMessage: 'Access denied',
  },
  noPairAccess: {
    id: 'noPairAccess',
    defaultMessage:
      "You don't have permission to add this combination of account and currency.",
  },
  layerDetails: {
    id: 'layerDetails',
    defaultMessage: 'layer details',
  },
  openNewTab: {
    id: 'openNewTab',
    defaultMessage: 'Open in new tab',
  },
  openLayer: {
    id: 'openLayer',
    defaultMessage: 'Open layer',
  },
  createLayer: {
    id: 'createLayer',
    defaultMessage: 'Create new layer',
  },
  newLayer: {
    id: 'newLayer',
    defaultMessage: 'New Layer',
  },
  addExistingLayer: {
    id: 'addExistingLayer',
    defaultMessage: 'Add layer',
  },
  shareWith: {
    id: 'shareWith',
    defaultMessage: 'Share with',
  },
  cantEditSomeItems: {
    id: 'cantEditSomeItems',
    defaultMessage: 'You cannot edit these items ({count})',
  },
  collapse: {
    id: 'collapse',
    defaultMessage: 'Collapse',
  },
  or: {
    id: 'or',
    defaultMessage: 'OR',
  },
  rename: {
    id: 'rename',
    defaultMessage: 'Rename',
  },
  shared: {
    id: 'shared',
    defaultMessage: 'Shared',
  },
  tree: {
    id: 'tree',
    defaultMessage: 'Tree',
  },
  market: {
    id: 'market',
    defaultMessage: 'Market',
  },
  yesDelete: {
    id: 'yesDelete',
    defaultMessage: 'Yes, delete',
  },
  lastUpdated: {
    id: 'lastUpdated',
    defaultMessage: 'Last updated',
  },
  formulaErr0: {
    id: 'formulaErr0',
    defaultMessage: 'Account not found.',
  },
  formulaErr1: {
    id: 'formulaErr1',
    defaultMessage:
      'Account formula has circular dependency. Please check the accounts tree.',
  },
  formulaErr2: {
    id: 'formulaErr2',
    defaultMessage: 'Account formula has circular dependency.',
  },
  formulaErr3: {
    id: 'formulaErr3',
    defaultMessage: 'The result of a formula is not a number.',
  },
  formulaErr4: {
    id: 'formulaErr4',
    defaultMessage: 'Error in formula: ',
  },
  advancedOptions: {
    id: 'advancedOptions',
    defaultMessage: 'Advanced options',
  },
  share: {
    id: 'share',
    defaultMessage: 'Share',
  },
  expiration: {
    id: 'expiration',
    defaultMessage: 'Expiration',
  },
  exp: {
    id: 'exp',
    defaultMessage: 'Exp.',
  },
  linkCopied: {
    id: 'linkCopied',
    defaultMessage: 'Link was copied',
  },
  tplProperties: {
    id: 'tplProperties',
    defaultMessage: 'Form properties',
  },
  accessLevel_guest: {
    id: 'accessLevel_guest',
    defaultMessage: 'Guest',
  },
  accessLevel_user: {
    id: 'accessLevel_user',
    defaultMessage: 'Member',
  },
  accessLevel_admin: {
    id: 'accessLevel_admin',
    defaultMessage: 'Admin',
  },
  scriptSharedWith_userList: {
    id: 'scriptSharedWith_userList',
    defaultMessage: 'Users from the list',
  },
  scriptSharedWith_allWorkspaceUsers: {
    id: 'scriptSharedWith_allWorkspaceUsers',
    defaultMessage: 'All workspace users',
  },
  scriptSharedWith_allRegisteredUsers: {
    id: 'scriptSharedWith_allRegisteredUsers',
    defaultMessage: 'All registered users',
  },
  scriptSharedWith_anyone: {
    id: 'scriptSharedWith_anyone',
    defaultMessage: 'Anyone with the link',
  },
  transferAccounts: {
    id: 'transferAccounts',
    defaultMessage: 'Transfer accounts',
  },
  old: {
    id: 'old',
    defaultMessage: 'Old',
  },
  new: {
    id: 'new',
    defaultMessage: 'new',
  },
  empty: {
    id: 'empty',
    defaultMessage: 'empty',
  },
  openAll: {
    id: 'openAll',
    defaultMessage: 'Open all sections',
  },
  closeAll: {
    id: 'closeAll',
    defaultMessage: 'Close all sections',
  },
  accessLevel: {
    id: 'accessLevel',
    defaultMessage: 'Access Level',
  },
  ip: {
    id: 'ip',
    defaultMessage: 'IP',
  },
  os: {
    id: 'os',
    defaultMessage: 'OS',
  },
  field: {
    id: 'field',
    defaultMessage: 'Field',
  },
  value: {
    id: 'value',
    defaultMessage: 'Value',
  },
  forms: {
    id: 'forms',
    defaultMessage: 'Forms',
  },
  itemsWontEdited: {
    id: 'itemsWontEdited',
    defaultMessage: "You don't have an access to edit {count} item(s).",
  },
  itemsWontDeleted: {
    id: 'itemsWontDeleted',
    defaultMessage: "You don't have an access to delete {count} item(s).",
  },
  addToExport: {
    id: 'addToExport',
    defaultMessage: 'Add to Export Graph queue',
  },
  itemAddedToExport: {
    id: 'itemAddedToExport',
    defaultMessage: 'Item successfully added to Export Graph queue',
  },
  itemsAddedToExport: {
    id: 'itemsAddedToExport',
    defaultMessage: '{count} items successfully added to Export Graph',
  },
  showDetails: {
    id: 'showDetails',
    defaultMessage: 'Show details',
  },
  hideDetails: {
    id: 'hideDetails',
    defaultMessage: 'Hide details',
  },
  navigation: {
    id: 'navigation',
    defaultMessage: 'Navigation',
  },
  continueWithoutItems: {
    id: 'continueWithoutItems',
    defaultMessage: 'Continue without these items',
  },
  copyLink: {
    id: 'copyLink',
    defaultMessage: 'Copy link',
  },
  removeAdminInfo: {
    id: 'removeAdminInfo',
    defaultMessage: 'Transaction removal is disabled by superadmin',
  },
  removeLastInfo: {
    id: 'removeLastInfo',
    defaultMessage: 'Only the last transaction can be deleted',
  },
  showTransactions: {
    id: 'showTransactions',
    defaultMessage: 'Show transactions',
  },
  showDashboard: {
    id: 'showDashboard',
    defaultMessage: 'Show dashboard',
  },
  originalDate: {
    id: 'originalDate',
    defaultMessage: 'Original date',
  },
  dragImage: {
    id: 'dragImage',
    defaultMessage: 'Drag and drop your image here',
  },
  imageSize: {
    id: 'imageSize',
    defaultMessage: 'JPG, PNG up to {fileSize}MB',
  },
  pin: {
    id: 'pin',
    defaultMessage: 'Pin',
  },
  unpin: {
    id: 'unpin',
    defaultMessage: 'Unpin',
  },
  addChart: {
    id: 'addChart',
    defaultMessage: 'Add Chart',
  },
  actorNotAvailableInfo: {
    id: 'actorNotAvailableInfo',
    defaultMessage:
      'Selected actor has been removed or access rights have been changed.',
  },
  transferCreated: {
    id: 'transferCreated',
    defaultMessage: 'Transfer has been successfully created.',
  },
  enterAccName: {
    id: 'enterAccName',
    defaultMessage: 'Enter account name',
  },
  enterAccCurrency: {
    id: 'enterAccCurrency',
    defaultMessage: 'Enter currency name',
  },
  invalidJson: {
    id: 'invalidJson',
    defaultMessage: 'Invalid JSON',
  },
  editAccessDenied: {
    id: 'editAccessDenied',
    defaultMessage: 'no editing access',
  },
  downloadFilesFailed: {
    id: 'downloadFilesFailed',
    defaultMessage: 'An error occurred while downloading actor attachments',
  },
  refInfo: {
    id: 'refInfo',
    defaultMessage: 'A string value for making actors API requests by ref',
  },
  refInfoWarning: {
    id: 'refInfoWarning',
    defaultMessage: 'Must be unique within a form',
  },
  startCall: {
    id: 'startCall',
    defaultMessage: 'Start Call',
  },
});
