import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Space, Card, PopoverBoundary } from 'mw-style-react';

import { useIntl } from 'hooks';
import ContentWithStickyActions from '@control-front-end/common/components/ContentWithStickyActions';
import { ImportSettings } from 'components';

import m from './intl';
import scss from './ImportSettingsModal.scss';

/** Renders a modal component for mapping access rules for participants. */
function ImportSettingsModal({
  onClose,
  data: { onSubmit, onChange, participants, scripts },
}) {
  const t = useIntl();

  const { boundaryRef } = useContext(PopoverBoundary.Context);

  const [importSettings, setImportSettings] = useState({});

  const handleSubmit = useCallback(
    (...args) => {
      onChange(importSettings);
      onSubmit(...args);
      onClose(...args);
    },
    [importSettings]
  );

  return (
    <Modal
      size="xlarge"
      className={scss.modal}
      onClose={onClose}
      label={t(m.rulesMapping)}
      strict
    >
      <ContentWithStickyActions
        content={
          <div ref={boundaryRef}>
            <ImportSettings
              participants={participants}
              scripts={scripts}
              onChange={setImportSettings}
            />
          </div>
        }
        actions={
          <Card>
            <Space>
              <Button
                size="large"
                fontWeight="normal"
                label={t(m.proceed)}
                fullWidth
                onClick={handleSubmit}
              />
            </Space>
          </Card>
        }
      />
    </Modal>
  );
}

ImportSettingsModal.propTypes = {
  data: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    participants: PropTypes.array.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default ImportSettingsModal;
