import AppUtils from './utils';

// Extracted from https://github.com/nagix/chartjs-plugin-colorschemes
// because of incompatibility with new Chart.js versions
// See colors here: https://nagix.github.io/chartjs-plugin-colorschemes/colorchart.html
const BASE_HEX = 16;
const MAX_RGB_VALUE = 255;
const SHADE_PERCENT = 1.3;
const HEX_COLOR_REGEX = /^#?[0-9A-F]{6}$/i;

const palette = {
  Tableau10: [
    '#4E79A7',
    '#F28E2B',
    '#E15759',
    '#76B7B2',
    '#59A14F',
    '#EDC948',
    '#B07AA1',
    '#FF9DA7',
    '#9C755F',
    '#BAB0AC',
  ],
  Tableau20: [
    '#4E79A7',
    '#A0CBE8',
    '#F28E2B',
    '#FFBE7D',
    '#59A14F',
    '#8CD17D',
    '#B6992D',
    '#F1CE63',
    '#499894',
    '#86BCB6',
    '#E15759',
    '#FF9D9A',
    '#79706E',
    '#BAB0AC',
    '#D37295',
    '#FABFD2',
    '#B07AA1',
    '#D4A6C8',
    '#9D7660',
    '#D7B5A6',
  ],
};

const hexToRgb = (color) => {
  const base = color.startsWith('#') ? 1 : 0;
  const r = parseInt(color.substring(base, base + 2), BASE_HEX);
  const g = parseInt(color.substring(base + 2, base + 4), BASE_HEX);
  const b = parseInt(color.substring(base + 4, base + 6), BASE_HEX);
  return { r, g, b };
};

const getPercentColor = (color, percent) => {
  if (!color || !HEX_COLOR_REGEX.test(color)) {
    return 'transparent';
  }

  const { r, g, b } = hexToRgb(color);

  const alpha = percent / 100;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const shadeColor = (color, percent) => {
  let { r, g, b } = hexToRgb(color);

  r = Math.round(r / percent);
  g = Math.round(g / percent);
  b = Math.round(b / percent);

  r = r < MAX_RGB_VALUE ? r : MAX_RGB_VALUE;
  g = g < MAX_RGB_VALUE ? g : MAX_RGB_VALUE;
  b = b < MAX_RGB_VALUE ? b : MAX_RGB_VALUE;

  const rr =
    r.toString(BASE_HEX).length === 1
      ? `0${r.toString(BASE_HEX)}`
      : r.toString(BASE_HEX);
  const gg =
    g.toString(BASE_HEX).length === 1
      ? `0${g.toString(BASE_HEX)}`
      : g.toString(BASE_HEX);
  const bb =
    b.toString(BASE_HEX).length === 1
      ? `0${b.toString(BASE_HEX)}`
      : b.toString(BASE_HEX);
  return `#${rr}${gg}${bb}`;
};

/**
 * Make color palette for chart dataset
 * @param count
 * @returns {string[]}
 */
export const makeDefaultPalette = (count) => {
  const { Tableau10, Tableau20 } = palette;
  if (count <= Tableau10.length) {
    return Tableau10;
  }
  if (count > Tableau10.length && count <= Tableau20.length) {
    return Tableau20;
  }
  const extendedPalette = [...Tableau20];
  for (let i = 0; i < count - Tableau20.length; i += 1) {
    const newColor = shadeColor(extendedPalette[i], SHADE_PERCENT);
    extendedPalette.push(newColor);
  }
  return extendedPalette;
};

const getRandomColorByPalette = (
  currentPalette = [],
  defaultPalette = palette.Tableau20
) => {
  if (currentPalette.length === 0) {
    return defaultPalette[
      Math.floor(AppUtils.safeRandom() * defaultPalette.length)
    ];
  }

  if (currentPalette.length > defaultPalette.length) {
    const extraColorIndex = currentPalette.length - defaultPalette.length - 1;
    return shadeColor(currentPalette[extraColorIndex], SHADE_PERCENT);
  }

  const availableColors = defaultPalette.filter(
    (color) => !currentPalette.includes(color)
  );
  return availableColors[
    Math.floor(AppUtils.safeRandom() * availableColors.length)
  ];
};

const getRandomColors = (count, defaultPalette = palette.Tableau20) => {
  const colors = [];
  let currentPalette = [];

  for (let i = 0; i < count; i += 1) {
    const newColor = getRandomColorByPalette(currentPalette, defaultPalette);
    colors.push(newColor);
    currentPalette = [...currentPalette, newColor];
  }

  return colors;
};

export const isLightColor = (hexcolor) => {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 186;
};

export const getContrastColor = (hexcolor) => {
  return isLightColor(hexcolor) ? 'black' : 'white';
};

export default {
  getRandomColorByPalette,
  getRandomColors,
  makeDefaultPalette,
  getPercentColor,
  isLightColor,
  getContrastColor,
};
