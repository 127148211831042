import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Icon } from 'mw-style-react';
import cn from 'classnames';
import scss from './UserAvatar.scss';

const USER_TYPES_WITH_ICONS = ['api', 'guest', 'anonymous'];

function UserAvatar({
  className,
  userType = 'user',
  src,
  label,
  size = 'medium',
  customIcon,
  children = null,
  bgColor,
  onClick,
}) {
  const renderIcon = () => {
    if (src || (!customIcon && !USER_TYPES_WITH_ICONS.includes(userType)))
      return null;
    const iconType = customIcon?.type || userType;
    return (
      <Icon
        size={customIcon?.size || size}
        type={iconType}
        color={customIcon?.color}
        colorType={customIcon?.colorType}
      />
    );
  };

  return (
    <Avatar
      className={cn(scss.avatar, className)}
      src={src}
      label={label}
      size={size}
      bgColor={bgColor}
      onClick={onClick}
    >
      {renderIcon()}
      {children}
      {src && USER_TYPES_WITH_ICONS.includes(userType) ? (
        <div styleName={cn('userBadge', userType, size)} />
      ) : null}
    </Avatar>
  );
}

UserAvatar.propTypes = {
  className: PropTypes.string,
  userType: PropTypes.string,
  src: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  customIcon: PropTypes.object,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default UserAvatar;
