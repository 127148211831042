// https://demo.qunee.com/svg2canvas/
import { mapValues } from 'lodash';
import { CorezoidLightTheme as theme } from 'mw-style-react';
import SVGIconVerified from './icons/status/SVGIconVerified';
import SVGIconPending from './icons/status/SVGIconPending';
import SVGIconPinned from './icons/SVGIconPinned';
import SVGIconChildren from './icons/counters/SVGIconChildren';
import SVGIconRejected from './icons/status/SVGIconRejected';
import SVGIconEvent from './icons/systemForms/SVGIconEvent';
import SVGIconUp from './icons/balance/SVGIconUp';
import SVGIconDown from './icons/balance/SVGIconDown';
import SVGIconFilter from './icons/systemForms/SVGIconFilter';
import SVGIconTrigger from './icons/systemForms/SVGIconTrigger';
import SVGIconCommentsWidget from './icons/systemForms/SVGIconCommentsWidget';
import SVGIconWidget from './icons/systemForms/SVGIconWidget';
import SVGIconCompany from './icons/systemForms/SVGIconCompany';
import SVGIconCategory from './icons/systemForms/SVGIconCategory';
import SVGIconGraph from './icons/systemForms/SVGIconGraph';
import SVGIconLayers from './icons/systemForms/SVGIconLayers';
import SVGIconScript from './icons/systemForms/SVGIconScript';
import SVGIconSnippet from './icons/systemForms/SVGIconSnippet';
import SVGIconState from './icons/systemForms/SVGIconState';
import SVGIconStream from './icons/systemForms/SVGIconStream';
import SVGIconTag from './icons/systemForms/SVGIconTag';
import SVGIconDashboard from './icons/systemForms/SVGIconDashboard';
import SVGIconTransfer from './icons/systemForms/SVGIconTransfer';
import SVGIconLayersCount from './icons/counters/SVGIconLayersCount';
import SVGIconForms from './icons/counters/SVGIconForms';
import SVGIconActor from './icons/counters/SVGIconActor';

const NODE_LABEL_ICONS = {
  // common
  pinned: SVGIconPinned,
  flag: (ctx) => {
    ctx.beginPath();
    ctx.arc(0, 0, 4, 0, Math.PI * 2);
    ctx.fillStyle = theme.palette.red;
    ctx.fill();
  },
  // actor status
  verified: SVGIconVerified,
  pending: SVGIconPending,
  rejected: SVGIconRejected,
  // counters
  children: SVGIconChildren,
  layersCount: SVGIconLayersCount,
  linksCount: SVGIconLayersCount,
  tplsCount: SVGIconForms,
  actorsCount: SVGIconActor,
  graphsCount: SVGIconGraph,
  // system forms
  AccountTriggers: SVGIconTrigger,
  ActorFilters: SVGIconFilter,
  CommentsWidgets: SVGIconCommentsWidget,
  CompanyCard: SVGIconCompany,
  CompanyCategories: SVGIconCategory,
  Dashboards: SVGIconDashboard,
  Events: SVGIconEvent,
  Graphs: SVGIconGraph,
  Layers: SVGIconLayers,
  Scripts: SVGIconScript,
  Snippets: SVGIconSnippet,
  State: SVGIconState,
  Streams: SVGIconStream,
  Tags: SVGIconTag,
  TransactionFilters: SVGIconFilter,
  TransferFilters: SVGIconFilter,
  Transfers: SVGIconTransfer,
  Widgets: SVGIconWidget,
  // balance vector
  up: SVGIconUp,
  down: SVGIconDown,
};

const renderIconOnCanvas = () => {
  const drawOnCanvas = (ctx, position, renderFunc) => {
    const { x, y } = position;
    ctx.save();
    ctx.translate(x, y);
    renderFunc(ctx);
    ctx.closePath();
    ctx.restore();
  };

  return mapValues(NODE_LABEL_ICONS, (renderFunc) => {
    return (ctx, position) => drawOnCanvas(ctx, position, renderFunc);
  });
};

export default renderIconOnCanvas;
