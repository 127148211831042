function SVGIconChildren(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = '#ACB3BE';
  ctx.beginPath();
  ctx.arc(4, 2, 2, 0, 6.283185307179586, false);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = '#ACB3BE';
  ctx.beginPath();
  ctx.moveTo(5, 5);
  ctx.bezierCurveTo(4.5, 5, 4.37054, 5, 4, 5);
  ctx.bezierCurveTo(3.68042, 5, 3.5, 5, 3, 5);
  ctx.lineTo(3, 8);
  ctx.lineTo(0, 8);
  ctx.lineTo(4.04848, 12);
  ctx.lineTo(8, 8);
  ctx.lineTo(5, 8);
  ctx.lineTo(5, 5);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconChildren;
