function SVGIconDown(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.save();
  ctx.fillStyle = '#DB4437';
  ctx.globalAlpha = 0.3;
  ctx.beginPath();
  ctx.moveTo(6, 0);
  ctx.lineTo(6, 0);
  ctx.bezierCurveTo(9.313708498984761, 0, 12, 2.686291501015239, 12, 6);
  ctx.lineTo(12, 6);
  ctx.bezierCurveTo(12, 9.313708498984761, 9.313708498984761, 12, 6, 12);
  ctx.lineTo(6, 12);
  ctx.bezierCurveTo(2.686291501015239, 12, 0, 9.313708498984761, 0, 6);
  ctx.lineTo(0, 6);
  ctx.bezierCurveTo(0, 2.686291501015239, 2.686291501015239, 0, 6, 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = '#DB4437';
  ctx.beginPath();
  ctx.moveTo(3.14778, 6.73419);
  ctx.lineTo(5.64571, 8.87781);
  ctx.bezierCurveTo(5.69323, 8.91684, 5.74925, 8.94743, 5.81058, 8.96784);
  ctx.bezierCurveTo(5.93221, 9.01072, 6.06863, 9.01072, 6.19026, 8.96784);
  ctx.bezierCurveTo(6.25159, 8.94743, 6.30761, 8.91684, 6.35513, 8.87781);
  ctx.lineTo(8.85306, 6.73419);
  ctx.bezierCurveTo(8.97979, 6.62544, 9.02928, 6.46693, 8.9829, 6.31838);
  ctx.bezierCurveTo(8.93651, 6.16982, 8.8013, 6.05379, 8.62819, 6.01398);
  ctx.bezierCurveTo(8.45508, 5.97418, 8.27038, 6.01665, 8.14365, 6.1254);
  ctx.lineTo(6.50001, 7.54019);
  ctx.lineTo(6.50001, 3.42872);
  ctx.bezierCurveTo(6.50001, 3.19195, 6.27634, 3, 6.00042, 3);
  ctx.bezierCurveTo(5.72451, 3, 5.50083, 3.19195, 5.50083, 3.42872);
  ctx.lineTo(5.50083, 7.54019);
  ctx.lineTo(3.85719, 6.1254);
  ctx.bezierCurveTo(3.76339, 6.04424, 3.63569, 5.99858, 3.50248, 5.99858);
  ctx.bezierCurveTo(3.36928, 5.99858, 3.24158, 6.04424, 3.14778, 6.1254);
  ctx.bezierCurveTo(3.0532, 6.2059, 3, 6.31548, 3, 6.42979);
  ctx.bezierCurveTo(3, 6.54411, 3.0532, 6.65369, 3.14778, 6.73419);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDown;
