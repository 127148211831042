import { useState, useCallback, useRef } from 'react';
import { mergeWith } from 'lodash';
import { cr } from 'mw-style-react';

import { EXP_NODE } from '@control-front-end/common/constants/graphActors';
import {
  snap,
  getGraphCursorPosition,
} from '@control-front-end/utils/modules/utilsCellCoords';

export const POSITIONING = {
  leftTop: 'leftTop',
  rightBottom: 'rightBottom',
};

const useCellsResize = (props) => {
  const [size, setSize] = useState(props.size);
  const sizeRef = useRef(props.size);
  sizeRef.current = size;

  const cursorPosition = useRef({ x: 0, y: 0 });

  const [offset, setOffset] = useState(props.offset);

  const [offsetDiff, setOffsetDiff] = useState(EXP_NODE.offset.none);

  const [minConstraints, setMinConstraints] = useState(props.minConstraints);

  const [positioning, setPositioning] = useState(POSITIONING.leftTop);

  const onResizeStart = (e, { size: startSize, handle }) => {
    cursorPosition.current = getGraphCursorPosition(document.cy, {
      originalEvent: e,
    });

    const minWidth = cr(
      [
        handle.includes('w'),
        Math.max(startSize.width - offset.left, props.minConstraints[0]),
      ],
      [
        handle.includes('e'),
        Math.max(startSize.width - offset.right, props.minConstraints[0]),
      ],
      [true, 0]
    );

    const minHeight = cr(
      [
        handle.includes('n'),
        Math.max(startSize.height - offset.top, props.minConstraints[1]),
      ],
      [
        handle.includes('s'),
        Math.max(startSize.height - offset.bottom, props.minConstraints[1]),
      ],
      [true, 0]
    );

    if (handle.includes('w') || handle.includes('n'))
      setPositioning(POSITIONING.rightBottom);
    else setPositioning(POSITIONING.leftTop);

    setMinConstraints([minWidth, minHeight]);
  };

  const onResizeStop = () => {
    setOffset((prevState) =>
      mergeWith({ ...prevState }, offsetDiff, (a, b) => a + b)
    );
    setOffsetDiff(EXP_NODE.offset.none);

    setMinConstraints(props.minConstraints);
  };

  const onResize = useCallback((e, { size: newSize, handle }) => {
    const newSizeSnapped = snap(newSize);

    const deltaX = newSizeSnapped.width - sizeRef.current.width;
    const deltaY = newSizeSnapped.height - sizeRef.current.height;

    setOffset((prevOffset) => {
      return mergeWith(
        { ...prevOffset },
        {
          left: handle.includes('w') ? deltaX : 0,
          top: handle.includes('n') ? deltaY : 0,
          right: handle.includes('e') ? deltaX : 0,
          bottom: handle.includes('s') ? deltaY : 0,
        },
        (a, b) => Math.max(a + b, 0)
      );
    });

    setSize(newSizeSnapped);
  }, []);

  return {
    size,
    offset,
    positioning,
    minConstraints,
    onResize,
    onResizeStart,
    onResizeStop,
  };
};

export default useCellsResize;
