import AppUtils from '@control-front-end/utils/utils';
import { call, select, put, takeLatest } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import { SEARCH, RequestStatus, SET_SEARCH_REQ_STATUS } from '../constants';

/**
 * Search events, people and tags
 */
function* searchQuery({ payload, callback, finallyCallback }) {
  const accounts = yield select((state) => state.accounts);
  const config = yield select((state) => state.config);
  const {
    filters,
    formId,
    query,
    limit = 15,
    offset = 0,
    loadMore = false,
  } = payload;
  const searchQ = encodeURIComponent(query);
  if (!searchQ.length) return;
  // Set search request status to progress
  yield put({ type: SET_SEARCH_REQ_STATUS, payload: RequestStatus.PROGRESS });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/search/${accounts.active}/${searchQ}`,
    queryParams: { formId, filters, limit, offset },
  });
  // Set search request status to success
  yield put({ type: SET_SEARCH_REQ_STATUS, payload: RequestStatus.SUCCESS });
  if (result !== RequestStatus.SUCCESS) return;
  data.data.forEach((item) => {
    if (item.objType === 'user') {
      item.avatar = AppUtils.makeUserAvatar(item, config);
    } else if (item.picture) {
      item.pictureUrl = AppUtils.makeAppUrl(`/download/${item.picture}`);
    }
    item.ownerAvatar = AppUtils.makeUserAvatar(item, config);
    if (item.ownerId === 0) item.ownerAvatar = config.systemUserAvatar;
  });
  if (callback) {
    callback(data.data);
  } else {
    const { list: searchList } = yield select((state) => state.search);
    const newSearchList = loadMore ? structuredClone(searchList) : [];
    newSearchList.push(...data.data);
    yield put({
      type: SEARCH.SUCCESS,
      payload: {
        list: newSearchList,
        offset: offset + limit,
        endList: !data.data.length,
      },
    });
  }
  finallyCallback?.(data.data);
}

function* search() {
  yield takeLatest(SEARCH.REQUEST, searchQuery);
}

export default search;
