import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  lineDashboardDynamic: {
    id: 'lineDashboardDynamic',
    defaultMessage: 'Transactions dynamic',
  },
  lineDashboardBalanceChanges: {
    id: 'lineDashboardBalanceChanges',
    defaultMessage: 'Dynamics of balance changes',
  },
  editChart: {
    id: 'editChart__CreateDashboard',
    defaultMessage: 'Edit Chart',
  },
  chartType: {
    id: 'chartType__CreateDashboard',
    defaultMessage: 'Chart type',
  },
  chartCounterType: {
    id: 'chartCounterType__CreateDashboard',
    defaultMessage: 'Account value type by default',
  },
  counterType_amount: {
    id: 'counterType_amount__CreateDashboard',
    defaultMessage: 'Account amount',
  },
  counterType_count: {
    id: 'counterType_count__CreateDashboard',
    defaultMessage: 'Count of transactions',
  },
  sourceType: {
    id: 'dashboardType',
    defaultMessage: 'Dashboard type',
  },
  sourceType_manual: {
    id: 'sourceType_manual',
    defaultMessage: 'Manual actor addition',
  },
  sourceType_actorFilter: {
    id: 'sourceType_actorFilter',
    defaultMessage: 'Dynamic by ActorFilters',
  },
  addSource: {
    id: 'addSource__CreateDashboard',
    defaultMessage: 'Add source',
  },
  chartPeriod: {
    id: 'chartPeriod__CreateDashboard',
    defaultMessage: 'Period by default',
  },
  dashboardType: {
    id: 'dashboardType__CreateDashboard',
    defaultMessage: 'Dashboard Type',
  },
  defaultAccountTitle: {
    id: 'defaultAccountTitle__CreateDashboard',
    defaultMessage: 'Account by default',
  },
  defaultAccountPlaceholder: {
    id: 'defaultAccountPlaceholder__CreateDashboard',
    defaultMessage: 'Select account',
  },
  noAccountsSelected: {
    id: 'noAccountsSelected__CreateDashboard',
    defaultMessage: 'Select at least one account',
  },
  duplicateAccounts: {
    id: 'duplicateAccounts__CreateDashboard',
    defaultMessage: `To select the same account multiple times, please enable 
  the 'Income type' option in Legend settings and choose different income types.`,
  },
  selectedFilterHasNoAccount: {
    id: 'selectedFilterHasNoAccount',
    defaultMessage: 'Selected actor filter has no account',
  },
  selectedFilterTopValueError: {
    id: 'selectedFilterTopValueError__CreateDashboard',
    defaultMessage:
      'Top value for dynamic dashboards must be between 1 and 100.',
  },
  useActorsColor: {
    id: 'useActorsColor__CreateDashboard',
    defaultMessage: 'Use actors color',
  },
  useActorsColorInfo: {
    id: 'useActorsColorInfo__CreateDashboard',
    defaultMessage:
      'If the checkbox is not selected, random colors will be used for the dashboard',
  },
  legendSettings: {
    id: 'legendSettings__CreateDashboard',
    defaultMessage: 'Legend settings',
  },
  legendInfo: {
    id: 'legendInfo__CreateDashboard',
    defaultMessage:
      'If the checkbox is not selected, the entity will not be displayed',
  },
  defaultAccountTooltip: {
    id: 'defaultAccountTooltip__CreateDashboard',
    defaultMessage: `If the default account is selected, this will allow for the reconstruction
      of dashboards for selected actors and other accounts.`,
  },
  displayTotal: {
    id: 'displayTotal__CreateDashboard',
    defaultMessage: 'Display the total',
  },
  l_actorTitle: {
    id: 'l_actorTitle__CreateDashboard',
    defaultMessage: 'Actor Name',
  },
  l_accountName: {
    id: 'l_accountName__CreateDashboard',
    defaultMessage: 'Account Name',
  },
  l_currencyName: {
    id: 'l_currencyName__CreateDashboard',
    defaultMessage: 'Currency',
  },
  l_incomeType: {
    id: 'l_incomeType__CreateDashboard',
    defaultMessage: 'Income type',
  },
  sorting: {
    id: 'sorting__CreateDashboard',
    defaultMessage: 'Sorting',
  },
  order_default: {
    id: 'order_default__CreateDashboard',
    defaultMessage: 'Unsorted',
  },
  order_asc: {
    id: 'order_asc__CreateDashboard',
    defaultMessage: 'Ascending order',
  },
  order_desc: {
    id: 'order_desc__CreateDashboard',
    defaultMessage: 'Descending order',
  },
  yAxisLabel: {
    id: 'yAxisLabel__CreateDashboard',
    defaultMessage: 'Y-Axis Label',
  },
  yAxisInfo: {
    id: 'yAxisInfo__CreateDashboard',
    defaultMessage: 'By default value type will be used as a label',
  },
  color: {
    id: 'color_CreateDashbord__CreateDashboard',
    defaultMessage: 'Color',
  },
  regenerateColors: {
    id: 'regenerateColors__CreateDashboard',
    defaultMessage: 'Regenerate all colors',
  },
  displayValues: {
    id: 'displayValues__CreateDashboard',
    defaultMessage: 'Display values in sections',
  },
  dashboardOriginalDateTooltip: {
    id: 'dashboardOriginalDateTooltip',
    defaultMessage:
      'The transaction original date is used to create a dashboard',
  },
});

export default { ...globalIntl, ...m };
