function SVGIconLayers(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.75, 0);
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(7.5, 11.7462);
  ctx.lineTo(13.6333, 6.93865);
  ctx.lineTo(15, 5.8731);
  ctx.lineTo(7.5, 0);
  ctx.lineTo(0, 5.8731);
  ctx.lineTo(1.35833, 6.93865);
  ctx.lineTo(7.5, 11.7462);
  ctx.closePath();
  ctx.moveTo(7.5, 13.8773);
  ctx.lineTo(1.35, 9.06974);
  ctx.lineTo(0, 10.1269);
  ctx.lineTo(7.5, 16);
  ctx.lineTo(15, 10.1269);
  ctx.lineTo(13.6417, 9.06135);
  ctx.lineTo(7.5, 13.8773);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconLayers;
