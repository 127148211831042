import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  DateUtils,
  Stack,
  Label,
  cr,
  Popover,
  Space,
  Card,
} from 'mw-style-react';

import { DATE_FORMAT_5, CALENDAR_VIEWS } from 'constants';
import AppUtils from '@control-front-end/utils/utils';

import { CutEventLabel, Description, EventDote, EventMenu } from '..';

import {
  getNumberOfRowsBasedOnDuration,
  DEFAULT_EVENT_BG_COLOR,
  DEFAULT_TIME_FORMAT,
} from '../../utils';

import scss from '../../EventsCalendar.scss';

function Event({
  isMoreMenuOpen,
  event: {
    extendedProps: {
      users,
      startUnixtime,
      endUnixtime,
      description,
      eventId,
      isMultiDay,
      actorBgColor = DEFAULT_EVENT_BG_COLOR,
      privs,
    },
    title = '',
    allDay,
  },
  timeText,
  view: { type },
  handleRemoveEvent,
  handleOpenEvent,
  handleCloneEvent,
  onClick,
  disableMenu,
  accId = '',
  isSelected,
  isBlured,
  onSelect,
  timeFormat = DEFAULT_TIME_FORMAT,
}) {
  const isMonthGrid = type === CALENDAR_VIEWS.dayGridMonth;
  const isDayGrid = type === CALENDAR_VIEWS.timeGridDay;
  const isWeekGrid = type === CALENDAR_VIEWS.timeGridWeek;
  const eventCardRef = useRef(null);

  const withBgColor =
    (!allDay && (isDayGrid || isWeekGrid || (isMonthGrid && isMultiDay))) ||
    isSelected;
  const textColor = withBgColor
    ? AppUtils.getContrastColor(actorBgColor)
    : 'black';
  const borderColor = withBgColor ? 'white' : 'transparent';
  const backgroundColor = withBgColor ? actorBgColor : 'transparent';
  const dateFormat = `${DATE_FORMAT_5} ${
    timeFormat === DateUtils.TIME_FORMATS[12] ? ' AMPM' : ''
  }`;

  const numberOfRows = getNumberOfRowsBasedOnDuration(
    startUnixtime,
    endUnixtime
  );

  useEffect(() => {
    if (eventCardRef.current) {
      const fcEvent = eventCardRef.current.closest('.fc-event');
      const fcEventParent = fcEvent?.parentElement;

      if (fcEventParent) {
        if (isSelected) {
          fcEventParent.classList.add(scss.fcEventParentSelected);
        } else {
          fcEventParent.classList.remove(scss.fcEventParentSelected);
        }
      }
    }
  }, [isSelected]);

  const getFullTimeLabel = () => {
    let timeLabelValue = timeText;

    if (startUnixtime && endUnixtime) {
      const startTimeLabel = DateUtils.toDate(
        startUnixtime,
        dateFormat,
        timeFormat
      );
      const showEndTime = endUnixtime && (isDayGrid || isWeekGrid);
      const endTimeLabel = showEndTime
        ? `${DateUtils.toDate(endUnixtime, ` - ${dateFormat}`, timeFormat)}`
        : '';
      timeLabelValue = `${startTimeLabel}${endTimeLabel}`;
    }
    return (
      <Label
        key={`full-time-label-${eventId}`}
        className={scss.eventLabel}
        value={timeLabelValue}
        fontSize="small"
        textColor={textColor}
      />
    );
  };

  const fullTimeLabel = getFullTimeLabel();

  const descriptionElement = (
    <Label
      fontSize="small"
      overflow="break"
      value={
        description ? (
          <Description accId={accId} description={description} />
        ) : (
          ''
        )
      }
    />
  );

  const titleElement = (
    <Label
      key={`event-title-${eventId}`}
      value={title}
      fontSize="small"
      fontWeight="semibold"
      className={cn(scss.eventLabel)}
      textColor={textColor}
    />
  );

  const eventContent = cr(
    [!eventId, fullTimeLabel],
    [allDay, <CutEventLabel value={titleElement} textColor={textColor} />],
    [
      isMonthGrid,
      <>
        {!isMultiDay ? (
          <EventDote eventId={eventId} actorBgColor={actorBgColor} />
        ) : null}
        <CutEventLabel
          value={
            <>
              <Label
                className={scss.eventLabel}
                value={DateUtils.toDate(startUnixtime, dateFormat, timeFormat)}
                fontSize="small"
                textColor={textColor}
                style={{ marginRight: '2px' }}
              />
              {titleElement}
            </>
          }
          textColor={textColor}
        />
      </>,
    ],
    [
      numberOfRows === 1,
      <CutEventLabel
        value={
          <>
            {titleElement} {fullTimeLabel} {descriptionElement}
          </>
        }
        textColor={textColor}
      />,
    ],
    [
      numberOfRows === 2,
      <Stack.V size={Stack.SIZE.xxsmall}>
        <CutEventLabel value={titleElement} textColor={textColor} />
        <CutEventLabel
          value={
            <>
              {fullTimeLabel} {descriptionElement}
            </>
          }
          textColor={textColor}
        />
      </Stack.V>,
    ],
    [
      numberOfRows === 3,
      <Stack.V size={Stack.SIZE.xxsmall}>
        <CutEventLabel value={titleElement} textColor={textColor} />
        <CutEventLabel value={fullTimeLabel} textColor={textColor} />
        <Label
          value={descriptionElement}
          fontSize="small"
          textColor={textColor}
          className={cn(scss.eventLabel)}
        />
      </Stack.V>,
    ]
  );

  const eventMenuContent = useCallback(
    ({ onClose }) => (
      <EventMenu
        {...{
          title,
          users,
          startUnixtime,
          endUnixtime,
          description,
          eventId,
          privs,
        }}
        onClose={onClose}
        onRemove={handleRemoveEvent}
        onOpenEvent={handleOpenEvent}
        onClone={handleCloneEvent}
        calendarViewType={type}
        accId={accId}
      />
    ),
    [
      title,
      users,
      startUnixtime,
      endUnixtime,
      description,
      eventId,
      handleRemoveEvent,
      handleOpenEvent,
      handleCloneEvent,
    ]
  );

  return (
    <Card
      className={cn(scss.eventWrapper, {
        [scss.eventWrapperMountGrid]: isMonthGrid,
        [scss.eventPopoverSelected]: isSelected,
        [scss.bluredEvent]: isBlured,
      })}
      fullHeight
      forwardRef={eventCardRef}
      fullWidth
      data-event-id={eventId}
      boxShadow={isSelected ? 'intense' : 'none'}
      style={{
        border: '2px solid white',
        borderColor: allDay ? actorBgColor : borderColor,
        backgroundColor,
      }}
    >
      <Popover
        key={`event-${eventId}-${type}-${isMultiDay}`}
        className={cn(scss.eventPopover, {
          [scss.popoverMoreMenu]: isMoreMenuOpen,
        })}
        topLevel={!isMoreMenuOpen}
        contentClassName={scss.eventPopoverContent}
        byClickPosition={!isMoreMenuOpen}
        toggleOnRepeatedClick
        onToggle={(opened) => onSelect(opened ? eventId : null)}
        anchors={{
          binding: Popover.ANCHOR.right_top,
          content: Popover.ANCHOR.left_top,
        }}
        content={disableMenu ? <div /> : eventMenuContent}
      >
        <Card
          className={cn(scss.eventCard, {
            [scss.eventSelected]: isSelected,
            [scss.eventMonthGrid]: isMonthGrid,
            [scss.eventMultiDay]: isMultiDay,
          })}
          fullWidth
          fullHeight
          withBorder={false}
          onClick={() => onClick?.(eventId)}
        >
          <Space size={Space.SIZE.xxsmall} left right key={`event-${eventId}`}>
            <Stack
              horizontal={isMonthGrid}
              size={Stack.SIZE.xxsmall}
              fullWidth
              fullHeight
              key={`event-${eventId}`}
            >
              {eventContent}
            </Stack>
          </Space>
        </Card>
      </Popover>
    </Card>
  );
}

Event.propTypes = {
  event: PropTypes.shape({
    extendedProps: PropTypes.shape({
      users: PropTypes.array,
      startUnixtime: PropTypes.number,
      endUnixtime: PropTypes.number,
      description: PropTypes.string,
      eventId: PropTypes.string,
      isMultiDay: PropTypes.bool,
      actorBgColor: PropTypes.string,
      privs: PropTypes.object,
    }).isRequired,
    title: PropTypes.string,
  }),
  view: PropTypes.object,
  handleRemoveEvent: PropTypes.func,
  handleOpenEvent: PropTypes.func,
  handleCloneEvent: PropTypes.func,
  isMoreMenuOpen: PropTypes.bool,
  accId: PropTypes.string,
  isSelected: PropTypes.bool,
  isBlured: PropTypes.bool,
  onSelect: PropTypes.func,
  timeFormat: PropTypes.oneOf(Object.keys(DateUtils.TIME_FORMATS)),
};

export default Event;
