function SVGIconStream(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.translate(-24, -175);
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.translate(12, 169);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.beginPath();
  ctx.moveTo(27, 12);
  ctx.lineTo(27, 14);
  ctx.lineTo(13, 14);
  ctx.lineTo(13, 12);
  ctx.lineTo(27, 12);
  ctx.closePath();
  ctx.moveTo(27, 9);
  ctx.lineTo(27, 11);
  ctx.lineTo(13, 11);
  ctx.lineTo(13, 9);
  ctx.lineTo(27, 9);
  ctx.closePath();
  ctx.moveTo(27, 6);
  ctx.lineTo(27, 8);
  ctx.lineTo(13, 8);
  ctx.lineTo(13, 6);
  ctx.lineTo(27, 6);
  ctx.closePath();
  ctx.moveTo(22, 15);
  ctx.lineTo(22, 17);
  ctx.lineTo(18, 17);
  ctx.lineTo(18, 15);
  ctx.lineTo(22, 15);
  ctx.closePath();
  ctx.moveTo(24, 15);
  ctx.lineTo(27, 15);
  ctx.bezierCurveTo(27.552, 15, 28, 15.448, 28, 16);
  ctx.lineTo(28, 20);
  ctx.bezierCurveTo(28, 21.103, 27.103, 22, 26, 22);
  ctx.lineTo(14, 22);
  ctx.bezierCurveTo(12.897, 22, 12, 21.103, 12, 20);
  ctx.lineTo(12, 16);
  ctx.bezierCurveTo(12, 15.448, 12.448, 15, 13, 15);
  ctx.lineTo(16, 15);
  ctx.bezierCurveTo(16.552, 15, 17, 15.448, 17, 16);
  ctx.lineTo(17, 18);
  ctx.lineTo(23, 18);
  ctx.lineTo(23, 16);
  ctx.bezierCurveTo(23, 15.448, 23.448, 15, 24, 15);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconStream;
