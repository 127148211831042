import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Space,
  Stack,
  Label,
  Radio,
  RadioItem,
  TextField,
} from 'mw-style-react';
import { useModal, useIntl } from 'hooks';
import m from './intl';

const IMPORT_STRATEGY = {
  replace: 'replace',
  reuse: 'reuse',
  error: 'error',
};

function ImportStrategySelectorModal({ onClose, data: { onChange, ...rest } }) {
  const t = useIntl();
  const [strategy, setStrategy] = useState(IMPORT_STRATEGY.replace);
  const [refReplacePrefix, setRefReplacePrefix] = useState(
    t(m.replacePlaceholder)
  );

  const { update: switchToImportWarningModal } = useModal(
    'ImportWarningModal',
    {
      onChange: (newImportSettings) => {
        onChange({ ...newImportSettings, strategy, refReplacePrefix });
      },
      ...rest,
    }
  );

  return (
    <Modal onClose={onClose} label={t(m.startImport)}>
      <Space size={Space.SIZE.large}>
        <Stack size={Stack.SIZE.large}>
          <Stack>
            <Label value={t(m.title)} />
            <Radio
              value={strategy}
              onChange={({ value }) => setStrategy(value)}
            >
              <RadioItem value={IMPORT_STRATEGY.replace} label={t(m.replace)}>
                <Stack.V size={Stack.SIZE.small}>
                  <Label color={Label.black} value={t(m.replaceDescription)} />
                  <TextField
                    color={TextField.COLOR.white}
                    unspaced
                    bordered
                    placeholder={t(m.replacePlaceholder)}
                    label={
                      <TextField.Label
                        color={Label.gray}
                        value={t(m.replaceInputLabel)}
                      />
                    }
                    value={refReplacePrefix}
                    onChange={({ value }) => setRefReplacePrefix(value)}
                  />
                </Stack.V>
              </RadioItem>
              <RadioItem value={IMPORT_STRATEGY.reuse} label={t(m.reuse)}>
                <Label color={Label.black} value={t(m.reuseDescription)} />
              </RadioItem>
              <RadioItem value={IMPORT_STRATEGY.error} label={t(m.error)}>
                <Label color={Label.black} value={t(m.errorDescription)} />
              </RadioItem>
            </Radio>
          </Stack>
          <Stack>
            <Button
              size="large"
              fontWeight="normal"
              label={t(m.next)}
              fullWidth
              onClick={() => {
                switchToImportWarningModal();
                onChange({ strategy, refReplacePrefix });
              }}
              disabled={
                strategy === IMPORT_STRATEGY.replace && !refReplacePrefix
              }
            />
          </Stack>
        </Stack>
      </Space>
    </Modal>
  );
}

ImportStrategySelectorModal.propTypes = {
  data: PropTypes.shape({ onChange: PropTypes.func.isRequired }),
  onClose: PropTypes.func.isRequired,
};

export default ImportStrategySelectorModal;
