import {
  ADD_WIDGET,
  REMOVE_WIDGET,
  CLEAR_WIDGETS,
  UPDATE_WIDGET,
  CLEAR_WIDGETS_BY_NAME,
  CLEAR_WIDGETS_BY_ACTORID,
} from 'constants';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

/**
 * The widgetsReducer manages the state of widgets within the application.
 * It handles an array of widget objects, each representing a UI component
 * that is both resizable and draggable.
 *
 * Actions:
 * - ADD_WIDGET: Adds a new widget to the state array. The widget's data is
 *   provided in the action's payload.
 * - REMOVE_WIDGET: Removes a widget from the state array based on the provided
 *   widget ID in the action's payload.
 * - CLEAR_WIDGETS: Clears all widgets from the state, resetting it to an empty array.
 *
 * This reducer allows for dynamic manipulation of the widgets array, enabling
 * adding, removing, and clearing of widgets in the application's UI. It is crucial
 * for managing the interactive aspects of the widget components, ensuring a
 * flexible and customizable user experience.
 */

const MAX_WIDGETS = 10;

const initialState = [];

export default createReducer(initialState, {
  [ADD_WIDGET](state, action) {
    const newWidget = {
      ...action.payload,
      timestamp: Date.now(),
    };

    if (state.length >= MAX_WIDGETS) {
      const widgetWithMinTimestampIndex = state.reduce(
        (minIndex, widget, index, widgets) =>
          widget.timestamp < widgets[minIndex].timestamp ? index : minIndex,
        0
      );

      return [
        ...state.slice(0, widgetWithMinTimestampIndex),
        ...state.slice(widgetWithMinTimestampIndex + 1),
        newWidget,
      ];
    }

    return [...state, newWidget];
  },
  [REMOVE_WIDGET](state, { payload }) {
    return state.filter((item) => item?.id !== payload?.id);
  },
  [CLEAR_WIDGETS]() {
    return [];
  },
  [UPDATE_WIDGET](state, action) {
    return state.map((widget) =>
      widget?.id === action.payload?.id
        ? { ...widget, ...action.payload?.updates }
        : widget
    );
  },
  [CLEAR_WIDGETS_BY_ACTORID](state, action) {
    return state.filter((widget) => !widget?.id.includes(action.payload.id));
  },
  [CLEAR_WIDGETS_BY_NAME](state, action) {
    return state.filter((widget) => widget?.name !== action.payload?.name);
  },
});
