function SVGIconTransfer(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0, 3);
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(15.5, 3.5);
  ctx.lineTo(12.175, 0.167);
  ctx.lineTo(12.175, 2.667);
  ctx.lineTo(6.333, 2.667);
  ctx.lineTo(6.333, 4.333);
  ctx.lineTo(12.175, 4.333);
  ctx.lineTo(12.175, 6.833);
  ctx.lineTo(15.5, 3.5);
  ctx.closePath();
  ctx.moveTo(3.825, 9.333);
  ctx.lineTo(9.667, 9.333);
  ctx.lineTo(9.667, 7.667);
  ctx.lineTo(3.825, 7.667);
  ctx.lineTo(3.825, 5.167);
  ctx.lineTo(0.5, 8.5);
  ctx.lineTo(3.825, 11.833);
  ctx.lineTo(3.825, 9.333);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconTransfer;
