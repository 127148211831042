import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'hooks';
import { useSelector } from 'react-redux';
import { SIP_ACTOR_ID, WIDGET_TYPE } from 'constants';
import mes from 'globalIntl';
import { Space, Tooltip, Menu, MenuItem, Label, Popover } from 'mw-style-react';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import cn from 'classnames';
import './UsersAvatars.scss';

function UsersAvatars(props) {
  const t = useIntl();
  const {
    users = [],
    size = 'medium',
    limit = 5,
    offset = 20,
    showList = false,
  } = props;
  const config = useSelector((state) => state.config);
  const auth = useSelector((state) => state.auth);
  const collapsedUsersCount = users.length - limit;
  const usersToShow = users.slice(0, limit);
  const avatarSize = size === 'medium' ? 32 : 28;
  let width =
    usersToShow.length * avatarSize +
    (usersToShow.length - 1) * (avatarSize / 2 - offset);
  if (collapsedUsersCount > 0) width += 16;

  const handleStartCall = (user) => {
    if (!config.sip) return;
    const avatarSize = '/52x52';
    const { id, nick, name, avatar } = user;
    if (window.ctrl) {
      window.ctrl(WIDGET_TYPE.webSIP, 'call', {
        actorId: SIP_ACTOR_ID,
        id,
        nick: nick || name,
        userAvatar: avatar.replace(avatarSize, ''),
      });
    }
  };

  const renderUsers = () =>
    usersToShow.map((user, index) => {
      const { userId, id, name, nick, avatar, color, icon, userType, type } =
        user;
      return (
        <div
          key={`${id || userId}_${index}`}
          styleName="users__avabox"
          style={{ left: -(index * offset) }}
        >
          <Tooltip topLevel value={name || nick}>
            <UserAvatar
              styleName={cn('users__avatar', size)}
              label={name || nick}
              size={size}
              src={avatar}
              bgColor={color}
              customIcon={icon ? { type: icon } : null}
              userType={userType || type}
            />
          </Tooltip>
        </div>
      );
    });

  const renderUsersList = () => (
    <Menu>
      <Space size={Space.SIZE.large} left right>
        <Space size={Space.SIZE.xsmall} bottom>
          <Label value={t(mes.users)} fontWeight="semibold" />
        </Space>
      </Space>
      {users.map(({ id, name, nick, avatar }) => (
        <MenuItem
          key={id}
          size="large"
          label={name || nick}
          avatar={avatar}
          visibility={config.sip && auth.id !== id ? 'visible' : 'disabled'}
          rightIcon={config.sip ? 'call' : null}
          onClick={() => handleStartCall({ id, name, nick, avatar })}
        />
      ))}
    </Menu>
  );

  const renderCounter = () => {
    const boxOffset = usersToShow.length;
    const label =
      collapsedUsersCount > 99 ? '+99' : `+${collapsedUsersCount.toString()}`;
    return (
      <div
        key="counter"
        styleName="users__avabox"
        style={{ left: -(boxOffset * offset) }}
      >
        <div key="counter" styleName={cn('users__counter', size)}>
          {label}
        </div>
      </div>
    );
  };

  return (
    <Popover topLevel padding={4} content={showList ? renderUsersList() : null}>
      <div styleName={cn('users', { pointer: !!showList })} style={{ width }}>
        {renderUsers()}
        {collapsedUsersCount > 0 ? renderCounter() : null}
      </div>
    </Popover>
  );
}

UsersAvatars.propTypes = {
  users: PropTypes.array.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  offset: PropTypes.number,
  limit: PropTypes.number,
  showList: PropTypes.bool,
};

export default UsersAvatars;
