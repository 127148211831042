import { put, select, takeLatest } from 'redux-saga/effects';
import { Utils } from 'mw-style-react';
import { CUSTOM_BAR_SCRIPT_PREFIX, CLEAR_WIDGETS_BY_ACTORID } from 'constants';
import {
  WS_EXTERNAL_PACKET,
  EXTERNAL_PACKET_ACTION,
  REACTION_STORAGE_KEY,
} from '@control-front-end/common/constants/reactions';
import {
  SET_ACTIVE_ACTOR,
  SET_ACTOR_TYPING,
} from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import history from '@control-front-end/app/src/store/history';

const PAGE_REGEX = {
  actorView: /\/actors_graph\/([^/]+)\/view\/([^/]+)/,
  events: /\/events\/([^/]+)\/list/,
};

function* eventsSetActive(payload, url) {
  const { accId, data } = payload;
  if (url.match(PAGE_REGEX.actorView)) {
    history.replace(
      AppUtils.makeUrl(
        `/actors_graph/${accId}/view/${data.actorId}${history.location.search}`
      )
    );
  } else if (url.match(PAGE_REGEX.events)) {
    yield put({ type: SET_ACTIVE_ACTOR, payload: { id: data.actorId } });
  }
}

function* eventsTyping(payload) {
  const { data, user } = payload;
  const { actorId, action } = data;
  const auth = yield select((state) => state.auth);
  const actorView = yield select((state) => state.actorView);
  if (auth.id === user.id || actorView.id !== actorId) return;
  const newTyping = actorView.typing.filter((i) => i.id !== user.id);
  if (action) newTyping.push(user);
  yield put({ type: SET_ACTOR_TYPING.SUCCESS, payload: newTyping });
}

function reactionsPlaceholder(payload, url) {
  const { data } = payload;
  // Trigger event to set placeholder message to the active reaction form
  if (Object.values(PAGE_REGEX).some((regex) => url.match(regex))) {
    document.dispatchEvent(
      new CustomEvent(
        `${WS_EXTERNAL_PACKET}:${EXTERNAL_PACKET_ACTION['reactions.placeholder']}`,
        { detail: data }
      )
    );
  }
  // Set placeholder message to session storage
  Utils.toSessionStorage(`${REACTION_STORAGE_KEY}${data.actorId}`, data.text);
}

function* scriptsToggle(payload) {
  const { data } = payload;
  const { actorId, action } = data;
  const ACTIONS = { show: 'show', hide: 'hide' };
  if (action === ACTIONS.show) {
    const btn = document.getElementById(
      `${CUSTOM_BAR_SCRIPT_PREFIX}${actorId}`
    );
    if (btn) btn.click();
  } else {
    yield put({ type: CLEAR_WIDGETS_BY_ACTORID, payload: { id: actorId } });
  }
}

function* handleExternalPacket({ payload = {} }) {
  const { accId, name } = payload;
  const url = history.location.pathname;
  const activeAccId = yield select((state) => state.accounts.activeAccount.id);
  if (accId !== activeAccId || !EXTERNAL_PACKET_ACTION[name]) return;

  switch (name) {
    case EXTERNAL_PACKET_ACTION['events.setActive']:
      yield eventsSetActive(payload, url);
      break;
    case EXTERNAL_PACKET_ACTION['events.typing']:
      yield eventsTyping(payload);
      break;
    case EXTERNAL_PACKET_ACTION['reactions.placeholder']:
      yield reactionsPlaceholder(payload, url);
      break;
    case EXTERNAL_PACKET_ACTION['scripts.toggle']:
      yield scriptsToggle(payload);
      break;
    default:
  }
}

function* wsExternalPacket() {
  yield takeLatest(WS_EXTERNAL_PACKET, handleExternalPacket);
}

export default wsExternalPacket;
