function SVGIconForms(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(0.75, 0.75);
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = '#ACB3BE';
  ctx.translate(-479, -1240);
  ctx.save();
  ctx.fillStyle = '#ACB3BE';
  ctx.translate(240, 1126);
  ctx.save();
  ctx.fillStyle = '#ACB3BE';
  ctx.translate(239, 114);
  ctx.save();
  ctx.fillStyle = '#ACB3BE';
  ctx.beginPath();
  ctx.moveTo(13.6, 0);
  ctx.bezierCurveTo(14.9255, 0, 16, 1.0745, 16, 2.4);
  ctx.lineTo(16, 15.200000000000001);
  ctx.bezierCurveTo(
    15.9983879,
    15.522960000000001,
    15.80274,
    15.81328,
    15.504,
    15.936000000000002
  );
  ctx.bezierCurveTo(
    15.409056,
    15.980785000000001,
    15.30494,
    16.002704,
    15.2,
    16
  );
  ctx.bezierCurveTo(
    14.987359999999999,
    16.0012294,
    14.782979999999998,
    15.917749,
    14.632,
    15.768
  );
  ctx.lineTo(11.672, 12.8);
  ctx.lineTo(2.4000000000000004, 12.8);
  ctx.bezierCurveTo(
    1.0745000000000005,
    12.8,
    4.440892098500626e-16,
    11.7255,
    4.440892098500626e-16,
    10.4
  );
  ctx.lineTo(4.440892098500626e-16, 2.4000000000000004);
  ctx.bezierCurveTo(
    4.440892098500626e-16,
    1.0745000000000005,
    1.0745000000000005,
    4.440892098500626e-16,
    2.4000000000000004,
    4.440892098500626e-16
  );
  ctx.closePath();
  ctx.moveTo(4, 7.2);
  ctx.bezierCurveTo(3.55817, 7.2, 3.2, 7.5581700000000005, 3.2, 8);
  ctx.bezierCurveTo(3.2, 8.44183, 3.55817, 8.8, 4, 8.8);
  ctx.bezierCurveTo(4.4418299999999995, 8.8, 4.8, 8.441830000000001, 4.8, 8);
  ctx.bezierCurveTo(4.8, 7.558169999999999, 4.4418299999999995, 7.2, 4, 7.2);
  ctx.closePath();
  ctx.moveTo(12, 7.2);
  ctx.lineTo(7.2, 7.2);
  ctx.bezierCurveTo(6.75817, 7.2, 6.4, 7.5581700000000005, 6.4, 8);
  ctx.bezierCurveTo(6.4, 8.44183, 6.758170000000001, 8.8, 7.2, 8.8);
  ctx.lineTo(12, 8.8);
  ctx.bezierCurveTo(12.44183, 8.8, 12.8, 8.441830000000001, 12.8, 8);
  ctx.bezierCurveTo(12.8, 7.558169999999999, 12.441830000000001, 7.2, 12, 7.2);
  ctx.closePath();
  ctx.moveTo(4, 4);
  ctx.bezierCurveTo(3.55817, 4, 3.2, 4.35817, 3.2, 4.8);
  ctx.bezierCurveTo(3.2, 5.241829999999999, 3.55817, 5.6, 4, 5.6);
  ctx.bezierCurveTo(4.4418299999999995, 5.6, 4.8, 5.241829999999999, 4.8, 4.8);
  ctx.bezierCurveTo(4.8, 4.35817, 4.4418299999999995, 4, 4, 4);
  ctx.closePath();
  ctx.moveTo(12, 4);
  ctx.lineTo(7.2, 4);
  ctx.bezierCurveTo(6.75817, 4, 6.4, 4.35817, 6.4, 4.8);
  ctx.bezierCurveTo(6.4, 5.241829999999999, 6.758170000000001, 5.6, 7.2, 5.6);
  ctx.lineTo(12, 5.6);
  ctx.bezierCurveTo(12.44183, 5.6, 12.8, 5.241829999999999, 12.8, 4.8);
  ctx.bezierCurveTo(12.8, 4.35817, 12.441830000000001, 4, 12, 4);
  ctx.closePath();
  ctx.fill('nonzero');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconForms;
