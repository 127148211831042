function SVGIconUp(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.save();
  ctx.fillStyle = '#B8DFCB';
  ctx.globalAlpha = 0.3;
  ctx.beginPath();
  ctx.moveTo(6, 0);
  ctx.lineTo(6, 0);
  ctx.bezierCurveTo(9.313708498984761, 0, 12, 2.686291501015239, 12, 6);
  ctx.lineTo(12, 6);
  ctx.bezierCurveTo(12, 9.313708498984761, 9.313708498984761, 12, 6, 12);
  ctx.lineTo(6, 12);
  ctx.bezierCurveTo(2.686291501015239, 12, 0, 9.313708498984761, 0, 6);
  ctx.lineTo(0, 6);
  ctx.bezierCurveTo(0, 2.686291501015239, 2.686291501015239, 0, 6, 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = '#129D59';
  ctx.beginPath();
  ctx.moveTo(8.85222, 5.26581);
  ctx.lineTo(6.35429, 3.12219);
  ctx.bezierCurveTo(6.30677, 3.08316, 6.25075, 3.05256, 6.18942, 3.03216);
  ctx.bezierCurveTo(6.06779, 2.98928, 5.93137, 2.98928, 5.80974, 3.03216);
  ctx.bezierCurveTo(5.74841, 3.05256, 5.69239, 3.08316, 5.64487, 3.12219);
  ctx.lineTo(3.14694, 5.26581);
  ctx.bezierCurveTo(3.02021, 5.37456, 2.97072, 5.53307, 3.0171, 5.68162);
  ctx.bezierCurveTo(3.06349, 5.83018, 3.1987, 5.94621, 3.37181, 5.98602);
  ctx.bezierCurveTo(3.54492, 6.02582, 3.72963, 5.98335, 3.85635, 5.8746);
  ctx.lineTo(5.49999, 4.45981);
  ctx.lineTo(5.49999, 8.57128);
  ctx.bezierCurveTo(5.49999, 8.80805, 5.72366, 9, 5.99958, 9);
  ctx.bezierCurveTo(6.27549, 9, 6.49917, 8.80805, 6.49917, 8.57128);
  ctx.lineTo(6.49917, 4.45981);
  ctx.lineTo(8.14281, 5.8746);
  ctx.bezierCurveTo(8.23661, 5.95576, 8.36431, 6.00142, 8.49752, 6.00142);
  ctx.bezierCurveTo(8.63072, 6.00142, 8.75842, 5.95576, 8.85222, 5.8746);
  ctx.bezierCurveTo(8.9468, 5.7941, 9, 5.68452, 9, 5.57021);
  ctx.bezierCurveTo(9, 5.45589, 8.9468, 5.34631, 8.85222, 5.26581);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconUp;
