function SVGIconGraph(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.translate(-205, -677);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(212.3312, 683.3272);
  ctx.bezierCurveTo(212.5472, 683.3736, 212.7704, 683.4, 213, 683.4);
  ctx.bezierCurveTo(213.2304, 683.4, 213.4536, 683.3736, 213.6696, 683.3272);
  ctx.lineTo(215.7056, 687.4008);
  ctx.bezierCurveTo(215.2288, 687.8176, 214.8784, 688.3688, 214.7144, 689);
  ctx.lineTo(211.2864, 689);
  ctx.bezierCurveTo(211.1224, 688.3688, 210.772, 687.8176, 210.2952, 687.4008);
  ctx.lineTo(212.3312, 683.3272);
  ctx.closePath();
  ctx.moveTo(217.8, 686.6);
  ctx.bezierCurveTo(217.5704, 686.6, 217.3472, 686.6264, 217.1312, 686.6736);
  ctx.lineTo(215.0952, 682.6);
  ctx.bezierCurveTo(215.7664, 682.0128, 216.2, 681.1608, 216.2, 680.2);
  ctx.bezierCurveTo(216.2, 678.436, 214.7648, 677, 213, 677);
  ctx.bezierCurveTo(211.236, 677, 209.8, 678.436, 209.8, 680.2);
  ctx.bezierCurveTo(209.8, 681.1608, 210.2344, 682.0128, 210.9056, 682.6);
  ctx.lineTo(208.8696, 686.6736);
  ctx.bezierCurveTo(208.6536, 686.6264, 208.4304, 686.6, 208.2, 686.6);
  ctx.bezierCurveTo(206.436, 686.6, 205, 688.036, 205, 689.8);
  ctx.bezierCurveTo(205, 691.5648, 206.436, 693, 208.2, 693);
  ctx.bezierCurveTo(209.6872, 693, 210.9288, 691.976, 211.2864, 690.6);
  ctx.lineTo(214.7144, 690.6);
  ctx.bezierCurveTo(215.072, 691.976, 216.3136, 693, 217.8, 693);
  ctx.bezierCurveTo(219.5648, 693, 221, 691.5648, 221, 689.8);
  ctx.bezierCurveTo(221, 688.036, 219.5648, 686.6, 217.8, 686.6);
  ctx.lineTo(217.8, 686.6);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconGraph;
