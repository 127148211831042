function SVGIconState(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(7.27273, 1.45455);
  ctx.lineTo(7.27273, 5.81818);
  ctx.bezierCurveTo(7.27273, 6.20395, 7.11948, 6.57392, 6.8467, 6.8467);
  ctx.bezierCurveTo(6.57392, 7.11948, 6.20395, 7.27273, 5.81818, 7.27273);
  ctx.lineTo(1.45455, 7.27273);
  ctx.bezierCurveTo(1.06878, 7.27273, 0.698807, 7.11948, 0.426026, 6.8467);
  ctx.bezierCurveTo(0.153246, 6.57392, 0, 6.20395, 0, 5.81818);
  ctx.lineTo(0, 1.45455);
  ctx.bezierCurveTo(0, 1.06878, 0.153246, 0.698807, 0.426026, 0.426026);
  ctx.bezierCurveTo(0.698807, 0.153246, 1.06878, 0, 1.45455, 0);
  ctx.lineTo(5.81818, 0);
  ctx.bezierCurveTo(6.20395, 0, 6.57392, 0.153246, 6.8467, 0.426026);
  ctx.bezierCurveTo(7.11948, 0.698807, 7.27273, 1.06878, 7.27273, 1.45455);
  ctx.closePath();
  ctx.moveTo(14.5455, 8.72727);
  ctx.lineTo(10.1818, 8.72727);
  ctx.bezierCurveTo(9.79605, 8.72727, 9.42608, 8.88052, 9.1533, 9.1533);
  ctx.bezierCurveTo(8.88052, 9.42608, 8.72727, 9.79605, 8.72727, 10.1818);
  ctx.lineTo(8.72727, 14.5455);
  ctx.bezierCurveTo(8.72727, 14.9312, 8.88052, 15.3012, 9.1533, 15.574);
  ctx.bezierCurveTo(9.42608, 15.8468, 9.79605, 16, 10.1818, 16);
  ctx.lineTo(14.5455, 16);
  ctx.bezierCurveTo(14.9312, 16, 15.3012, 15.8468, 15.574, 15.574);
  ctx.bezierCurveTo(15.8468, 15.3012, 16, 14.9312, 16, 14.5455);
  ctx.lineTo(16, 10.1818);
  ctx.bezierCurveTo(16, 9.79605, 15.8468, 9.42608, 15.574, 9.1533);
  ctx.bezierCurveTo(15.3012, 8.88052, 14.9312, 8.72727, 14.5455, 8.72727);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(6.77273, 1.45455);
  ctx.lineTo(6.77273, 5.81818);
  ctx.bezierCurveTo(6.77273, 6.07134, 6.67216, 6.31413, 6.49315, 6.49315);
  ctx.bezierCurveTo(6.31413, 6.67216, 6.07134, 6.77273, 5.81818, 6.77273);
  ctx.lineTo(1.45455, 6.77273);
  ctx.bezierCurveTo(1.20138, 6.77273, 0.958592, 6.67216, 0.77958, 6.49315);
  ctx.bezierCurveTo(0.600568, 6.31414, 0.5, 6.07134, 0.5, 5.81818);
  ctx.lineTo(0.5, 1.45455);
  ctx.bezierCurveTo(0.5, 1.20138, 0.600568, 0.958592, 0.77958, 0.77958);
  ctx.bezierCurveTo(0.958592, 0.600568, 1.20138, 0.5, 1.45455, 0.5);
  ctx.lineTo(5.81818, 0.5);
  ctx.bezierCurveTo(6.07134, 0.5, 6.31414, 0.600568, 6.49315, 0.77958);
  ctx.bezierCurveTo(6.67216, 0.958592, 6.77273, 1.20138, 6.77273, 1.45455);
  ctx.closePath();
  ctx.moveTo(10.1818, 9.22727);
  ctx.lineTo(14.5455, 9.22727);
  ctx.bezierCurveTo(14.7986, 9.22727, 15.0414, 9.32784, 15.2204, 9.50685);
  ctx.bezierCurveTo(15.3994, 9.68587, 15.5, 9.92866, 15.5, 10.1818);
  ctx.lineTo(15.5, 14.5455);
  ctx.bezierCurveTo(15.5, 14.7986, 15.3994, 15.0414, 15.2204, 15.2204);
  ctx.bezierCurveTo(15.0414, 15.3994, 14.7986, 15.5, 14.5455, 15.5);
  ctx.lineTo(10.1818, 15.5);
  ctx.bezierCurveTo(9.92866, 15.5, 9.68587, 15.3994, 9.50685, 15.2204);
  ctx.bezierCurveTo(9.32784, 15.0414, 9.22727, 14.7986, 9.22727, 14.5455);
  ctx.lineTo(9.22727, 10.1818);
  ctx.bezierCurveTo(9.22727, 9.92866, 9.32784, 9.68587, 9.50685, 9.50685);
  ctx.bezierCurveTo(9.68587, 9.32784, 9.92866, 9.22727, 10.1818, 9.22727);
  ctx.closePath();
  ctx.moveTo(1.45455, 9.22727);
  ctx.lineTo(5.81818, 9.22727);
  ctx.bezierCurveTo(6.07134, 9.22727, 6.31414, 9.32784, 6.49315, 9.50685);
  ctx.bezierCurveTo(6.67216, 9.68587, 6.77273, 9.92866, 6.77273, 10.1818);
  ctx.lineTo(6.77273, 14.5455);
  ctx.bezierCurveTo(6.77273, 14.7986, 6.67216, 15.0414, 6.49315, 15.2204);
  ctx.bezierCurveTo(6.31414, 15.3994, 6.07134, 15.5, 5.81818, 15.5);
  ctx.lineTo(1.45455, 15.5);
  ctx.bezierCurveTo(1.20138, 15.5, 0.958592, 15.3994, 0.77958, 15.2204);
  ctx.bezierCurveTo(0.600568, 15.0414, 0.5, 14.7986, 0.5, 14.5455);
  ctx.lineTo(0.5, 10.1818);
  ctx.bezierCurveTo(0.5, 9.92866, 0.600568, 9.68587, 0.77958, 9.50685);
  ctx.bezierCurveTo(0.958592, 9.32784, 1.20138, 9.22727, 1.45455, 9.22727);
  ctx.closePath();
  ctx.moveTo(10.1818, 0.5);
  ctx.lineTo(14.5455, 0.5);
  ctx.bezierCurveTo(14.7986, 0.5, 15.0414, 0.600568, 15.2204, 0.77958);
  ctx.bezierCurveTo(15.3994, 0.958592, 15.5, 1.20138, 15.5, 1.45455);
  ctx.lineTo(15.5, 5.81818);
  ctx.bezierCurveTo(15.5, 6.07134, 15.3994, 6.31414, 15.2204, 6.49315);
  ctx.bezierCurveTo(15.0414, 6.67216, 14.7986, 6.77273, 14.5455, 6.77273);
  ctx.lineTo(10.1818, 6.77273);
  ctx.bezierCurveTo(9.92866, 6.77273, 9.68587, 6.67216, 9.50685, 6.49315);
  ctx.bezierCurveTo(9.32784, 6.31414, 9.22727, 6.07134, 9.22727, 5.81818);
  ctx.lineTo(9.22727, 1.45455);
  ctx.bezierCurveTo(9.22727, 1.20138, 9.32784, 0.958592, 9.50685, 0.77958);
  ctx.bezierCurveTo(9.68587, 0.600568, 9.92866, 0.5, 10.1818, 0.5);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconState;
