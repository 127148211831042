function SVGIconPinned(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(10, 0);
  ctx.lineTo(10, 0);
  ctx.bezierCurveTo(15.522847498307936, 0, 20, 4.477152501692064, 20, 10);
  ctx.lineTo(20, 10);
  ctx.bezierCurveTo(20, 15.522847498307936, 15.522847498307936, 20, 10, 20);
  ctx.lineTo(10, 20);
  ctx.bezierCurveTo(4.477152501692064, 20, 0, 15.522847498307936, 0, 10);
  ctx.lineTo(0, 10);
  ctx.bezierCurveTo(0, 4.477152501692064, 4.477152501692064, 0, 10, 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(11.7985, 4.80669);
  ctx.bezierCurveTo(11.611, 4.61913, 11.3069, 4.61913, 11.1194, 4.80669);
  ctx.bezierCurveTo(10.9319, 4.99425, 10.9319, 5.29834, 11.1194, 5.4859);
  ctx.lineTo(11.459, 5.82551);
  ctx.lineTo(8.19928, 7.99901);
  ctx.lineTo(8.06346, 7.86317);
  ctx.bezierCurveTo(7.6884, 7.48804, 7.08032, 7.48804, 6.70526, 7.86317);
  ctx.bezierCurveTo(6.33021, 8.23829, 6.33021, 8.84648, 6.70526, 9.2216);
  ctx.lineTo(8.06346, 10.58);
  ctx.lineTo(4.66797, 15.3346);
  ctx.lineTo(9.42166, 11.9385);
  ctx.lineTo(10.7799, 13.2969);
  ctx.bezierCurveTo(11.1549, 13.672, 11.763, 13.672, 12.138, 13.2969);
  ctx.bezierCurveTo(12.5131, 12.9218, 12.5131, 12.3136, 12.138, 11.9385);
  ctx.lineTo(12.0022, 11.8026);
  ctx.lineTo(14.1753, 8.54238);
  ctx.lineTo(14.5149, 8.88199);
  ctx.bezierCurveTo(14.7024, 9.06955, 15.0065, 9.06955, 15.194, 8.88199);
  ctx.bezierCurveTo(15.3815, 8.69443, 15.3815, 8.39033, 15.194, 8.20277);
  ctx.lineTo(11.7985, 4.80669);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconPinned;
