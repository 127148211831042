function SVGIconLayersCount(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.375, 0);
  ctx.scale(0.75, 0.75);
  ctx.save();
  ctx.fillStyle = '#ACB3BE';
  ctx.beginPath();
  ctx.moveTo(7.5, 11.7);
  ctx.lineTo(13.6, 7);
  ctx.lineTo(15, 6);
  ctx.lineTo(7.5, 0);
  ctx.lineTo(0, 5.9);
  ctx.lineTo(1.4, 6.9);
  ctx.lineTo(7.5, 11.7);
  ctx.closePath();
  ctx.moveTo(7.5, 13.899999999999999);
  ctx.lineTo(1.3, 9);
  ctx.lineTo(0, 10);
  ctx.lineTo(7.5, 16);
  ctx.lineTo(15, 10.1);
  ctx.lineTo(13.6, 9.1);
  ctx.lineTo(7.5, 13.899999999999999);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconLayersCount;
