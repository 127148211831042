import { createSelector } from '@reduxjs/toolkit';
import { ACCEPT_FILES } from 'constants';
export * from './graphs';

const getActiveAcc = (state) => state.accounts.activeAccount;
const getReadOnly = (state) => state.actorView.readonly;
const getPrivs = (state) => state.actorView.privs;

export const getActiveAccount = createSelector(
  [getActiveAcc],
  (activeAccount) => {
    return activeAccount;
  }
);

export const getCanEditActor = createSelector(
  [getReadOnly, getPrivs],
  (readOnly, privs) => !readOnly && privs && privs.modify
);

const getActor = (state, props) => {
  const cache = state.dataCache.actor;
  return cache[props.id] || cache[props.id.split('_')[1]];
};

export const getIsActorFilter = createSelector(
  [(state) => state.actorView, (state) => state.systemForms],
  (actorView, systemForms) => {
    const { actorfilters, transactionfilters, transferfilters } = systemForms;
    const filterFormIds = [
      actorfilters.id,
      transactionfilters.id,
      transferfilters.id,
    ];
    return filterFormIds.includes(actorView.formId);
  }
);

export const getCachedActor = createSelector([getActor], (actor) => actor);

export const getCachedUser = createSelector(
  [(state, props) => state.dataCache.user[props.id]],
  (user) => user
);

export const checkUserPermissions = createSelector(
  [(state) => state.auth.workspaces, (state) => state.accounts.active],
  (userWs, activeWs) => {
    const permissions = userWs[activeWs].permissions;
    return (perms = []) => {
      if (!perms.length) return true;
      for (const p of perms) {
        if (permissions[p]) return true;
      }
      return false;
    };
  }
);

/**
 * Получить доступные mimeTypes для крепления файлов в акторы
 */
export const getFileMimeTypes = createSelector(
  (state) => state.config,
  (config) => {
    const { fileMimeTypes } = config;
    if (fileMimeTypes === 'none') return null;
    if (!fileMimeTypes?.length) return ACCEPT_FILES;
    return fileMimeTypes;
  }
);
