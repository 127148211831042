import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Badge,
  Icon,
  Label,
  Menu,
  MenuItem,
  Tooltip,
  Utils,
} from 'mw-style-react';
import { DEL_MODAL, SET_MODAL } from 'constants';
import { PARENT_SYSTEM_FORMS } from '@control-front-end/common/constants/forms';
import Toggle from '@control-front-end/common/components/Toggle';
import SelectActorsTemplate from '@control-front-end/common/components/SelectActorsTemplate';
import useIntl from 'useIntl';
import cn from 'classnames';
import FormUtils from '@control-front-end/utils/formUtils';
import useActorFormActions from '../../hooks/useActorFormsActions';
import './FormSectionsPanel.scss';
import mes from './intl';

/**
 * Панель со списком секций формы
 */
function FormSectionsPanel({
  containerEl,
  formRefs,
  rootForm,
  forms,
  activeSection,
  canManageForms,
  handleSelectSection,
  handleFormsChange,
  handleSelectNewForm,
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [isOpenPanel, togglePanel] = useState(true);
  const [formSearch, toggleFormSearch] = useState(false);
  const [formMenu, toggleFormMenu] = useState(null);
  const {
    checkIsInheritForm,
    handleAddForms,
    handleUpdateForm,
    handleRemoveForms,
  } = useActorFormActions({ forms, handleFormsChange });
  const noForms = !forms || !forms.length;
  const rootFormId = rootForm?.id;
  const isSystemForm =
    forms.length === 1 &&
    (rootForm?.type === 'system' || rootForm?.form?.type === 'system');
  const isExtendable =
    !isSystemForm || PARENT_SYSTEM_FORMS.includes(rootForm?.title);

  const getIsActiveSection = (formId, sectionIndex) => {
    return (
      activeSection &&
      activeSection.formId === formId &&
      activeSection.index === sectionIndex
    );
  };

  /**
   * Скролл к выбранной секции
   */
  const handleClickSection = (formIndex, index) => {
    if (!formRefs.current[formIndex] || !containerEl) return;
    const isActive = getIsActiveSection(forms[formIndex].id, index);
    if (index === -1 && isActive) return;
    setTimeout(() => {
      const formId = forms[formIndex].id;
      if (!formRefs.current[formIndex].current) return;
      FormUtils.scrollToFormSection(containerEl, formId, index);
    }, 400);
  };

  /**
   * Модальное окно для создания/редактирования формы
   */
  const showFormEditorIframe = ({ formId, parentId }) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'FormEditorIframe',
        data: {
          step: 'CreateForm',
          formId,
          parentId,
        },
        callback: (resForm) => {
          if (formId) {
            handleUpdateForm(resForm);
          } else {
            handleAddForms(resForm.forms);
          }
        },
      },
    });
  };

  /**
   * Модальное окно с инф., что удалятся и все дочерние формы
   */
  const showModalConfirm = (form) => {
    const treeIds = [form.id];
    const formsToDel = [];
    for (const item of forms) {
      if (item.id === form.id) continue;
      if (treeIds.includes(item.id)) {
        formsToDel.push(item);
      } else if (treeIds.includes(item.parentId)) {
        formsToDel.push(item);
        treeIds.push(item.id);
      }
    }

    if (!formsToDel.length) {
      handleRemoveForms(treeIds);
      return;
    }
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'removeFormFromActor',
          content: (
            <div styleName="sections__panel__delList">
              <Label value={t(mes.removeFormWithChildren)} />
              {formsToDel.map((i) => (
                <div key={i.id} styleName="sections__panel__delList__item">
                  <Badge
                    bgColor={i.color || '#ffffff'}
                    borderColor={i.color || '#ACB3BE'}
                  />
                  <Label value={i.title} />
                </div>
              ))}
            </div>
          ),
          buttons: [
            {
              label: t(mes.remove),
              onClick: () => {
                handleRemoveForms(treeIds);
                dispatch({ type: DEL_MODAL });
              },
            },
          ],
        },
      },
    });
  };

  const handleFormMenuClick = (form, action) => {
    switch (action) {
      case 'remove':
        showModalConfirm(form);
        break;
      case 'edit':
        showFormEditorIframe({ formId: form.id });
        break;
      case 'child':
        showFormEditorIframe({ parentId: form.id });
        break;
      default:
        break;
    }
    toggleFormMenu(null);
  };

  const renderError = (count) => {
    if (!count) return null;
    return (
      <Tooltip
        topLevel
        value={count > 1 ? t(mes.errorsFound, { count }) : t(mes.errorFound)}
      >
        <div styleName="sections__panel__item__error">
          <Label value={count} />
          <Icon type="alert" />
        </div>
      </Tooltip>
    );
  };

  const renderMainSection = () => (
    <div
      styleName={cn('sections__panel__item', {
        active: activeSection && activeSection.index === -1,
      })}
      onClick={() => {
        handleSelectSection({ formId: rootForm.id, index: -1 });
        Utils.scrollTo(containerEl, 0, 200);
      }}
    >
      <Label fontWeight="normal" value="Title & Ref." />
    </div>
  );

  const isNoContentForm = (sections) => {
    if (!sections || !sections.length) return true;
    return sections.length === 1 && !sections[0].content.length;
  };

  const renderSectionList = (item, formIndex) => {
    const { form, id: formId, parentId } = item;
    const isUAT = !parentId;
    if (isNoContentForm(form.sections)) {
      return isUAT ? (
        renderMainSection()
      ) : (
        <div styleName="sections__panel__item empty">
          <Label fontWeight="normal" value={t(mes.empty)} />
        </div>
      );
    }

    return form.sections.map((item, index) => {
      const errorCount = item.content.filter((i) => i.error).length;
      const sectionId = `${formId}_${index}`;
      const isActive = getIsActiveSection(formId, index);
      const sectionTitle = item.title || `Section ${index + 1}`;
      return (
        <Fragment key={sectionId}>
          {isUAT && index === 0 ? renderMainSection() : null}
          <div
            styleName={cn('sections__panel__item', { active: isActive })}
            onClick={() => {
              handleSelectSection({ formId, index });
              handleClickSection(formIndex, index);
            }}
          >
            <Tooltip topLevel value={sectionTitle}>
              <Label fontWeight="normal" value={sectionTitle} />
            </Tooltip>
            {renderError(errorCount)}
          </div>
        </Fragment>
      );
    });
  };

  /**
   * Счетчик ошибок в форме
   */
  const renderTotalErrors = (sections) => {
    const count = sections.reduce((sum, elem) => {
      return sum + elem.content.filter((i) => i.error).length;
    }, 0);

    if (!count) return null;
    return (
      <Tooltip
        topLevel
        value={count > 1 ? t(mes.errorsFound, { count }) : t(mes.errorFound)}
      >
        <div
          styleName="sections__panel__item__error total"
          onClick={() => togglePanel((prevOpen) => !prevOpen)}
        >
          <Icon type="alert" />
        </div>
      </Tooltip>
    );
  };

  /**
   * Контекстное меню действий с формой
   */
  const renderFormMenu = (form) => {
    const privs = !form.accessDenied ? form.privs || form.form.privs : form;
    const canEditForm = privs && privs.modify && !form.isDefault;
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          styleName="sections__panel__menu"
          size="small"
          onClick={({ value }) => handleFormMenuClick(form, value)}
          onClose={() => toggleFormMenu(null)}
        >
          <MenuItem
            size="small"
            label={t(mes.remove)}
            value="remove"
            visibility={
              checkIsInheritForm(form.id) && !form.isDefault && !form.parentId
                ? 'disabled'
                : 'visible'
            }
          />
          <MenuItem
            size="small"
            label={t(mes.createChildForm)}
            value="child"
            visibility={
              form.isDefault ||
              form.accessDenied ||
              !canManageForms ||
              (form.form.type && !PARENT_SYSTEM_FORMS.includes(form.form.title))
                ? 'disabled'
                : 'visible'
            }
          />
          <MenuItem
            size="small"
            label={t(mes.edit)}
            value="edit"
            visibility={canEditForm ? 'visible' : 'disabled'}
          />
        </Menu>
      </div>
    );
  };

  const renderFormActions = (form) => {
    if (form.type === 'system') return null;
    return (
      <div styleName="sections__panel__menu__wrap">
        {!form.accessDenied ? renderTotalErrors(form.form.sections) : null}
        <Icon
          type="more"
          styleName="table__row__icon"
          onClick={(e) => {
            e.stopPropagation();
            toggleFormMenu(form.id);
          }}
        />
        {formMenu === form.id ? renderFormMenu(form) : null}
      </div>
    );
  };

  return (
    <div
      styleName={cn('sections__panel', { collapsed: !isOpenPanel, noForms })}
    >
      <div styleName="sections__panel__header">
        {isOpenPanel ? (
          <>
            <Label fontWeight="semibold" value={t(mes.forms)} />
            {isExtendable && canManageForms ? (
              <Tooltip value={t(mes.addForm)}>
                <div onClick={() => toggleFormSearch(!formSearch)}>
                  <Icon styleName="sections__panel__header__add" type="add" />
                </div>
              </Tooltip>
            ) : null}
          </>
        ) : null}
        <div
          styleName="sections__panel__header__collapse"
          onClick={() => togglePanel((prevOpen) => !prevOpen)}
        >
          <Icon type="arrows" size="small" />
        </div>
      </div>
      {formSearch ? (
        <div styleName="sections__panel__header__select">
          <SelectActorsTemplate
            bordered={true}
            value={{}}
            exclude={forms}
            withDefault={false}
            withRelations={true}
            formTypes={null}
            fullModel={true}
            autoFocus={true}
            hideCreateBtn={!canManageForms}
            placeholder={t(mes.findForm)}
            isSameRoot={!checkIsInheritForm(rootFormId)}
            rootFormId={rootFormId}
            onChange={({ value }) => {
              if (!value.forms) return;
              handleSelectNewForm(value);
              handleAddForms(value.forms);
            }}
            onBlur={() => toggleFormSearch(false)}
            onCreate={() => {
              showFormEditorIframe({ parentId: rootForm ? rootForm.id : null });
            }}
          />
        </div>
      ) : null}
      {isOpenPanel
        ? forms.map((item, index) =>
            item.accessDenied ? (
              <div key={item.id} styleName="sections__panel__accessDenied">
                <Label value={item.title} />
                <Label fontSize="small" value={t(mes.accessDenied)} />
                {renderFormActions(item)}
              </div>
            ) : (
              <Toggle
                key={item.id}
                theme="white"
                type="default"
                fontWeight="normal"
                isOpen={false}
                isActive={
                  (activeSection && activeSection.formId === item.id) ||
                  (formMenu && formMenu.id === item.id)
                }
                title={item.title}
                tooltip={item.title}
                rightComponent={renderFormActions(item)}
              >
                <div styleName="sections__panel__list">
                  {renderSectionList(item, index)}
                </div>
              </Toggle>
            )
          )
        : null}
    </div>
  );
}

FormSectionsPanel.propTypes = {
  rootForm: PropTypes.object,
  forms: PropTypes.array.isRequired,
  activeSection: PropTypes.shape({
    formId: PropTypes.number,
    index: PropTypes.number,
  }),
  canManageForms: PropTypes.bool,
  handleSelectSection: PropTypes.func.isRequired,
  handleSelectNewForm: PropTypes.func,
  handleFormsChange: PropTypes.func,
};

export default FormSectionsPanel;
