import { CorezoidLightTheme as theme } from 'mw-style-react';
import {
  NODES_COLORS_PALETTE,
  LAYER_GRID_COLOR,
} from '@control-front-end/common/constants/graphActors';

/**
 * Убрать прозрачные пиксели из canvas
 * @param c
 * @returns {HTMLCanvasElement}
 */
function canvasTrimTransparentPixels(c) {
  const ctx = c.getContext('2d');
  const copy = document.createElement('canvas').getContext('2d');
  if (!c.width || !c.height) return c;
  const pixels = ctx.getImageData(0, 0, c.width, c.height);
  const l = pixels.data.length;
  const bound = {
    top: null,
    left: null,
    right: null,
    bottom: null,
  };

  for (let i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] === 0) continue;
    const x = (i / 4) % c.width;
    const y = Math.floor(i / 4 / c.width);
    if (bound.top === null) bound.top = y;
    if (bound.left === null || x < bound.left) bound.left = x;
    if (bound.right === null || bound.right < x) bound.right = x;
    if (bound.bottom === null || bound.bottom < y) bound.bottom = y;
  }

  const trimHeight = bound.bottom - bound.top;
  const trimWidth = bound.right - bound.left;
  if (!trimWidth || !trimHeight) return copy.canvas;
  const trimmed = ctx.getImageData(
    bound.left,
    bound.top,
    trimWidth,
    trimHeight
  );
  copy.canvas.width = trimWidth;
  copy.canvas.height = trimHeight;
  copy.putImageData(trimmed, 0, 0);
  return copy.canvas;
}

function hexToRgba(hex, opacity = 1) {
  const rgb = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
}

/**
 * Calculate the width of the text string based on the current font and style settings
 * in the canvas's 2D context without rendering (use canvas for graph grid if exist)
 */
function measureTextWidth({
  text,
  fontSize = theme.label.fontSize.medium,
  fontFamily = 'Open Sans,sans-serif',
  fontWeight = theme.label.fontWeight.semibold,
}) {
  const canvas =
    document.getElementById('gridRender') || document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  return ctx.measureText(text).width;
}

/**
 * Возвращает картингу polygon
 * @param polygon
 * @param color
 * @param zoom
 * @param opacity
 * @returns {{img: string, width: number, height: number}}
 */
function createImgByPolygon({
  polygon,
  color: propColor,
  opacity = 0.4,
  bgGrid = false,
}) {
  const color = propColor || NODES_COLORS_PALETTE.Simulator;
  const canvas = document.createElement('canvas');
  const xCoords = polygon.map((point) => point[0]);
  const yCoords = polygon.map((point) => point[1]);
  const minX = Math.min(...xCoords);
  const minY = Math.min(...yCoords);
  canvas.width = Math.max(...xCoords) - minX;
  canvas.height = Math.max(...yCoords) - minY;
  const ctx = canvas.getContext('2d');
  ctx.translate(-minX, -minY);
  ctx.beginPath();
  for (const position of polygon) {
    ctx.lineTo(position[0], position[1]);
  }
  ctx.lineWidth = 1;
  ctx.strokeStyle = hexToRgba(color, opacity);
  ctx.stroke();
  ctx.closePath();
  ctx.fillStyle = hexToRgba(color, opacity);
  ctx.fill();
  if (bgGrid) {
    ctx.strokeStyle = LAYER_GRID_COLOR;
    ctx.lineWidth = 1;
    ctx.translate(minX, minY);
    for (let x = 0; x <= canvas.width; x += 50) {
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, canvas.height);
      ctx.stroke();
    }
    for (let y = 0; y <= canvas.height; y += 50) {
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(canvas.width, y);
      ctx.stroke();
    }
  }
  ctx.save();
  const trimCanvas = canvasTrimTransparentPixels(canvas);
  const img = trimCanvas.toDataURL('image/png');
  return {
    img,
    width: trimCanvas.width,
    height: trimCanvas.height,
    type: 'area',
  };
}

/**
 * Получить предельные координаты polygon
 * @param polygon
 * @returns {{minX: number, maxY: number, maxX: number, minY: number}}
 */
function getPolygonLimitCoord(polygon) {
  const xCoords = [];
  const yCoords = [];
  polygon.forEach((point) => {
    xCoords.push(point[0]);
    yCoords.push(point[1]);
  });
  const maxX = Math.max(...xCoords);
  const maxY = Math.max(...yCoords);
  const minX = Math.min(...xCoords);
  const minY = Math.min(...yCoords);
  return { maxX, maxY, minX, minY };
}

function getPolygonCenterCoord(polygon) {
  const { minX, minY, maxY, maxX } = this.getPolygonLimitCoord(polygon);
  const centerX = minX + (maxX - minX) / 2;
  const centerY = minY + (maxY - minY) / 2;
  return { x: centerX, y: centerY };
}

function snapPolygonToNewCenter(polygon, newPolygonCenter) {
  const prevPolygonCenter = this.getPolygonCenterCoord(polygon);
  const positionDiff = {
    x: newPolygonCenter.x - prevPolygonCenter.x,
    y: newPolygonCenter.y - prevPolygonCenter.y,
  };

  return polygon.map((point) => [
    point[0] + positionDiff.x,
    point[1] + positionDiff.y,
  ]);
}

function checkPointInsidePolygon(position, polygon, tolerance = 0) {
  const { minX, minY, maxX, maxY } = this.getPolygonLimitCoord(polygon);
  const { x, y } = position;
  return (
    x >= minX - tolerance &&
    x <= maxX + tolerance &&
    y >= minY - tolerance &&
    y <= maxY + tolerance
  );
}

function checkBoundingBoxInsidePolygon({ x1, x2, y1, y2 }, polygon, tolerance) {
  return (
    this.checkPointInsidePolygon({ x: x1, y: y1 }, polygon, tolerance) &&
    this.checkPointInsidePolygon({ x: x2, y: y2 }, polygon, tolerance)
  );
}

function getPolygonSize(polygon) {
  const { maxX, maxY, minX, minY } = this.getPolygonLimitCoord(polygon);
  return {
    width: Math.abs(maxX - minX),
    height: Math.abs(maxY - minY),
  };
}

function makeRectanglePolygon({ x1, y1, x2, y2 }) {
  return [
    [x1, y1],
    [x1, y2],
    [x2, y2],
    [x2, y1],
  ];
}

function shiftPolygon(polygon, { x, y }) {
  return polygon.map((point) => [point[0] + x, point[1] + y]);
}

function makeBoundingBoxFromPolygon(polygon) {
  const xs = polygon.map((point) => point[0]);
  const ys = polygon.map((point) => point[1]);

  const x1 = Math.min(...xs);
  const x2 = Math.max(...xs);
  const y1 = Math.min(...ys);
  const y2 = Math.max(...ys);

  return {
    x1,
    x2,
    y1,
    y2,
    w: x2 - x1,
    h: y2 - y1,
  };
}

function renderedCoordinateToPosition(renderedCoordinate, zoom, panCoordinate) {
  return (renderedCoordinate - panCoordinate) / zoom;
}

function renderRectangle({
  ctx,
  x,
  y,
  width,
  height,
  borderRadius = 0,
  color,
}) {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(x + borderRadius, y);
  ctx.lineTo(x + width - borderRadius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
  ctx.lineTo(x + width, y + height - borderRadius);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - borderRadius,
    y + height
  );
  ctx.lineTo(x + borderRadius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
  ctx.lineTo(x, y + borderRadius);
  ctx.quadraticCurveTo(x, y, x + borderRadius, y);
  ctx.closePath();
  ctx.fill();
}

export default {
  canvasTrimTransparentPixels,
  createImgByPolygon,
  getPolygonLimitCoord,
  getPolygonCenterCoord,
  snapPolygonToNewCenter,
  getPolygonSize,
  makeRectanglePolygon,
  shiftPolygon,
  makeBoundingBoxFromPolygon,
  checkPointInsidePolygon,
  checkBoundingBoxInsidePolygon,
  hexToRgba,
  renderedCoordinateToPosition,
  renderRectangle,
  measureTextWidth,
};
