function SVGIconVerified(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.translate(-4, -4);
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.translate(4, 4);
  ctx.save();
  ctx.fillStyle = '#FFFFFF';
  ctx.strokeStyle = '#DDE0E5';
  ctx.beginPath();
  ctx.arc(8, 8, 7.5, 0, 6.283185307179586, false);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = '#22C933';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.beginPath();
  ctx.moveTo(8, 2);
  ctx.bezierCurveTo(11.3137085, 2, 14, 4.6862915, 14, 8);
  ctx.bezierCurveTo(14, 11.3137085, 11.3137085, 14, 8, 14);
  ctx.bezierCurveTo(4.6862915, 14, 2, 11.3137085, 2, 8);
  ctx.bezierCurveTo(2, 4.6862915, 4.6862915, 2, 8, 2);
  ctx.closePath();
  ctx.moveTo(11.232233, 6.26455733);
  ctx.bezierCurveTo(
    10.8752104,
    5.91181422,
    10.2963624,
    5.91181422,
    9.93933983,
    6.26455733
  );
  ctx.lineTo(9.93933983, 6.26455733);
  ctx.lineTo(7.35290694, 8.81871025);
  ctx.lineTo(6.06066017, 7.54195314);
  ctx.bezierCurveTo(
    5.70363757,
    7.18921003,
    5.12478956,
    7.18921003,
    4.76776695,
    7.54195314
  );
  ctx.bezierCurveTo(
    4.41074435,
    7.89469626,
    4.41074435,
    8.46660584,
    4.76776695,
    8.81934895
  );
  ctx.lineTo(4.76776695, 8.81934895);
  ctx.lineTo(6.70710678, 10.7354427);
  ctx.bezierCurveTo(
    7.06412939,
    11.0881858,
    7.6429774,
    11.0881858,
    8,
    10.7354427
  );
  ctx.lineTo(8, 10.7354427);
  ctx.lineTo(11.232233, 7.54195314);
  ctx.bezierCurveTo(
    11.5892557,
    7.18921003,
    11.5892557,
    6.61730045,
    11.232233,
    6.26455733
  );
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconVerified;
