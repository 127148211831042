import {
  SEARCH_ACTORS,
  GET_TEMPLATE_ACTORS,
  GET_ALL_ACTORS,
  CREATE_ACTOR_LIST,
  UPDATE_ACTOR_LIST,
  GET_ACTORS_LIST,
  UPDATE_ACTOR_UNREAD_REACTION,
  REMOVE_ACTOR_LIST,
  SET_ACTIVE_ACTOR,
  SET_ACTORS_SEARCH_LIST,
  ACTORS_ACCESS,
  CLEAR_LIST_ACTORS,
  SET_ACTORS_REQ_STATUS,
  REMOVE_ALL_FORM_ACTORS,
} from '@control-front-end/common/constants/graphActors';
import { RECENT_ACTORS } from 'constants';
import { GET_STREAM_ACTORS } from '@control-front-end/common/constants/streams';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  activeActor: null,
  activeReactionId: null,
  searchList: [],
  list: [],
  limit: 20,
  offset: 0,
  total: 0,
  endList: false,
  formId: null,
  filter: null,
  reqStatus: 'init',
  orderBy: 'created_at',
  orderValue: 'DESC',
};

export default createReducer(initState, {
  [GET_ACTORS_LIST.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SEARCH_ACTORS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_ALL_ACTORS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [RECENT_ACTORS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_TEMPLATE_ACTORS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_STREAM_ACTORS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ACTORS_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_ACTOR_UNREAD_REACTION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_ACTOR_LIST.SUCCESS](state, action) {
    return {
      ...state,
      list: action.payload.list,
      activeActor: action.payload.activeActor,
      offset: state.offset + 1,
      total: state.total + 1,
    };
  },
  [REMOVE_ACTOR_LIST.SUCCESS](state, action) {
    return {
      ...state,
      list: action.payload.list,
      activeActor: action.payload.activeActor,
      offset: state.offset - 1 < 0 ? 0 : state.offset - 1,
      total: action.payload.total || state.total - 1,
    };
  },
  [REMOVE_ALL_FORM_ACTORS.SUCCESS](state) {
    return {
      ...state,
      activeActor: null,
      list: [],
      offset: 0,
      total: 0,
      endList: true,
    };
  },
  [UPDATE_ACTOR_LIST.SUCCESS](state, action) {
    return { ...state, list: action.payload.list };
  },
  [SET_ACTIVE_ACTOR](state, { payload: { id, rid } }) {
    return {
      ...state,
      activeActor: id,
      activeReactionId: rid,
    };
  },
  [SET_ACTORS_SEARCH_LIST](state, { payload }) {
    return {
      ...state,
      searchList: payload,
    };
  },
  [SET_ACTORS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_LIST_ACTORS](state) {
    return {
      ...initState,
      reqTime: state.reqTime,
    };
  },
});
