import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useIntl from 'useIntl';
import { Button, Label, Stack, TextField } from 'mw-style-react';
import {
  GET_ACTOR,
  GET_TEMPLATE_ACTORS,
} from '@control-front-end/common/constants/graphActors';
import {
  MAX_CHART_FILTER_TOP_VALUE,
  MIN_CHART_FILTER_TOP_VALUE,
} from '@control-front-end/common/constants/actorsFilters';
import { INCOME_TYPE } from '@control-front-end/common/constants/actorAccounts';
import SelectActors from '@control-front-end/common/components/SelectActors';
import AppUtils from '@control-front-end/utils/utils';
import cn from 'classnames';
import mes from './intl';
import scss from './DashboardFromActorFilter.scss';

const TOP_VALUES = [
  { value: '3', title: '3' },
  { value: '5', title: '5' },
  { value: '10', title: '10' },
  { value: '15', title: '15' },
  { value: '20', title: '20' },
  { value: '25', title: '25' },
  { value: '30', title: '30' },
];

/**
 * Settings panel for actors filter dashboard source
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DashboardFromActorFilter({ value = {}, onChange, onAccountsChange }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const systemForms = useSelector((state) => state.systemForms) || {};
  const systemFormsFilters = systemForms.actorfilters || {};
  const error =
    value.id && (!value.filter?.accountNameId || !value.filter?.currencyId);
  const topInputError =
    value.top > MAX_CHART_FILTER_TOP_VALUE ||
    value.top < MIN_CHART_FILTER_TOP_VALUE;

  const handleChangeTop = (newTop) => {
    onChange({ ...value, top: newTop });
  };

  useEffect(() => {
    if (value.top === undefined) {
      handleChangeTop(TOP_VALUES[0].value);
    }
  }, [value.top]);

  const fetchActorAccounts = () => {
    const filter = { ...value.filter };
    if (!filter?.accountNameId || !filter?.currencyId) return;
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: {
        id: value.id,
      },
      callback: (filterActor) => {
        const filter = AppUtils.jsonParse(filterActor?.data?.filter, {});
        onChange({ ...value, filter });
        if (!filter?.accountNameId || !filter?.currencyId) return;
        dispatch({
          type: GET_TEMPLATE_ACTORS.REQUEST,
          payload: {
            formId: filter.formId,
            limit: value.top,
            offset: 0,
            filter,
            localState: true,
          },
          callback: (data) => {
            const newAccounts = data.list.map(({ actorId }) => ({
              actor: { id: actorId },
              color: AppUtils.getRandomColorByPalette(),
              account: {
                nameId: filter.accountNameId,
                currencyId: filter.currencyId,
              },
              incomeType: filter.incomeType || INCOME_TYPE.total,
            }));
            onAccountsChange(newAccounts);
          },
        });
      },
    });
  };

  const handleChange = ({ value: newValue }) => {
    if (!newValue) return;
    const { id, title, data } = newValue;
    const filter = AppUtils.jsonParse(data?.filter, {});
    const sourceModel = {
      id,
      title,
      top: value.top,
      filter,
    };
    onChange(sourceModel);
  };

  useEffect(() => {
    fetchActorAccounts();
  }, [value?.id]);

  const getErrorText = () => {
    if (!value.id || !error) return '';
    return t(mes.filterHasNoAccountSettings);
  };

  const renderTopValues = () => (
    <Stack.H size={Stack.SIZE.xsmall} fullWidth>
      {TOP_VALUES.map((item) => (
        <Button
          key={`top_value_${item.value}`}
          className={cn(scss.button, scss.buttonTop)}
          type={item.value === value.top ? 'default' : 'secondary'}
          label={item.title}
          size="small"
          fontWeight="normal"
          onClick={() => {
            handleChangeTop(item.value);
          }}
        />
      ))}
      <TextField
        className={scss.topInput}
        bordered
        unspaced
        length={MAX_CHART_FILTER_TOP_VALUE.toString().length}
        type="int"
        error={topInputError}
        value={value.top}
        helperText={topInputError ? t(mes.topValueError) : ''}
        placeholder={t(mes.customTopPlaceholder)}
        onChange={({ value }) => {
          handleChangeTop(value);
        }}
      />
    </Stack.H>
  );

  return (
    <Stack.H
      className={scss.sourceType}
      alignItems="flexStart"
      fullWidth
      size={Stack.SIZE.small}
    >
      <div className={scss.filterSelect}>
        <SelectActors
          key="actorFilter"
          id="actorFilter"
          bordered={true}
          type="modal"
          multiselect={false}
          error={error}
          helperText={getErrorText()}
          label={t(mes.selectActorFilterCapital)}
          placeholder={t(mes.selectActorFilter)}
          formId={systemFormsFilters.id}
          value={value}
          exclude={[]}
          withData
          onChange={handleChange}
        />
      </div>
      <Stack justifyContent="center" size={Stack.SIZE.xxsmall}>
        <Stack.H size={Stack.SIZE.xsmall} alignItems="flexStart">
          <Label fontWeight="semibold" value={t(mes.top)} />
          <Label value={t(mes.topBrackets)} />
        </Stack.H>
        {renderTopValues()}
      </Stack>
    </Stack.H>
  );
}

export default DashboardFromActorFilter;
