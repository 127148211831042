import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import pickBy from 'lodash/pickBy';
import { GET_FORM, GET_FORMS } from '@control-front-end/common/constants/forms';
import {
  Label,
  Icon,
  Popover,
  Card,
  Space,
  Stack,
  Divider,
  Button,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import getFormFieldsMap from '@control-front-end/utils/getFormFieldsMap';
import SelectFormAndField from '@control-front-end/app/src/components/SelectFormAndField';
import './TransactionsFiltersOptions.scss';
import mes from './intl';

const initalState = { form: null, field: null };

function SelectFormAndFieldDropDown({
  formId,
  fieldId,
  filterFields,
  onChange,
}) {
  const t = useIntl();
  // Separate state to save value before confirmation
  const [tmpState, setTmpState] = useState(initalState);
  const [state, setState] = useState(initalState);
  const dispatch = useDispatch();

  const setRecentFormAndFirstFeild = () => {
    dispatch({
      type: GET_FORMS.REQUEST,
      payload: {
        formTypes: 'all',
        withRelations: true,
        withDefault: false,
        limit: 1,
        offset: 0,
      },
      callback: (data) => {
        // Set first field of the most recent form as default
        const firstFormFirstField = Object.values(
          pickBy(getFormFieldsMap(data?.list?.[0]), filterFields)
        )[0];

        if (firstFormFirstField) {
          const newState = {
            form: data?.list?.[0],
            field: firstFormFirstField,
          };
          setState(newState);
          setTmpState(newState);
          onChange(newState);
        }
      },
    });
  };

  useEffect(() => {
    if (formId && fieldId) {
      dispatch({
        type: GET_FORM.REQUEST,
        payload: { formId, withRelations: true },
        callback: (data) => {
          // Set first field of the most recent form as default
          const initialFormField = getFormFieldsMap(data)[fieldId];
          if (initialFormField) {
            const newState = { form: data, field: initialFormField };
            setState(newState);
            setTmpState(newState);
            onChange(newState);
            return;
          }
          setRecentFormAndFirstFeild();
        },
      });
      return;
    }

    setRecentFormAndFirstFeild();
  }, []);

  const title = useMemo(() => {
    if (state.form?.id && state.field?.id) {
      return `${state.form.title}, ${state.field.title}`;
    }
    return 'Any';
  }, [state]);

  return (
    <Popover
      padding={4}
      onClose={() => setTmpState(initalState)}
      content={({ onClose }) => (
        <Card style={{ width: '400px', overflow: 'visible' }}>
          <Space size="small">
            <Stack.V divider={<Divider />}>
              <SelectFormAndField
                value={tmpState.field && tmpState.form ? tmpState : state}
                size="small"
                onChange={setTmpState}
                filterFields={filterFields}
              />
              <Stack.H size="small" fullWidth justifyContent="flexEnd">
                <Button
                  size="small"
                  type="text"
                  label={t(mes.cancel)}
                  onClick={onClose}
                />
                <Button
                  size="small"
                  label={t(mes.apply)}
                  disabled={!tmpState.form?.id || !tmpState.field?.id}
                  onClick={() => {
                    setState(tmpState);
                    onChange(tmpState);
                    onClose();
                  }}
                />
              </Stack.H>
            </Stack.V>
          </Space>
        </Card>
      )}
      anchors={{
        binding: Popover.ANCHOR.left_bottom,
        content: Popover.ANCHOR.left_top,
      }}
    >
      <Card background="#e7f1ff" boxShadow="none">
        <Space size="xsmall">
          <Stack.H size="xxsmall" alignItems="center">
            <Label
              styleName="tf__type__title"
              fontWeight="semibold"
              color={Label.COLOR.primary}
              value={`${t(mes.account)}: ${title}`}
            />
            <Icon type="dropdown" size="micro" />
          </Stack.H>
        </Space>
      </Card>
    </Popover>
  );
}

SelectFormAndFieldDropDown.defaultProps = {
  filterFields: () => true,
};

SelectFormAndFieldDropDown.propTypes = {
  formId: PropTypes.number,
  fieldId: PropTypes.string,
  filterFields: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default SelectFormAndFieldDropDown;
