import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Upload,
  Divider,
  MenuItem,
  ColorPicker,
  Popover,
  Card,
  Space,
  Stack,
  Icon,
} from 'mw-style-react';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import { UPLOAD_FILE } from 'constants';
import {
  ACTORS_COLORS_PALETTE,
  STATES_COLORS_PALETTE,
} from '@control-front-end/common/constants/graphActors';
import mes from './intl';
import scss from './ActorAvatarEditable.scss';

/**
 * Редактируемый аватар актора на панели
 * @returns {*}
 * @constructor
 */
function ActorAvatarEditable(props) {
  const {
    size,
    system,
    picture,
    pictureUrl,
    colors = [],
    accessDenied,
    status,
    iconLabel,
    formType,
    formTitle,
    isState = false,
    handleChange,
    handleSelectColor,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const avatarRef = useRef();
  const menuRef = useRef();
  const [menu, toggleMenu] = useState(false);
  const [picker, togglePicker] = useState(false);
  const [palette, togglePalette] = useState(false);
  const actorColor = colors.find((i) => i.type === 'actor') || {};
  const formColor = colors.find((i) => i.type === 'form') || {};
  const mainColor = actorColor?.color ? actorColor : formColor;
  const [bgColor, setBgColor] = useState(mainColor?.color || '#ffffff');
  const [pickerColor, setPickerColor] = useState(null);
  const hasCustomColor = actorColor && actorColor.color !== formColor.color;

  const handleClick = ({ target }) => {
    if (
      avatarRef.current &&
      !avatarRef.current.contains(target) &&
      menuRef.current &&
      !menuRef.current.contains(target)
    ) {
      if (target.tagName === 'INPUT' && target.getAttribute('type') === 'file')
        return;
      toggleMenu(false);
    }
  };

  useEffect(() => {
    if (mainColor && mainColor.color !== bgColor) setBgColor(mainColor.color);
  }, [mainColor]);

  useEffect(() => {
    const rootNode = document.getElementById('root');
    rootNode.addEventListener('click', handleClick, true);
    return () => {
      rootNode.removeEventListener('click', handleClick);
    };
  }, []);

  /**
   * Применить аватар
   */
  const changeAvatar = (newPicture = null) => {
    const newPictureUrl = newPicture
      ? AppUtils.makeAppUrl(`/download/${newPicture}`)
      : '';
    handleChange({
      picture: newPicture,
      pictureUrl: newPictureUrl,
      color: actorColor.color,
    });
  };

  /**
   * Применить цвет
   */
  const changeColor = (color = null) => {
    handleChange({ color, picture, pictureUrl });
  };

  /**
   * Загрузить изображение для актора
   */
  const handleUploadPicture = ({ value }) => {
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files: value,
        callback: (attachments) => {
          changeAvatar(attachments[0].fileName);
          if (menu) toggleMenu(false);
        },
      },
    });
  };

  const renderColorPicker = () => (
    <ColorPicker
      value={pickerColor || bgColor}
      onChange={({ hex }) => {
        setPickerColor(hex);
        if (handleSelectColor) handleSelectColor(hex);
        if (hex && hex !== bgColor) {
          changeColor(hex);
        }
      }}
      onClose={() => {
        if (pickerColor && pickerColor !== bgColor) {
          changeColor(pickerColor);
        }
        togglePicker(false);
      }}
      disableAlpha={true}
    />
  );

  const renderColorPalette = () => (
    <Card borderRadius="large">
      <Space size={Space.SIZE.xsmall} className={scss.paletteBox}>
        <Stack.H
          size={Stack.SIZE.xsmall}
          className={scss.buttonsWrap}
          alignItems="center"
        >
          {Object.entries(
            isState ? STATES_COLORS_PALETTE : ACTORS_COLORS_PALETTE
          ).map(([key]) => {
            const paletteColor = isState
              ? STATES_COLORS_PALETTE[key]
              : ACTORS_COLORS_PALETTE[key];
            return (
              <div
                key={`buttonColor_${key}`}
                className={scss.colorButton}
                style={{
                  backgroundColor: paletteColor,
                }}
                onClick={() => {
                  setPickerColor(paletteColor);
                  if (handleSelectColor) handleSelectColor(paletteColor);
                  changeColor(paletteColor);
                  toggleMenu(false);
                  togglePalette(false);
                }}
              />
            );
          })}
          <Stack
            className={scss.pickerButton}
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              togglePicker(true);
            }}
          >
            <Icon size="large" type="add" />
          </Stack>
        </Stack.H>
      </Space>
    </Card>
  );

  const renderAvatarMenu = () => (
    <div
      ref={menuRef}
      className={scss.editable__avatar__menu}
      onClick={(e) => e.stopPropagation()}
    >
      <Upload
        className={scss.editable__avatar__replace}
        type="avatar"
        accept="image/*"
        preview={false}
        onChange={(obj) => {
          handleUploadPicture(obj);
        }}
        visibility={(system && 'disabled') || 'visible'}
      >
        <MenuItem
          className={scss.editable__avatar__menu__item}
          leftIcon="camera"
          label={pictureUrl ? t(mes.replace) : t(mes.addAvatar)}
          onClick={() => {}}
        />
      </Upload>
      <Popover
        anchors={{
          binding: Popover.ANCHOR.right_top,
          content: Popover.ANCHOR.left_top,
        }}
        content={() => {
          if (!palette) return null;
          return picker ? renderColorPicker() : renderColorPalette();
        }}
      >
        <MenuItem
          className={scss.editable__avatar__menu__item}
          leftIcon="palette"
          rightIcon="arrow"
          label={t(mes.setColor)}
          onClick={() => {
            togglePalette(true);
            togglePicker(false);
          }}
        />
      </Popover>
      {pictureUrl || hasCustomColor ? (
        <Divider className={scss.editable__avatar__menu__divider} />
      ) : null}
      {pictureUrl ? (
        <MenuItem
          leftIcon="trash"
          label={t(mes.clearAvatar)}
          onClick={() => {
            changeAvatar();
            toggleMenu(false);
            togglePicker(false);
          }}
          visibility={(system && 'disabled') || 'visible'}
        />
      ) : null}
      <MenuItem
        leftIcon="trash"
        label={t(mes.resetColor)}
        visibility={hasCustomColor ? 'visible' : 'hidden'}
        onClick={() => {
          changeColor();
          setPickerColor(formColor.color);
          toggleMenu(false);
          togglePicker(false);
        }}
      />
    </div>
  );

  return (
    <div
      className={scss.editable__avatar}
      ref={avatarRef}
      onClick={(e) => {
        if (!e.target.closest('div[class^="editable__avatar"]')) return;
        e.stopPropagation();
        toggleMenu(true);
      }}
    >
      <Popover
        topLevel
        content={() => {
          if (menu) return renderAvatarMenu();
          return null;
        }}
      >
        <ActorAvatar
          size={size}
          icon={!pictureUrl && !formType ? 'picture' : undefined}
          formType={formType}
          formTitle={formTitle}
          iconLabel={iconLabel}
          pictureUrl={pictureUrl}
          color={pickerColor || bgColor}
          colorFilled={true}
          colors={colors}
          accessDenied={accessDenied}
          status={status}
        />
      </Popover>
    </div>
  );
}

ActorAvatarEditable.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'xxlarge']),
  picture: PropTypes.string,
  pictureUrl: PropTypes.string,
  colors: PropTypes.array,
  accessDenied: PropTypes.bool,
  status: PropTypes.oneOf(['verified', 'rejected', 'pending', null]),
  formType: PropTypes.string,
  formTitle: PropTypes.string,
  system: PropTypes.bool,
  isState: PropTypes.bool,
  handleSelectColor: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

export default ActorAvatarEditable;
