import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import { GRAPH_DISCOVERY } from '@control-front-end/common/constants/graphLayers';
import useLayerActions from '@control-front-end/app/src/routes/ActorsGraph/routers/Graph/hooks/useLayerActions';
import {
  ADD_ACTOR,
  EXP_NODE,
  getNodeExpandType,
} from '@control-front-end/common/constants/graphActors';

import { GRAPH_CELL_SIZE } from 'constants';

const DEFAULT_LAYER_NAME = 'New Layer';

const calculateOffset = ({ newOffset, width, height }) => {
  const offsetWidth = width - GRAPH_CELL_SIZE;
  const offsetHeight = height - GRAPH_CELL_SIZE;

  if (Object.values(newOffset).every((val) => !val)) return null;

  const offsetTemplate = {
    top: offsetHeight / 2,
    bottom: offsetHeight / 2,
    left: offsetWidth / 2,
    right: offsetWidth / 2,
  };

  const direction = (offset) => ({
    top: offset.top > 0,
    bottom: offset.bottom > 0,
    left: offset.left > 0,
    right: offset.right > 0,
  });

  const { top, bottom, left, right } = direction(newOffset);

  if (top && !bottom && !left && !right) {
    return { ...offsetTemplate, top: offsetHeight, bottom: 0 };
  }
  if (bottom && !top && !left && !right) {
    return { ...offsetTemplate, top: 0, bottom: offsetHeight };
  }
  if (left && !top && !bottom && !right) {
    return { ...offsetTemplate, left: offsetWidth, right: 0 };
  }
  if (right && !top && !bottom && !left) {
    return { ...offsetTemplate, left: 0, right: offsetWidth };
  }

  return {
    top: top ? offsetHeight : 0,
    bottom: bottom ? offsetHeight : 0,
    left: left ? offsetWidth : 0,
    right: right ? offsetWidth : 0,
  };
};

const useExpandActiveCell = ({ cy, handleSaveActorLayerSettings }) => {
  const dispatch = useDispatch();
  const systemForms = useSelector((state) => state.systemForms);
  const match = useRouteMatch();
  const { id, graphFolderId, acc } = match?.params || {};
  const { handleCreateLayer } = useLayerActions({
    accId: acc,
    layerId: id,
  });

  const expand = ({ newSize, newOffset, position, node: nodeAtPosition }) => {
    const node =
      nodeAtPosition ||
      cy.nodes(`[position.x = ${position.x}][position.y = ${position.y}]`)[0];

    if (
      !node ||
      !node?.data() ||
      node?.data('isTrace') ||
      node?.data('isStateMarkup')
    ) {
      return;
    }

    const { id: nodeId } = node.data();
    const expandType = getNodeExpandType(node, systemForms);
    const defaultSize = EXP_NODE.size[EXP_NODE.sizeType[expandType]];
    const width = Math.round(Math.max(newSize.width, defaultSize.width));
    const height = Math.round(Math.max(newSize.height, defaultSize.height));
    const offset = calculateOffset({ newOffset, width, height });

    if (!offset) {
      return;
    }

    const options =
      expandType === EXP_NODE.contentType.profile
        ? { profile: true, expand: true }
        : { expand: true };

    handleSaveActorLayerSettings(
      {
        id: nodeId,
        settings: {
          expandType,
          offset,
          height,
          width,
          ...options,
        },
      },
      () =>
        dispatch({
          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
          expand: true,
          position,
          offset,
        })
    );
  };

  const expandEmptyCell = ({ newSize, newOffset, position }) => {
    handleCreateLayer({
      name: DEFAULT_LAYER_NAME,
      graphFolderId,
      formData: {},
      addLayer: true,
      callback: (layer) => {
        dispatch({
          type: ADD_ACTOR.REQUEST,
          payload: {
            actorModel: layer,
            position,
            polygon: null,
          },
          callback: (data) => {
            if (data) {
              expand({
                newSize,
                newOffset,
                position,
              });
            }
          },
        });
      },
    });
  };

  const expandActiveCell = useCallback(
    ({ position, newSize, newOffset }) => {
      if (
        !cy ||
        !newSize ||
        !newOffset ||
        Object.values(newOffset).every((val) => !val)
      ) {
        return;
      }

      const nodeAtPosition = cy.nodes(
        `[position.x = ${position.x}][position.y = ${position.y}]`
      )[0];

      if (!nodeAtPosition) {
        expandEmptyCell({ newSize, newOffset, position });
      } else {
        expand({
          newSize,
          newOffset,
          position,
          node: nodeAtPosition,
        });
      }
    },
    [cy]
  );
  return { expandActiveCell };
};

useExpandActiveCell.propTypes = {
  cy: PropTypes.object.isRequired,
  handleSaveActorLayerSettings: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default useExpandActiveCell;
