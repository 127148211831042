import { call, put, select } from 'redux-saga/effects';
import AppUtils from '@control-front-end/utils/utils';
import { Utils, cr } from 'mw-style-react';
import { keyBy, omit, get } from 'lodash';
import {
  UPDATE_ACTOR,
  NON_FILTER_KEYS,
  EMPTY_IMG_BASE64,
  VALENCY_GRAPH,
} from '@control-front-end/common/constants/graphActors';
import {
  cyNodePropType,
  graphElementOwnFieldsPropType,
} from '@control-front-end/common/constants/propTypes';
import {
  SET_MAX_LAYER_NUMBER,
  UPDATE_LAYER,
} from '@control-front-end/common/constants/graphLayers';
import {
  NODE_SNAP_TYPE,
  toCellCoord,
  snapNodePositionByType,
} from '@control-front-end/utils/modules/utilsCellCoords';
import { updateLayerProps } from '../../routes/ActorsGraph/sagas/layers/layerHelpers';

/**
 * Аватар актора
 */
export function makeActorPictureBase(model, config) {
  let pictureUrl;
  if (
    model.systemObjType === 'user' ||
    !!model.systemObjSAId ||
    model.type === 'user' ||
    model.type === 'api'
  ) {
    pictureUrl = config.avatarPath.replace(
      '{userId}',
      model.systemObjSAId || model.systemObjId || model.saId || model.id
    );
  } else if (model.picture && !model.picture.includes('static')) {
    pictureUrl = AppUtils.makeAppUrl(`/download/${model.picture}`);
  } else if (model.isLayer && model.pictureUrl) {
    pictureUrl = model.pictureUrl;
  } else {
    const picture = model.formPicture || model.picture;
    pictureUrl = picture?.includes('static') ? picture : null;
  }
  return pictureUrl;
}

/**
 * Аватар актора генератор
 */
export function* makeActorPicture(model) {
  const config = yield select((state) => state.config);
  return makeActorPictureBase(model, config);
}

/**
 * Добавить аватар пользователю
 */
export function makeUserWithAvatar(model, config) {
  const modelCopy = structuredClone(model);
  modelCopy.avatar = AppUtils.makeUserAvatar(model, config);
  return modelCopy;
}

export function makePictureObject(model) {
  if (!model.pictureObject) return;
  const copyPictureObject = { ...model.pictureObject };
  const { img, type } = copyPictureObject;
  copyPictureObject.img =
    type === 'image' ? AppUtils.makeAppUrl(`/download/${img}`) : img;
  copyPictureObject.border =
    type === 'napkin' && img === EMPTY_IMG_BASE64 ? 1 : 0;
  return copyPictureObject;
}

/**
 * Расширить модель ссылками на изображения (аватар актора, пользователей)
 */
export function* extendModelWithAvatars(model) {
  const config = yield select((state) => state.config);
  model.pictureUrl = yield call(makeActorPicture, model);
  model.access = (model.access || []).map((u) => makeUserWithAvatar(u, config));
  if (model.defaultAccess) {
    model.defaultAccess = model.defaultAccess.map((u) =>
      makeUserWithAvatar(u, config)
    );
  }
  if (model.user) {
    model.user = makeUserWithAvatar(model.user, config);
  }
  if (model.attachments?.length) {
    for (const attach of model.attachments) {
      attach.userAvatar = AppUtils.makeUserAvatar(attach, config);
    }
  }
  model.copyPictureObject = makePictureObject(model);
}

function makeNodeTitle(node) {
  if (node.accessDenied) return 'Access Denied';
  if (node.title) return node.title;
  if (node.name) return node.name;
  const props = Object.keys(node.data);
  return node.data[props[0]];
}

export function* getSnappedNodePosition(node) {
  const systemForms = yield select((state) => state.systemForms);

  const {
    data: { formId, areaPicture },
    position,
    polygon,
  } = node;

  const snapPosition = snapNodePositionByType(
    { position, polygon },
    cr(
      [formId === systemForms.state.id && polygon, NODE_SNAP_TYPE.borders],
      [formId === systemForms.events.id && areaPicture, NODE_SNAP_TYPE.none],
      [true, NODE_SNAP_TYPE.center]
    )
  );

  return {
    ...snapPosition,
    cellPosition: snapPosition.position && toCellCoord(snapPosition.position),
  };
}

function addNodeToModel({
  node: nodeProp,
  nodeModels,
  duplicatesObj,
  config,
  maxIndex,
  replaceAll,
  actorNumbers,
  status = 'new',
  systemForms,
}) {
  // When status isn't new - actor is already wrapped in node
  const node = status === 'new' ? nodeProp : nodeProp.data;

  maxIndex = node.actorNumber || maxIndex + 1;
  const privs = node.privs || {};
  const id = node.laId ? `node_${node.laId}` : node.id.toString();
  duplicatesObj[node.id] = id;
  if (!node.areaPicture || node.areaPicture === '') delete node.areaPicture;
  const copyPictureObject = makePictureObject(node);
  const pictureUrl = makeActorPictureBase(node, config);
  const access = (node.access || []).map((u) => makeUserWithAvatar(u, config));
  const user = node.user ? makeUserWithAvatar(node.user, config) : null;
  const classes = node.classes || [];
  if (node.accessDenied) classes.push('accessDenied');
  if (node.pictureObject) classes.push('pictureObject');
  if (node.areaPicture) classes.push('areaPicture');

  const eventFormId = systemForms.events.id;
  const reactionFormId = systemForms.reactions.id;
  const dashboardFormId = systemForms.dashboards.id;
  const stateFormId = systemForms.state.id;
  const isLayerArea = !!(node.formId === eventFormId && node.areaPicture);
  const isStateMarkup = node.formId === stateFormId;
  if (isStateMarkup && node.polygon) {
    node.areaPicture = AppUtils.createImgByPolygon({
      polygon: node.polygon,
      color: node.color,
      opacity: 1,
      bgGrid: true,
    });
    if (!node.locked && !node.privs.modify) {
      node.locked = true;
    }
  }
  const layerSettings = { ...node.layerSettings };
  if (layerSettings.expand) {
    const isRecursiveLayerIFrame =
      document.location.pathname.indexOf(`/layers/${node.id}`) !== -1;
    layerSettings.expand = !isRecursiveLayerIFrame;
  }
  nodeModels.push({
    id,
    replaceAll,
    data: {
      ...node,
      id,
      // For "static" nodes can't apply "id" as "actorId" because they are different entities in that case
      actorId: node.actorId || (node.static ? null : node.id.toString()),
      group: 'nodes',
      type: 'node',
      title: makeNodeTitle(node),
      user,
      access,
      pictureUrl,
      copyPictureObject,
      readOnly: node.readOnly || isLayerArea || !privs.modify,
      accessDenied: node.accessDenied,
      locked: Boolean(isLayerArea || node.accessDenied || node.locked),
      layerSettings,
      isStateMarkup,
      isLayerArea,
      isChart: node.formId === dashboardFormId,
      isNonInteractive: node.isNonInteractive || node.formId === reactionFormId,
      actorNumber:
        actorNumbers && !node.isTrace ? node.actorNumber || maxIndex : null,
    },
    isLayerArea,
    locked: Boolean(isLayerArea || node.locked),
    grabbable: true,
    selectable: true,
    status,
    position: node.position,
    classes,
  });
  return maxIndex;
}

/**
 * Создать модель графа
 */
export function* makeGraphModels({
  nodes,
  edges,
  replaceAll = false,
  status = 'new',
  isTree = false,
}) {
  const config = yield select((state) => state.config);
  const { actorNumbers } = yield select((state) => state.settings);
  const systemForms = yield select((state) => state.systemForms);
  let maxIndex = yield select((state) => state.layerActorsNumbers);
  const nodeModels = [];
  const edgeModels = [];
  const duplicatesObj = {};
  for (const i of nodes) {
    maxIndex = addNodeToModel({
      node: i,
      nodeModels,
      duplicatesObj,
      config,
      maxIndex,
      replaceAll,
      actorNumbers,
      status,
      systemForms,
    });
  }
  for (const i of edges) {
    maxIndex = i.isTrace ? null : i.actorNumber || maxIndex + 1;
    const id = i.laId ? `edge_${i.laId}` : i.id.toString();
    const source = duplicatesObj[i.source] || i.source;
    const target = duplicatesObj[i.target] || i.target;
    if (i.laId && !i.laIdSource && !i.laIdTarget) {
      i.laIdSource = parseInt(source.split('_')[1], 10);
      i.laIdTarget = parseInt(target.split('_')[1], 10);
    }
    edgeModels.push({
      id,
      replaceAll,
      data: {
        ...i,
        id,
        edgeId: i.edgeId || i.id.toString(),
        group: 'edges',
        type: 'edge',
        sourceActorId: i.sourceActorId || i.source,
        targetActorId: i.targetActorId || i.target,
        source: i.laIdSource ? `node_${i.laIdSource}` : source,
        target: i.laIdTarget ? `node_${i.laIdTarget}` : target,
        actorNumber: actorNumbers ? i.actorNumber || maxIndex : null,
        name: i.name || '',
        targetArrow: i.targetArrow || isTree ? 'triangle' : 'none',
        isTree,
      },
      classes: i.classes || [],
      status,
    });
  }
  yield put({ type: SET_MAX_LAYER_NUMBER, payload: maxIndex });
  return { nodes: nodeModels, edges: edgeModels };
}

/**
 * Получить слой по id
 */
export function* getLayerById(id) {
  const graphLayers = yield select((state) => state.graphLayers);
  const { list } = graphLayers;
  return list.find((i) => i.id === id);
}

/**
 * Получить активный слой графа c акторами
 */
export function* getActiveLayer() {
  const graphLayers = yield select((state) => state.graphLayers);
  const { active } = graphLayers;
  return yield getLayerById(active);
}

/**
 * Изменяем граф активного слоя
 */
export function* sendReducerMsg({ type, payload, layerId }) {
  const graphLayers = yield select((state) => state.graphLayers);
  const { active, list } = graphLayers;
  const copyList = list.slice();
  const findLayerId = layerId || active;
  const layerIndex = copyList.findIndex((i) => i.id === findLayerId);
  if (layerIndex === -1) return null;
  const layer = copyList[layerIndex];
  const copyLayer = { ...layer, ...payload };
  copyList.splice(layerIndex, 1, copyLayer);
  yield put({
    type,
    payload: { list: copyList },
  });
  return layer;
}

/**
 * Find actor by laId (node_122, edge_322)
 */
export function* getActorByGraphId(laId, type, withDuplicates = false) {
  let typeList = 'graphLayers';
  let layer = yield getActiveLayer();
  const actorsList = yield select((state) => state.actorsList);
  if (!layer) {
    typeList = 'actorsList';
    layer = { [type]: actorsList.list };
  }
  if (AppUtils.isUuid(laId)) {
    const findEl = (i) =>
      i.id === laId ||
      (i.data && i.data.actorId === laId) ||
      (i.data && i.data.edgeId === laId);
    const elUuids = withDuplicates
      ? layer[type]?.filter(findEl)
      : layer[type]?.find(findEl);
    if (withDuplicates)
      return typeList === 'graphLayers' ? elUuids?.map((i) => i.data) : elUuids;
    return typeList === 'graphLayers' && elUuids ? elUuids.data : elUuids;
  }
  const id = laId?.toString().includes('_') ? laId?.split('_')[1] : laId;
  if (!id) return null;
  const el = layer[type].find(
    (i) => +i.data.laId === +id || (!i.data.laId && i.data.id === laId)
  );
  return typeList === 'graphLayers' ? el?.data : el;
}

/**
 * Получить элементы графа без статуса
 */
export function* getGraphEls(type, layerId) {
  const graphLayers = yield select((state) => state.graphLayers);
  const { active, list } = graphLayers;
  const findLayerId = layerId || active;
  const layer = list.find((i) => i.id === findLayerId);
  if (!layer?.[type]) return [];
  let filtered = layer[type].filter((i) => i.status !== 'removed');
  filtered = filtered.map((i) => {
    const copy = { ...i };
    delete copy.status;
    delete copy.replaceAll;
    return copy;
  });
  return structuredClone(filtered);
}

/**
 * Получить элементы графа и установить всем статус new
 */
export function* getAllGraphEls(type) {
  const graphLayers = yield select((state) => state.graphLayers);
  const { active, list } = graphLayers;
  const layer = list.find((i) => i.id === active);
  if (!layer) return [];
  const copyLayer = structuredClone(layer);
  const filtered = copyLayer[type].filter((i) => i.status !== 'removed');
  filtered.forEach((i) => {
    i.status = 'new';
  });
  return filtered;
}

/**
 * Обновить свойство актора
 */
export function* updateActorProp({
  type,
  layerId,
  id,
  actorId,
  propId,
  value,
}) {
  const copyNodes = yield call(getGraphEls, 'nodes', layerId);
  const copyEdges = yield call(getGraphEls, 'edges', layerId);
  const actorsIndexes = id
    ? AppUtils.findAllIndexes(copyNodes, (i) => i.id === id)
    : AppUtils.findAllIndexes(copyNodes, (i) => i.data.actorId === actorId);
  for (const nodeIndex of actorsIndexes) {
    const node = copyNodes[nodeIndex];
    if (!node) continue;
    node.data[propId] = value;
    node.status = 'updated';
    copyNodes.splice(nodeIndex, 1, node);
  }
  yield sendReducerMsg({
    layerId,
    type,
    payload: {
      nodes: copyNodes,
      edges: copyEdges,
    },
  });
}

/**
 * Removes properties from element data that are related to Cytoscape.js (cyNodePropType)
 * and properties that are related to an element directly (node or edge, graphElementOwnFieldsPropType)
 *
 * @param {Object} element - cytoscape element
 * @returns {Object} element with omitted properties
 */
const removeCYFields = (element) => {
  return omit(element, [
    ...Object.keys(cyNodePropType),
    ...Object.keys(graphElementOwnFieldsPropType),
  ]);
};

/**
 * Updates element data except data that is related to an element directly (node or edge)
 *
 * @param {Array} element An element to update
 * @param {Object} update The update data
 */
const updateElement = (element, updateProp) => {
  const update =
    element.data?.laId && element?.data.laId === updateProp?.data.laId
      ? updateProp
      : {
          ...removeCYFields(updateProp),
          data: removeCYFields(updateProp?.data),
        };

  return {
    ...element,
    ...update,
    data: {
      ...(element?.data || {}),
      ...(update?.data || {}),
    },
  };
};

/**
 * Update element/elements (node/s or edge/s) in the provided list
 *
 * - By default the key is 'data.id' - which means that only specific node will be updated
 *
 * - If you provide "data.actorId" or "data.edgeId" - all nodes with specified actorId/edgeId will be updated
 * (e.g. all actors entities on the graph)
 *
 * @param {Object} options Options
 * @param {Array} options.list The list of elements to update
 * @param {Object} options.update The update data
 * @param {string} [options.key='data.id'] The key to identify the elements
 */
export const updateListElements = ({ list, update, key = 'data.id' }) => {
  list.forEach((i, index) => {
    if (get(i, key) === get(update, key) && get(i, key) !== undefined) {
      list.splice(index, 1, updateElement(i, update));
    }
  });
};

/**
 * Обновить модель актора на графе
 */
export function* updateActorOnGraph({ actorModel, layerId }) {
  const actorModels = Array.isArray(actorModel) ? actorModel : [actorModel];
  const copyNodes = yield call(getGraphEls, 'nodes', layerId);
  const copyNodesMap = keyBy(copyNodes, 'id');
  const { nodes } = yield call(makeGraphModels, {
    nodes: actorModels,
    edges: [],
  });

  for (const nodeModel of nodes) {
    const { areaPicture, polygon, color } =
      copyNodesMap[nodeModel.id]?.data || {};

    const stateMarkupNodeUpdate = {};
    if (areaPicture && polygon && nodeModel.data.color !== color) {
      stateMarkupNodeUpdate.areaPicture = AppUtils.createImgByPolygon({
        polygon,
        color: nodeModel.data.color,
        opacity: 1,
        bgGrid: true,
      });
    }

    updateListElements({
      list: copyNodes,
      update: {
        status: 'updated',
        data: {
          ...(nodeModel.data || {}),
          ...stateMarkupNodeUpdate,
        },
      },
      key: 'data.actorId',
    });
  }
  return { nodes: copyNodes };
}

/**
 * Обновить актор-слой
 */
export function* updateLayerActor({ layerId, data }) {
  const list = yield call(updateLayerProps, {
    layerId,
    props: data,
  });
  yield put({ type: UPDATE_LAYER.SUCCESS, payload: { list } });
}

export function* updateValencyLayerActor(data) {
  const valencyActors = yield select((state) => state.graphValency.list);
  const targetActorIndex = valencyActors.findIndex((i) => i.id === data.id);
  if (targetActorIndex === -1) return;
  const valencyActorsCopy = structuredClone(valencyActors);
  const actor = structuredClone(valencyActorsCopy[targetActorIndex]);
  actor.data = { ...data, type: 'node' };
  valencyActorsCopy[targetActorIndex] = structuredClone(actor);
  yield put({
    type: VALENCY_GRAPH.GET.SUCCESS,
    payload: { list: valencyActorsCopy },
  });
}

/**
 * Установить балансы акторам
 */
export function setActorsBalances({
  balances,
  nodes,
  currencyParams,
  workWithData = true, // TOMAKE: Should be refactored
}) {
  balances.forEach((item) => {
    const actors = nodes.filter(
      (node) =>
        (workWithData ? node.data.actorId : node.id) === item?.actorId &&
        node.status !== 'removed'
    );
    for (const i of actors) {
      const actor = workWithData ? i.data : i;

      if (item.accountsExist) {
        const isDateTime = currencyParams && currencyParams.type === 'dateTime';
        actor.balance =
          isDateTime && (!item.credit || !item.debit)
            ? 0
            : item.credit - item.debit;
        actor.balanceVector = item.vector;
        if (currencyParams) {
          actor.balanceFormatted = AppUtils.formattedAmount(
            actor.balance,
            currencyParams
          );
        }
      } else {
        delete actor.balanceVector;
        actor.balance = null;
      }
      if (workWithData) {
        i.status = 'updated';
        delete i.replaceAll;
      }
    }
  });
}

/**
 * Сгруппировать акторы по шаблонам
 */
export function* groupActorsByTpl({ rootActorId, nodes, edges, edgeTypeId }) {
  const childrenEdges = edges.filter(
    (i) => i.data.sourceActorId === rootActorId
  );
  const childrenEdgesIds = childrenEdges.map((i) => i.id);
  const hiddenEdges = yield getGraphEls('hiddenEdges');
  if (!childrenEdges.length) return { groupEdges: edges, hiddenEdges };
  const childNodesIds = childrenEdges.map((i) => i.data.target);
  const targetToEdge = {};
  childrenEdges.forEach((i) => {
    targetToEdge[i.data.target] = i;
  });
  const childNodes = nodes.filter((i) => childNodesIds.includes(i.id));
  const groupChild = AppUtils.groupBy(childNodes, 'data.formId');
  for (const formId of Object.keys(groupChild)) {
    const groupActors = groupChild[formId];
    const groupNodeId = `${AppUtils.udid()}_${formId}_node`;
    const groupNode = {
      id: groupNodeId,
      data: {
        id: groupNodeId,
        actorId: groupNodeId,
        group: 'nodes',
        type: 'node',
        title: groupActors[0].data.formTitle,
        data: {},
        privs: {},
        readOnly: true,
        isNonInteractive: true,
        color: groupActors[0].data.color,
      },
      status: 'new',
    };
    const groupEdgeId = `${AppUtils.udid()}_${formId}_edge`;
    const groupEdge = {
      id: groupEdgeId,
      data: {
        id: groupEdgeId,
        edgeId: groupEdgeId,
        group: 'edges',
        type: 'edge',
        sourceActorId: rootActorId,
        targetActorId: groupNodeId,
        source: rootActorId,
        target: groupNodeId,
        edgeTypeId,
        privs: {},
        name: '',
        isTree: true,
        targetArrow: 'triangle',
      },
      status: 'new',
    };
    nodes.push(groupNode);
    edges.push(groupEdge);
    for (const actor of groupActors) {
      const subEdge = { ...targetToEdge[actor.id] };
      subEdge.data.realSourceActorId = rootActorId;
      subEdge.data.sourceActorId = groupNodeId;
      subEdge.data.source = groupNodeId;
      edges.push(subEdge);
    }
  }
  const groupEdges = edges.filter((i) => !hiddenEdges.includes(i.id));
  return { groupEdges, hiddenEdges: childrenEdgesIds };
}

/**
 * Исключить узлы без доступа + их связи
 */
export function excludeAccessDenied({ nodes = [], edges = [] }) {
  const copyNodes = nodes.filter((n) => !n.accessDenied);
  const copyEdges = edges.filter((n) => {
    const sourceNode = nodes.find((item) => item.id === n.source);
    const targetNode = nodes.find((item) => item.id === n.target);
    return (
      sourceNode &&
      targetNode &&
      !sourceNode.accessDenied &&
      !targetNode.accessDenied
    );
  });
  return { nodes: copyNodes, edges: copyEdges };
}

/**
 * Сформировать узлы для автослоя
 */
function* makeAutoLayerEls(id, root, list, total) {
  const rootNode = {
    id,
    status: 'new',
    data: {
      id,
      actorId: id,
      title: Utils.toPascalCase(id),
      type: 'node',
      privs: {},
      isAutoLayerRoot: true,
      isAutoLayerNode: true,
      ...root,
    },
  };
  switch (id) {
    case 'actors_bag':
      rootNode.data = {
        ...rootNode.data,
        tplsCount: total,
        pictureUrl: '/static/actor_gray.svg',
        isTemplate: true,
      };
      break;
    case 'actor_filter':
      let customPicture = root?.color
        ? '/static/actor_white.svg'
        : '/static/actor_gray.svg';
      if (root.title === 'Graphs') {
        customPicture = '/static/graph_node.svg';
      }
      rootNode.data = {
        ...rootNode.data,
        actorsCount: total,
        pictureUrl: customPicture,
        isTemplate: true,
      };
      break;
    default:
      break;
  }
  if (!list.length) return { nodes: [rootNode], edges: [] };
  const nodes = list.map((i) => ({
    id: i.id,
    status: 'new',
    data: {
      id: i.id,
      group: 'nodes',
      actorId: i.id,
      title: i.name,
      readOnly: !i.privs?.modify,
      ...i,
      type: 'node',
      isTemplate: id === 'actors_bag',
      isAutoLayerNode: true,
    },
    position: null,
  }));
  const edgeType = 'hierarchy';
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === edgeType);
  const edges = list.map((i) => ({
    id: `edge_${i.id}`,
    status: 'new',
    data: {
      id: `edge_${i.id}`,
      type: 'edge',
      group: 'edges',
      edgeTypeId: edgeTypeH.id,
      edgeType,
      edgeId: `edge_${i.id}`,
      source: rootNode.id,
      target: i.id,
      title: '',
      privs: i.privs,
    },
  }));

  return { nodes: [rootNode, ...nodes], edges };
}

/**
 * Сформировать авто-слой (для графов или мешка акторов)
 */
export function* makeAutoLayerModel({ id, root, list, total }) {
  const { nodes, edges } = yield call(makeAutoLayerEls, id, root, list, total);
  nodes[0].replaceAll = true;
  return {
    id,
    title: Utils.toPascalCase(id),
    type: 'graph',
    isAuto: true,
    nodes,
    edges,
    privs: { view: true, modify: true, remove: true },
  };
}

/**
 * Сформировать из списка графов модаль графа
 */
export function* updateAutoLayerEls({ layerId, id, status, data }) {
  const copyNodes = yield call(getGraphEls, 'nodes');
  updateListElements({
    list: copyNodes,
    update: { id, status, data },
  });
  yield sendReducerMsg({
    layerId,
    type: UPDATE_ACTOR.SUCCESS,
    payload: { nodes: copyNodes },
  });
}

export function* isActiveStream(model) {
  const streams = yield select((state) => state.streams);
  const auth = yield select((state) => state.auth);
  if (
    !streams.list.length ||
    streams.active === 'all' ||
    model.streams?.includes(streams.active)
  ) {
    return true;
  }
  if (streams.active === 'sign' || streams.active === 'execute') {
    const user = model.access.find((i) => i.userId === auth.id);
    if (!user) return false;
    return user.privs[streams.active];
  }
  return false;
}

/**
 * Check if actor matches filter/stream
 */
export function* isActiveTemplateList(model) {
  const { filter, formId } = yield select((state) => state.actorsList);
  const customFilter = omit(filter, [...NON_FILTER_KEYS, 'formId', 'qFormId']);
  const isActiveS = yield isActiveStream(model);
  return (
    model.formId === formId && AppUtils.isEmptyObject(customFilter) && isActiveS
  );
}

/**
 * Check if actor needs to be added to user's Recent
 */
export function* isUsersRecentActor(model) {
  const actorsList = yield select((state) => state.actorsList);
  const auth = yield select((state) => state.auth);
  const systemForms = yield select((state) => state.systemForms);
  const reactionsFormId = systemForms.reactions.id;
  const isReaction = model.formId === reactionsFormId;
  if (isReaction) return false;
  const { formId, filter } = actorsList;
  const isActiveRecent = !formId && !filter;
  const isOwner = model.user.id === auth.id;
  return isActiveRecent && isOwner;
}
