import { useEffect, useState, useRef } from 'react';

/**
 * Хук для получения координат и зума графа
 * @param graph
 * @param {Function} callback - Wrap with useCallback to avoid unnecessary re-renders.
 */
function useGetGraphCoord(graph, callback) {
  const mountRef = useRef();
  const [coord, setCoord] = useState(null);

  const setCoordTooltip = () => {
    if (!graph) return;
    const pan = graph.pan();
    const zoom = graph.zoom();
    if (mountRef.current) {
      setCoord({ pan, zoom });
      if (callback) callback({ pan, zoom });
    }
  };

  useEffect(() => {
    if (callback) {
      setCoordTooltip();
    }
  }, [callback]);

  useEffect(() => {
    mountRef.current = true;
    setCoordTooltip();
    graph?.on('pan zoom', setCoordTooltip);
    return () => {
      mountRef.current = false;
      graph?.off('pan zoom', setCoordTooltip);
    };
  }, [graph]);

  return coord;
}

export default useGetGraphCoord;
