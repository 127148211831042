import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useIntl from 'useIntl';
import {
  Label,
  Icon,
  Avatar,
  Tooltip,
  ProgressBar,
  DateUtils,
  Chip,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import {
  GET_ACTOR,
  UPDATE_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import { DATE_FORMAT, CACHE_TTL } from 'constants';
import { getCachedActor } from 'selectors';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import ActorCard from '../../../ActorCard/ActorCard';
import mes from './intl';
import './ActorPreview.scss';

/**
 * Просмотр карточки актора
 */
function ActorPreview(props) {
  const { accId, id, title, rootActor } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const systemForms = useSelector((state) => state.systemForms);
  const cache = useSelector((state) => state.dataCache.actor);
  const actorView = useSelector((state) => state.actorView);
  const actorData = useSelector((state) => getCachedActor(state, props));
  const eventsFormId = systemForms.events.id;
  const [isLoading, setLoading] = useState(false);
  const [hasAccess, setAccess] = useState(true);

  useEffect(() => {
    if (!rootActor || !actorView || !cache) return;
    const { id: rootActorId, key: dataKey, formid: formId } = rootActor;
    const actorTitle = cache[id]?.title;
    if (rootActorId === actorView.id && actorTitle && title !== actorTitle) {
      const oldValue = actorView.data?.[dataKey];
      if (!Array.isArray(oldValue) || oldValue.length === 0) return;
      const updatedFormData = oldValue.map((item) => ({
        ...item,
        title: item.value === id ? actorTitle : item.title,
      }));
      dispatch({
        type: UPDATE_ACTOR.REQUEST,
        payload: {
          id: rootActorId,
          formId,
          formData: {
            [dataKey]: updatedFormData,
          },
        },
      });
    }
  }, [rootActor, actorView.id, cache]);

  /**
   * Получить событие
   */
  const getActor = () => {
    const actorId = id.indexOf('_') !== -1 ? id.split('_')[1] : id;
    setLoading(true);
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: { id: actorId, cache: true },
      callback: () => setLoading(false),
      errorCallback: () => {
        setLoading(false);
        setAccess(false);
      },
    });
  };

  useEffect(() => {
    const actorId = id.indexOf('_') !== -1 ? id.split('_')[1] : id;
    const now = new Date().getTime();
    // Проверяем, есть ли свежий кэш данных актора в сторе
    const isRecentlyCachedData =
      actorData?.id === actorId && now - actorData.reqTime < CACHE_TTL;
    if (isRecentlyCachedData) return;
    getActor();
  }, [id]);

  /**
   * Чипы для карточки
   */
  const renderChip = (icon, label, tooltip) => (
    <Tooltip value={tooltip}>
      <Chip
        type="rectangular"
        size="small"
        label={label}
        icon={icon}
        fontSize="small"
        fontWeight="normal"
        closeIcon={false}
      />
    </Tooltip>
  );

  const renderEventContent = () => {
    const { description, data } = actorData;
    const { startDate, endDate } = data;
    const htmlDescription = AppUtils.makeDescription({
      str: description,
      accId,
      chip: true,
    });
    return (
      <>
        {renderChip('calendar', AppUtils.getTextDate(startDate, endDate))}
        <div
          styleName="preview__content__descr"
          dangerouslySetInnerHTML={{
            __html: htmlDescription,
          }}
        />
      </>
    );
  };

  if (!actorData && !hasAccess) {
    return (
      <div styleName="preview__content__accessDenied">
        <Label value={t(mes.accessDenied)} />
      </div>
    );
  }

  if (!actorData || isLoading) {
    return (
      <div styleName="preview__content__loader">
        <ProgressBar type="circle" />
      </div>
    );
  }

  const isOwner = actorData.user && actorData.user.id === auth.id;
  const isEvent = actorData.formId === eventsFormId;

  return (
    <div styleName="preview__root">
      <div styleName="preview__header">
        <div styleName="preview__header__title">
          {isEvent ? (
            <Tooltip value={actorData.ownerName}>
              <Avatar
                styleName="preview__header__title__avatar"
                src={actorData.user.avatar}
                size="small"
              />
            </Tooltip>
          ) : (
            <ActorAvatar
              size="small"
              type="compact"
              formType={actorData.formType}
              formTitle={actorData.formTitle}
              pictureUrl={actorData.pictureUrl}
              colors={actorData.allColors}
              colorFilled={true}
            />
          )}
          <Label
            fontWeight="semibold"
            fontSize="small"
            value={actorData.title || title}
          />
        </div>
      </div>
      <div styleName="preview__content">
        <div styleName="preview__content__wrap">
          {actorData.formId === eventsFormId ? (
            renderEventContent()
          ) : (
            <ActorCard
              key={actorData.id}
              type="popup"
              id={actorData.id}
              wrapSections={true}
              forms={actorData.forms}
              accessDenied={actorData.accessDenied}
            />
          )}
        </div>
      </div>
      {actorData && actorData.user ? (
        <div styleName="preview__info">
          <div>
            <Icon size="small" type="client" />
            <Label
              fontSize="small"
              value={
                isOwner
                  ? t(mes.youAreOwner)
                  : t(mes.userIsOwner, { name: actorData.user.nick })
              }
            />
          </div>
          <div>
            <Icon size="small" type="info" />
            <Label
              fontSize="small"
              value={DateUtils.toDate(
                actorData.updatedAt,
                `${t(mes.lastUpdated)}: ${DATE_FORMAT}`
              )}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

ActorPreview.propTypes = {
  accId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default ActorPreview;
