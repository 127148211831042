import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorsLinks: {
    id: 'actorsLinks',
    defaultMessage: 'Links',
  },
  nodes: {
    id: 'nodes',
    defaultMessage: 'Nodes',
  },
  cantEditActors: {
    id: 'cantEditActors',
    defaultMessage: "You can't edit these items",
  },
});

export default { ...globalIntl, ...m };
