import { defineMessages } from 'react-intl';

const m = defineMessages({
  startImport: {
    id: 'startImport_ImportStrategySelectorModal',
    defaultMessage: 'Start import',
  },
  title: {
    id: 'title_ImportStrategySelectorModal',
    defaultMessage: 'Select import strategy:',
  },
  replace: {
    id: 'replace__ImportStrategySelectorModal',
    defaultMessage: 'Replace',
  },
  replaceDescription: {
    id: 'replaceDescription__ImportStrategySelectorModal',
    defaultMessage:
      'If the REF of the objects in the file matches the one in the current workspace, new objects will be created with a modified REF.', // eslint-disable-line 
  },
  replacePlaceholder: {
    id: 'replacePlaceholder__ImportStrategySelectorModal',
    defaultMessage: 'my_prefix_',
  },
  replaceInputLabel: {
    id: 'replaceInputLabel__ImportStrategySelectorModal',
    defaultMessage:
      'Specify the prefix for REF objects that match during the import.',
  },
  reuse: {
    id: 'reuse__ImportStrategySelectorModal',
    defaultMessage: 'Reuse',
  },
  reuseDescription: {
    id: 'reuseDescription__ImportStrategySelectorModal',
    defaultMessage:
      'If the REF of the objects in the file matches the one in the current workspace, a merge will occur, and the objects will be updated according to the new structure from the file.',  // eslint-disable-line
  },
  error: {
    id: 'error__ImportStrategySelectorModal',
    defaultMessage: 'Error',
  },
  errorDescription: {
    id: 'errorDescription__ImportStrategySelectorModal',
    defaultMessage:
      'If the REF of the objects in the file matches the one in the current workspace, the import will not proceed, and an error will be returned.', // eslint-disable-line 
  },
  next: {
    id: 'next__ImportStrategySelectorModal',
    defaultMessage: 'Next',
  },
});

export default m;
