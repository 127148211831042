import { call, put, takeLatest } from 'redux-saga/effects';
import {
  APP_INIT,
  CONFIG,
  GET_ACTIVE_ACC,
  RequestStatus,
  GET_USER_SETTINGS,
  SET_LOCATION,
  GET_CONFIG,
  INIT_TRANSLATIONS,
} from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import getURLSettings from '@control-front-end/utils/getURLSettings';
import api from './api';
import { createSettingsModel } from './users';
import { SET_SYSTEM_FORMS } from '../constants/forms';
import {
  SET_DEFAULT_ACTOR_TPL,
  GET_EDGE_TYPES,
} from '../constants/graphActors';
import { SIP_ACTOR_ID } from '../constants';

function* setSystemForms(forms) {
  const mapForms = {};
  for (const i of forms) {
    mapForms[i.title.toLowerCase()] = i;
  }
  yield put({ type: SET_SYSTEM_FORMS, payload: mapForms });
}

/**
 * Установка GA
 */
function setGA(data) {
  if (data.googleAnalytics) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${data.googleAnalytics}`;
    script.async = true;
    document.head.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line func-names, prettier/prettier
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    gtag('config', data.googleAnalytics);
  }
}

/**
 * Setup google tag manager
 */
function setGTM(data) {
  if (!data.gtagAnalytics) return;

  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtm.js?id=${data.gtagAnalytics}`;
  script.async = true;
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(script, firstScript);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
}

/**
 * Sets the LinkedIn Insight Tag.
 * @param {string} data.linkedInPartnerId LinkedIn partner ID.
 */
function setLinkedInInsightTag(data) {
  if (!data.linkedInPartnerId) return;

  /* Below code is provided by LinkedIn Insight Tag setupper */
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(data.linkedInPartnerId);

  if (!window.lintrk) {
    window.lintrk = (a, b) => {
      window.lintrk.q.push([a, b]);
    };
    window.lintrk.q = [];
  }

  const s = document.getElementsByTagName('script')[0];
  const b = document.createElement('script');
  b.type = 'text/javascript';
  b.async = true;
  b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
  s.parentNode.insertBefore(b, s);
}

/**
 * Sets the meta pixel tag for tracking Facebook events.
 * @param {string} data.metaPixelId - The ID of the meta pixel.
 */
function setMetaPixelTag(data) {
  if (!data.metaPixelId) return;

  /* Below code is provided by Meta Pixel setupper */
  /* eslint-disable */
  !((f, b, e, v, n, t, s) => {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
  fbq('init', data.metaPixelId);
  fbq('track', 'PageView');
  /* eslint-enable */
}

/**
 * Инициализация приложения
 */
export function* appInit({ payload }) {
  const { accId, host } = payload;
  const queryAccId = AppUtils.isValidAccountId(accId) ? accId : null;
  const { result, data } = yield call(api, {
    method: 'get',
    url: '/init',
    queryParams: { queryAccId, host },
  });
  if (result !== RequestStatus.SUCCESS) {
    yield put({ type: APP_INIT.ERROR });
    return;
  }
  const {
    config,
    activeWorkspace,
    userSettings,
    systemForms,
    defaultForm,
    systemEdgeTypes,
  } = data.data;
  yield put({ type: CONFIG.SUCCESS, payload: config });
  setGA(config);
  setGTM(config);
  setLinkedInInsightTag(config);
  setMetaPixelTag(config);

  /**
   * Display widget only if it's opened from the separate simulator page.
   * In other cases there is no need in widget, because simulator in that cases serves different purposes
   */
  if (!AppUtils.isIframe()) {
    AppUtils.setControlWidget({
      widgetUrl: config.widgetUrl,
      widgetSettings: {
        auth: true,
        actorId: config.controlWidgetId,
        hideDefaultLauncher: true,
      },
      sipSettings: {
        actorId: SIP_ACTOR_ID,
        debug: true,
      },
    });
  }
  yield put({ type: SET_LOCATION, payload: document.location.pathname });
  yield put({ type: GET_ACTIVE_ACC.SUCCESS, payload: activeWorkspace });
  yield setSystemForms(systemForms);
  const settings = createSettingsModel(userSettings);
  const urlSettings = getURLSettings();

  const urlPlainMode = { true: true, false: false }[urlSettings.plainMode];

  yield put({
    type: GET_USER_SETTINGS.SUCCESS,
    payload: {
      ...settings,
      plainMode: urlPlainMode === undefined ? settings.plainMode : urlPlainMode,
    },
  });
  yield put({ type: SET_DEFAULT_ACTOR_TPL, payload: defaultForm });
  yield put({ type: GET_EDGE_TYPES.SUCCESS, payload: systemEdgeTypes });
  yield put({ type: APP_INIT.SUCCESS });
  return data.data;
}

function* getConfig() {
  const { result, data } = yield call(api, {
    method: 'get',
    url: '/config',
  });
  if (result !== RequestStatus.SUCCESS) {
    yield put({ type: GET_CONFIG.ERROR });
    return;
  }
  yield put({ type: GET_CONFIG.SUCCESS, payload: data.data });
}

function* initTranslations({ payload: { locale }, callback }) {
  const { default: payload } = yield import(
    `../../../messages/app/${locale}.json`
  );
  yield put({ type: INIT_TRANSLATIONS.SUCCESS, payload });
  if (callback) callback(payload);
}

function* init() {
  yield takeLatest(GET_CONFIG.REQUEST, getConfig);
  yield takeLatest(APP_INIT.REQUEST, appInit);
  yield takeLatest(INIT_TRANSLATIONS.REQUEST, initTranslations);
}

export default init;
