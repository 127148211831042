function SVGIconDashboard(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = '#1973E8';
  ctx.beginPath();
  ctx.moveTo(6.545, 0);
  ctx.lineTo(9.455, 0);
  ctx.bezierCurveTo(9.856, 0, 10.182, 0.358, 10.182, 0.8);
  ctx.lineTo(10.182, 15.200000000000001);
  ctx.bezierCurveTo(10.182, 15.642000000000001, 9.856, 16, 9.455, 16);
  ctx.lineTo(6.545, 16);
  ctx.bezierCurveTo(6.144, 16, 5.818, 15.642, 5.818, 15.2);
  ctx.lineTo(5.818, 0.8);
  ctx.bezierCurveTo(5.818, 0.35800000000000004, 6.143999999999999, 0, 6.545, 0);
  ctx.closePath();
  ctx.moveTo(12.364, 4);
  ctx.lineTo(15.273, 4);
  ctx.bezierCurveTo(15.674, 4, 16, 4.358, 16, 4.8);
  ctx.lineTo(16, 15.2);
  ctx.bezierCurveTo(16, 15.642, 15.674, 16, 15.273, 16);
  ctx.lineTo(12.363, 16);
  ctx.bezierCurveTo(11.962, 16, 11.636, 15.642, 11.636, 15.2);
  ctx.lineTo(11.636, 4.8);
  ctx.bezierCurveTo(11.636, 4.358, 11.962, 4, 12.363999999999999, 4);
  ctx.closePath();
  ctx.moveTo(0.727, 8);
  ctx.lineTo(3.637, 8);
  ctx.bezierCurveTo(4.038, 8, 4.364, 8.358, 4.364, 8.8);
  ctx.lineTo(4.364, 15.200000000000001);
  ctx.bezierCurveTo(4.364, 15.642000000000001, 4.038, 16, 3.636, 16);
  ctx.lineTo(0.727, 16);
  ctx.bezierCurveTo(0.326, 16, 0, 15.642, 0, 15.2);
  ctx.lineTo(0, 8.8);
  ctx.bezierCurveTo(0, 8.358, 0.326, 8, 0.727, 8);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDashboard;
