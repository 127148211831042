import { NODES_COLORS_PALETTE } from '@control-front-end/common/constants/graphActors';

const getFormNodeStructure = ({ id, title, color, form }) => {
  return {
    [`form_${id}`]: {
      title,
      color: color || NODES_COLORS_PALETTE.Cherry,
      isNonInteractive: true,
      nodes: form.sections.reduce((acc, section, index) => {
        acc[`node_section_${id}_${index}`] = {
          title: section.title || `Section ${index + 1}`,
          color: NODES_COLORS_PALETTE.Lips,
          isNonInteractive: true,
          nodes: section.content.reduce((acc, item) => {
            acc[`node_item_${index}_${item.id}`] = {
              title: item.title,
              color: NODES_COLORS_PALETTE.Simulator,
              isNonInteractive: true,
            };
            return acc;
          }, {}),
        };
        return acc;
      }, {}),
    },
  };
};

export default getFormNodeStructure;
