import {
  Avatar,
  Chip,
  Chips,
  cr,
  Icon,
  Label,
  Space,
  Stack,
} from 'mw-style-react';
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import scss from './WorkspacesList.scss';

const WorkspacesList = ({ list, activeAccountId, handleSelect }) => {
  return list.map(({ id, name, photo, color, roles = [] }) => {
    const isActive = id === activeAccountId;
    return (
      <Space
        key={id}
        className={cn(scss.workspaceItem, { [scss.active]: isActive })}
        left
        right
        size="xsmall"
        fullWidth
        onClick={() => handleSelect(id)}
      >
        <Stack.H
          size="xsmall"
          fullWidth
          alignItems="center"
          justifyContent="flexStart"
        >
          <Avatar
            className={scss.avatar}
            size="small"
            src={photo}
            bgColor={color}
            label={!photo ? name : ''}
          />
          <Stack.V
            className={scss.workspaceItem__label}
            size="micro"
            justifyContent="center"
          >
            <Label
              overflow="cut"
              duplicatingTooltip
              fontSize="small"
              value={name}
              color={Label.COLOR[isActive ? 'primary' : 'black']}
            />
            {cr([
              roles.length,
              <Chips
                spaceSize={Chips.SIZE.xxsmall}
                chipProps={{
                  size: 'micro',
                  type: 'rounded',
                  color: Chip.COLOR.white,
                  borderColor: Chip.COLOR.gray,
                  unbordered: false,
                }}
              >
                {roles.map((role) => (
                  <Label
                    key={role.id}
                    fontSize="xsmall"
                    color={Label.COLOR.gray}
                    value={role.name}
                    overflow="cut"
                    duplicatingTooltip
                  />
                ))}
              </Chips>,
            ])}
          </Stack.V>
          {cr([
            isActive,
            <Icon className={scss.workspaceItem__check} type="check" />,
          ])}
        </Stack.H>
      </Space>
    );
  });
};

WorkspacesList.propTypes = {
  activeAccountId: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
      color: PropTypes.string,
      roles: PropTypes.array,
    })
  ).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default WorkspacesList;
